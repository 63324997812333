/**=====================
    2.20 Loader CSS Start
==========================**/
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: $white;
  z-index: 11;
  top: 0;

  /*Color Loader*/
  .loader-index {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    filter: url("#goo");

    &:after {
      content: "";
      width: 4em;
      height: 4em;
      background: var(--theme-default);
      border-radius: 50%;
      position: absolute;
      transform: scale(0.5);
      animation: grow 2s cubic-bezier(.14, 0.05, 0.55, 0.5) infinite alternate;
    }

    span {
      width: 2em;
      height: 2em;
      background: var(--theme-secondary);
      border-radius: 50%;
      margin-right: 2em;
      position: relative;
      transform: translateX(7em);
      animation: move 4s ease-in-out infinite;

      &:before {
        content: "";
        width: 2em;
        height: 2em;
        background: var(--theme-default);
        border-radius: 50%;
        position: absolute;
        left: 3em;
        transform: translateX(0em);
        animation: shrink 2s ease-in-out infinite;
      }

      &:after {
        content: "";
        width: 2em;
        height: 2em;
        background: $info-color;
        border-radius: 50%;
        position: absolute;
        right: 3em;
        transform: translateX(0em);
        animation: shrink 2s ease-in-out infinite;
      }
    }
  }

  @keyframes grow {
    0% {
      transform: scale(0.5);
      background: $info-color;
    }

    50% {
      transform: scale(1);
      background: var(--theme-secondary);
    }
  }

  @keyframes move {
    0% {
      transform: translateX(7em);
    }

    50% {
      transform: translateX(-5em)
    }
  }

  @keyframes shrink {
    0% {
      transform: translateX(0em)
    }

    50% {
      transform: translateX(-1em)
    }
  }

  svg {
    width: 0;
    height: 0;
  }

  /*Color Loader*/

  body {
    font-family: sans;
    min-height: 100vh;
    margin: 0;
    display: grid;
    place-content: center;
    background: $black
  }

  h1 {
    color: $white;
    text-align: center;
    margin-bottom: 2em;
    letter-spacing: 3px;

    position: relative;
    z-index: 2;

    &:before {
      position: absolute;
      content: "";
      height: 1px;
      background: linear-gradient(to right, var(--theme-secondary), var(--theme-default), $info-color);
      color: $white;
      top: 2.75em;
      left: 0.75em;
      width: 2.5em;
      font-weight: 400;
      font-variant: small-caps;
      font-size: 0.5em;
    }
  }
}

/**=====================
    2.20 Loader CSS Ends
==========================**/