/**=====================
     2.2 Alert CSS start
==========================**/

.notify-alert {
  .close {
    color: $white;
    background: unset;
    border: none;
    font-size: 25px;

    [dir="rtl"] & {
      right: unset !important;
      left: 10px !important;
    }
  }

  &.alert-light {
    .close {
      color: $theme-body-font-color;
    }
  }
}

.alert {
  border-radius: $alert-border-radious;
  padding: 15px;

  svg {
    vertical-align: middle;
  }

  .progress {
    margin-top: $alert-padding;
  }

  [data-notify='icon'] {
    margin-right: 10px;
    line-height: 20px;
    position: absolute;
  }

  [data-notify='message'] {
    display: inline-block;
    width: 100%;
  }

  i {
    margin-right: 5px;
    font-size: $alert-msg-icon-size;
  }

  p {
    margin-bottom: 0;
  }

  .close {
    opacity: 1;

    span {
      font-size: 24px;
      font-weight: 400;
      display: inline-block;
      border-radius: 3px;
      text-shadow: none;
      padding: 0 5px;
      padding-right: 0;
    }
  }
}

.alert-dismissible {
  padding-right: 2.5rem;

  [dir="rtl"] & {
    padding-right: 15px;
    padding-left: 2.5rem;
  }

  .btn-close {
    box-shadow: none;

    @media (max-width:767px) {
      font-size: 10px;
    }
  }

  .close {
    padding: 10px 1.25rem;
    top: 4px;
    padding-right: 13px;
    opacity: 0.5;
    transition: all 0.3s ease;

    &:hover {
      transition: all 0.3s ease;
      opacity: 1;
      color: inherit;
    }
  }
}

.card-body {
  button {
    &:focus {
      outline: none;
    }
  }

  .alert {
    &:last-child {
      margin-bottom: 0;
    }

    svg {
      @include pixelstrap-size(16px, 16px);
      margin-right: 4px;
      position: absolute;
      top: 18px;

      &~p {
        padding-left: 20px;
      }
    }
  }
}

strong {
  display: inline-block;

  &~p {
    display: inline-block;
  }
}

$alert-name: primary,
  secondary,
  success,
  danger,
  warning,
  info,
  light,
  dark;

$alert-color : $primary-color,
$secondary-color,
$success-color,
$danger-color,
$warning-color,
$info-color,
$light-color,
$dark-color;

@each $var in $alert-name {
  $i: index($alert-name, $var);

  .alert-#{$var} {
    background-color: rgba(nth($alert-color, $i), 0.8);
    border-color: rgba(nth($alert-color, $i), 0.9);
    color: $white;

    .progress {
      height: 5px;
      background-color: darken(nth($alert-color, $i), 1%);
      border-radius: 0;
    }

    .progress-bar {
      background-color: lighten(nth($alert-color, $i), 50%);
    }

    .btn-close {
      filter: brightness(1) invert(1);
    }

    .alert-link {
      color: nth($alert-color, $i);

      @if($var =="light") {
        color: $dark-color;
      }
    }

    @if($var =="light") {
      & {
        color: $dark-color;
        background-color: $light-color;
      }

      .btn-close {
        filter: unset;
      }
    }

    hr {
      border-top-color: nth($alert-color, $i);
    }

  }

  .border-#{$var} {
    border-color: nth($alert-color, $i) !important;
  }

  .alert-bg-#{$var} {
    background-color: rgba(nth($alert-color, $i), 0.1);
  }

  // dashboard-7
  .income-wrapper {
    ul {
      li {
        .income-dot {
          &.dot-#{$var} {
            background-color: nth($alert-color, $i);
          }
        }
      }
    }
  }

  // touchspin 
  .touchspin-wrapper {
    .btn-touchspin {
      &.touchspin-#{$var} {
        background-color: nth($alert-color, $i);
        border: 1px solid nth($alert-color, $i);
        color: $white;
      }

      &.spin-border-#{$var} {
        background-color: unset;
        border: 1px solid nth($alert-color, $i);
        color: nth($alert-color, $i);
      }
    }

    input[type='number'] {
      &.spin-outline-#{$var} {
        outline: 1px solid nth($alert-color, $i);
        border: none;
        border-radius: 3px;
      }
    }
  }

  .pre-post-touchspin {

    .btn-touchspin {
      &.touchspin-#{$var} {
        background-color: nth($alert-color, $i);
        border: 1px solid nth($alert-color, $i);
        color: $white;
      }
    }
  }

  // Switches
  .form-switch {
    .form-check-input {
      &.switch-#{$var} {
        &:checked {
          background-color: nth($alert-color, $i);
          border-color: nth($alert-color, $i);
        }
      }

      &:focus {
        box-shadow: unset;
      }
    }
  }

  .payment-second {
    .mega-icons {
      &.stroke-#{$var} {
        stroke: nth($alert-color, $i);
      }
    }
  }

  .toast-#{$var} {
    color: nth($alert-color, $i) !important;
  }

  .list-group-horizontal {

    &-sm,
    &-md,
    &-lg,
    &-xl,
    &-xxl {
      .list-group-item {
        &.border-left-#{$var} {
          border-left: 4px solid nth($alert-color, $i);

          [dir="rtl"] & {
            border-right: 4px solid nth($alert-color, $i);
            border-left: unset;
          }
        }
      }
    }
  }

  // Radio-toggle
  .main-radio-toggle {
    .btn-check {
      &.radio-light-#{$var} {
        &:focus {
          +.btn {
            box-shadow: 0 0 0 4px rgba(nth($alert-color, $i), 0.55);
          }
        }
      }
    }
  }

  // Radio button
  .fill-radios {
    .radio {
      &.radio-#{$var} {
        input[type='radio']:checked {
          &+label {
            &::before {
              border: 10px solid nth($alert-color, $i);
            }

            &::after {
              background-color: nth($alert-color, $i);
            }
          }
        }
      }
    }
  }

  .list-group {

    .form-check-input[type=checkbox],
    [type=radio] {
      &.checkbox-#{$var} {
        background-color: unset;
        border-color: nth($alert-color, $i);
        outline: 0;

        &:focus,
        &:active,
        &.active,
        &:checked {
          border-color: nth($alert-color, $i);
          outline: 0;
          background-color: nth($alert-color, $i);
        }
      }
    }

    .alert-#{$var}.light {
      background-color: rgba(nth($alert-color, $i), 0.4);
      border-color: rgba(nth($alert-color, $i), 0.7);
    }

    .alert-#{$var}.dark {

      @if($var =="light") {
        color: $dark-color;
      }

      .alert-link {
        color: $white;
        background-color: nth($alert-color, $i);
        border-color: nth($alert-color, $i);
        color: $auth-bg-color;

        @if($var =="light") {
          color: $dark-color;
        }
      }
    }

    .alert-#{$var}.outline,
    .alert-#{$var}.outline-2x {
      background-color: $transparent-color;
      border-color: nth($alert-color, $i);
      color: nth($alert-color, $i);

      @if($var =="light") {
        color: $dark-color;
      }

      .btn-close {
        filter: unset;
      }
    }

    .alert-#{$var}.inverse {
      background-color: transparent;
      padding: 13px 20px 13px 65px;
      border-color: nth($alert-color, $i);
      color: $theme-body-font-color;

      &:before {
        top: 16px;
        content: "";
        position: absolute;
        left: 54px;
        width: 0;
        height: 0;
        border-left: 7px solid nth($alert-color, $i);
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;

        @media (max-width: 576px) {
          left: 45px;
        }
      }

      i {
        padding: 17px 20px;
        display: inline-block;
        background-color: nth($alert-color, $i);
        color: $white;
        border-radius: 3px 0 0 3px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        @media (max-width: 576px) {
          padding: 17px 14px;
        }
      }
    }

    .btn-close {
      filter: unset;
      top: -3px;
    }
  }
}

.outline-2x {
  border-width: 2px;
  padding: 11px 1.25rem;
}


.alert-icons {
  &.outline-2x {
    padding: 15px;
  }

  svg {
    position: absolute !important;
    top: 18px !important;
  }
}

.alert-dark,
.alert-info {
  .btn-close {
    filter: none;
  }
}

.alert-dark {
  .btn-close {
    filter: brightness(1) invert(1);
  }
}

$alert-light-name: primary,
  secondary,
  success,
  warning,
  info,
  light,
  danger,
  dark;

$alert-light-color: $light-primary,
  $light-secondary,
  $light-success,
  $light-warning,
  $light-info,
  $light-white,
  $light-danger,
  $light-dark;

@each $var in $alert-light-name {
  $i: index($alert-light-name, $var);

  .alert-light-#{$var} {
    background-color: nth($alert-light-color, $i);
  }

  .shadow-#{$var} {
    box-shadow: 0px 10.511241px 12.408992767333984px 0px nth($alert-light-color, $i);
  }
}

.alert-arrow {
  min-width: 55px;
  height: 55px;
  @include pixelstrap-flex(flex, center, center);
  position: relative;

  i {
    margin-right: 0;
  }

}

.ellipsis.content {
  @media (max-width:576px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px;
  }

  @media (max-width:575px) {
    max-width: 180px;
  }
}

.alert-dismissible {
  display: flex;
  align-items: center;
  gap: 8px;

}

// Flag-icons
.alert {
  &.notify-alert {
    &.alert-copy {
      display: flex !important;
      background-color: var(--theme-default);
      border: 1px solid var(--theme-default);
      width: 300px;
      line-height: 1;
      color: $white;

      @media (max-width:550px) {
        width: 285px;
      }
    }
  }
}

// Icon with alerts
.alerts-icon {
  .alert {
    >div {
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      background-color: var(--white);
      @include pixelstrap-flex(flex, center, center);
      margin-right: 10px;
    }

    svg {
      position: relative;
      top: 0;
      @include pixelstrap-size(18px, 18px);
      margin: 0;
    }
  }
}

.outline-alerts {
  .double-border {
    border: 4px double;
  }

  .inset-border {
    border: 4px inset;
  }

  .alert {
    p {
      display: inline-block;
      max-width: 92%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.alert-box {
  .alert-body {
    text-align: center;

    svg {
      @include pixelstrap-size(100px, 100px);
      position: relative;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 12px;
    }

    .button-box {
      button {
        &:first-child {
          margin-right: 6px;
        }

        &.light-background {

          &:hover,
          &:focus {
            background-color: var(--light-bg) !important;
            border-color: transparent;
          }
        }
      }
    }
  }
}

/**=====================
   2.2 Alert CSS end
==========================**/