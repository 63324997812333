/**=====================
    3.54 Scroll-spy CSS start
==========================**/

.main-scrollspy {
    .nav-pills {
        .nav-link {
            color: var(--chart-text-color);
            font-weight: 500;

            &.active {
                color: $white !important;
            }
        }
    }

    h6,
    h5 {
        margin-bottom: 10px;
        color: var(--body-font-color);
    }

    p:not(.f-m-light) {
        color: $font-gray-color;
    }

    .navbar-scrollspy {
        .navbar-brand {
            img {
                @media (max-width:480px) {
                    height: 34px;
                }
            }
        }

        .bg-body-tertiary {
            justify-content: space-between;
            background-color: var(--light-background) !important;

            @media (max-width:606px) {
                gap: 8px;
            }
        }

        .nav-pills {
            align-items: center;
            gap: 8px;

            .nav-item {
                &.dropdown {
                    .dropdown-toggle {
                        width: 120px;
                        border-radius: 6px;
                        padding: 8px 16px;

                        @media (max-width:480px) {
                            width: 100%;
                        }

                        &:focus {
                            border: unset;
                        }

                        &::after {
                            font-size: 14px;
                            top: 48%;
                        }

                        [dir="rtl"] & {
                            text-align: right;

                            &::after {
                                left: 10px;
                                right: unset;
                            }
                        }
                    }

                    .dropdown-menu {
                        li {
                            .dropdown-item {
                                opacity: 1;
                                border: unset;
                                color: var(--chart-text-color);

                                &.active,
                                &:hover {
                                    background-color: rgba($primary-color, 0.09);
                                    color: var(--theme-default);
                                }
                            }
                        }
                    }
                }
            }
        }

        .scrollspy-example {
            height: 200px;
            overflow-y: auto;
            scroll-behavior: smooth;
            background-color: var(--light-background) !important;
        }
    }

    // Nested Scrollspy
    .nested-scrollspy {
        .common-align {
            @media (max-width:991px) {
                flex-direction: column;
            }
        }

        .nested-scrollspy-menu {
            background-color: var(--light-background) !important;

            .nav-pills {
                &:first-child {
                    padding: 22px 16px;
                }
            }
        }

        .scrollspy-example-2 {
            height: 300px;
            overflow-y: auto;
            scroll-behavior: smooth;

            @media (max-width:1199px) and (min-width:576px) {
                height: 344px;
            }

            @media (max-width:575px) {
                height: 185px;
            }

            p {
                margin-bottom: 16px;
            }

            svg {
                width: 120px;
                height: 150px;

                @media (max-width:1399px) and (min-width:576px) {
                    width: 88px;
                    height: 88px;
                }

                @media (max-width:575px) {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    .scrollspy-example-3 {
        height: 180px;
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    .custom-scrollspy-section {
        .common-p-space {
            padding: 18px 0;

            &:last-child {
                padding-bottom: 0;
            }
        }

        .nav-pills {
            .nav-link {
                position: relative;
                border-radius: 0;
                transition: all .2s ease-in-out;
                text-align: center;
                padding: 12px 16px;

                &.active {
                    .custom-arrow {
                        display: block;
                        position: absolute;
                        content: '';
                        border-left: 8px solid var(--theme-default);
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        right: -12px;
                        transition: all .2s ease-in-out;

                        [dir="rtl"] & {
                            right: unset;
                            left: -4px;
                            border-left: unset;
                            border-right: 8px solid var(--theme-default);
                        }
                    }
                }

            }
        }

        .main-project {
            .attendance-card {
                .left-overview-content {
                    .svg-box {
                        width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
                        padding: 6px;

                        svg {
                            width: calc(65px + (120 - 65) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(65px + (150 - 65) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }

                .right-overview-content {
                    >div {
                        h6 {
                            padding-bottom: 0;
                        }
                    }

                    .marks-count {
                        i {
                            @media (max-width:575px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .scrollspy-example-5 {
            height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            scroll-behavior: smooth;

            img {
                height: calc(85px + (150 - 85) * ((100vw - 320px) / (1920 - 320)));
            }

            .about-box {

                >div {
                    svg {
                        width: 13px;
                        height: 13px;
                        vertical-align: middle;
                        fill: $white;
                    }
                }
            }

            span {
                color: $font-gray-color;
            }
        }
    }

    .experience-section {
        h6 {
            font-weight: 400;
            color: $badge-light-color;
        }

        .progress {
            margin-bottom: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));

            &:last-child {
                margin-bottom: 0;
            }

            .progress-bar {
                background-color: var(--theme-default);
                color: $white;
            }
        }
    }
}

/**=====================
    3.54 Scroll-spy CSS ends
==========================**/