/**=====================
  5.3 Header CSS Start
==========================**/

.left-header {
  .d-flex {
    align-items: center;
  }

  h6 {
    line-height: 1.6;

    @media (max-width: 1199px) {
      width: 160px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: 991px) {
      width: 120px;
    }

    @media (max-width: 767px) {
      width: 66px;
    }
  }

  @media (max-width: 755px) {
    display: none;
  }

  img {
    margin-top: -12px;
    height: 26px;
  }

  i {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: -4px;
  }
}

/*======= Page Header css ends  ======= */


/**======Main Header css Start ======**/
.offcanvas,
.offcanvas-bookmark {
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        position: relative;
        filter: blur(5px) grayscale(100%);
      }
    }
  }
}

.offcanvas {
  position: unset;
  visibility: visible;
  background: unset;
  z-index: 2;
}

.toggle-sidebar {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;

  svg {
    width: $header-wrapper-nav-icon-size;
    height: $header-wrapper-nav-icon-size;
  }

  &:before {
    content: "";
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: rgba($primary-color, 0.1);
    border-radius: 100%;
    left: -10px;
    z-index: -2;
    top: -8px;
    transform: scale(0);
    transition: all 0.3s ease;
  }

  &:hover {
    &:before {
      height: 40px;
      width: 40px;
      transform: scale(1);
      transition: all 0.3s ease;
    }

    >svg {
      color: var(--theme-default) !important;
      stroke: var(--theme-default) !important;
    }
  }
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;

  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;

  li {
    a {
      svg {
        margin-top: 0 !important;

        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}

/**======Main Header css Ends ======**/
/**=====================
    5.3 Header CSS Ends
==========================**/