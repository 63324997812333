/**=====================
    3.65 Dashboard_12 Start
==========================**/
.dashboard-12 {
    .small-stats {
        .card {
            .card-body {
                .project-state {
                    width: 66px;
                    height: 67px;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;

                    svg {
                        @include pixelstrap-size(33px, 33px);
                    }

                    @media (min-width:1400px) {
                        width: calc(48px + (66 - 48) * ((100vw - 1400px) / (1920 - 1400)));
                        height: calc(49px + (67 - 49) * ((100vw - 1400px) / (1920 - 1400)));

                        svg {
                            width: calc(22px + (33 - 22) * ((100vw - 1400px) / (1920 - 1400)));
                            height: calc(22px + (33 - 22) * ((100vw - 1400px) / (1920 - 1400)));
                        }
                    }

                    @media (max-width:768px) {
                        width: calc(48px + (66 - 48) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(49px + (67 - 49) * ((100vw - 320px) / (1920 - 320)));

                        svg {
                            width: calc(24px + (33 - 24) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(24px + (33 - 24) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }
            }

            [class^="font-"] {
                padding-top: 9px;

                span {
                    margin-left: 2px;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 2px;
                    }
                }
            }
        }
    }

    .tasks-card {
        .arrow-group {
            display: flex;
            gap: 5px;
            position: absolute;
            right: 60px;
            top: 15px;
            z-index: 2;

            [dir="rtl"] & {
                right: unset;
                left: 60px;
            }

            .next,
            .prev {
                background-color: $light-color;
                @include pixelstrap-size(35px, 35px);
                @include pixelstrap-flex(flex, center, center);
                border-radius: 5px;

                @media (min-width:1400px) {
                    width: calc(30px + (35 - 30) * ((100vw - 1400px) / (1920 - 1400)));
                    height: calc(30px + (35 - 30) * ((100vw - 1400px) / (1920 - 1400)));

                    i {
                        font-size: calc(12px + (14 - 12) * ((100vw - 1400px) / (1920 - 1400)));
                    }
                }

                @media (max-width:575px) {
                    width: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
                    height: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));

                    i {
                        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                    }
                }

                &.swiper-button-disabled {
                    opacity: 0.5;
                }
            }
        }

        .task-box {
            .common-space {
                gap: 6px;
            }

            h6 {
                line-height: 25px;
                @include multi-line-ellipsis(2, auto, vertical, hidden);
            }

            p {
                color: rgba($badge-light-color, 0.8);
                font-weight: 400;
                margin: 0;


                a {
                    font-weight: 500;
                    color: rgba($badge-light-color, 0.8);
                    text-decoration: underline;
                }

                &:nth-last-child(1) {
                    padding-top: 15px;
                }
            }

            .project-link {
                padding: 9px 0 17px 0;
            }

            .badge {
                padding: 4px 12px;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 400;

                @media (min-width:1400px) {
                    font-size: calc(13px + (14 - 13) * ((100vw - 1400px) / (1920 - 1400)));
                }
            }

            .project-member {
                @include pixelstrap-flex(flex, space-between, center);
                padding-bottom: 8px;

                .customers {
                    text-align: end;
                    display: flex;
                    justify-content: flex-end;


                    li {
                        img {
                            @include pixelstrap-size(28px, 28px);
                            transition: 0.5s;

                            &:hover {
                                transform: translateY(-4px) scale(1.02);
                                transition: 0.5s;
                            }
                        }

                        +li {
                            margin-left: -13%;

                            [dir="rtl"] & {
                                margin-left: unset;
                                margin-right: -13%;
                            }
                        }
                    }
                }
            }

            .progress {
                height: 6px;
                background: linear-gradient(180.36deg, #CFDCE8 -32.82%, #FFFFFF 132.82%);
            }

            .swiper-wrapper {
                .swiper-slide {
                    border-left: 1px dashed rgba($badge-light-color, 0.38);
                    padding-left: 20px;

                    [dir="rtl"] & {
                        border-left: unset;
                        border-right: 1px dashed rgba($badge-light-color, 0.38);
                        padding-left: unset;
                        padding-right: 20px;
                        margin-right: unset !important;
                        margin-left: 20px !important;
                    }

                    &.swiper-slide-active {
                        border-left: unset;
                        padding-left: 0;

                        [dir="rtl"] & {
                            padding-left: unset;
                        }
                    }
                }
            }

            [class^="col-"] {
                +[class^="col-"] {
                    border-left: 1px dashed rgba($badge-light-color, 0.38);

                    [dir="rtl"] & {
                        border-left: unset;
                        border-right: 1px dashed rgba($badge-light-color, 0.38);
                    }
                }
            }
        }
    }

    .task-card {
        .common-space {
            padding: 17px 0 18px 0;
            border-bottom: 1px solid rgba($badge-light-color, 0.1);
            gap: 6px;

            >div {
                display: flex;
                gap: 24px;

                @media (min-width:1400px) {
                    gap: calc(12px + (24 - 12) * ((100vw - 1400px) / (1920 - 1400)));
                }

                span {
                    &:first-child {
                        @media (max-width:1499px) {
                            display: none;
                        }
                    }

                    &:last-child {
                        @media (max-width:1599px) and (min-width:1400px) {
                            display: block;
                            width: 99%;
                        }
                    }
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
                svg {
                    fill: var(--theme-default);
                }
            }

            svg {
                @include pixelstrap-size(22px, 22px);
                fill: var(--body-font-color);
            }

            &:nth-child(2),
            &:nth-child(3) {
                color: rgba($badge-light-color, 0.8);

                span {
                    &:last-child {
                        text-decoration: line-through;
                    }
                }
            }
        }
    }

    .all-project-table {
        .recent-table {
            div.dt-container {
                .dt-layout-row {
                    &:last-child {
                        .dt-info {
                            @media (max-width:1416px) {
                                display: none;
                            }
                        }
                    }
                }
            }

            table {

                thead,
                tbody {
                    tr {

                        th,
                        td {
                            min-width: auto;

                            a {
                                color: var(--body-font-color);
                            }

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            img {
                                height: 40px;

                                @media (max-width:1199px) {
                                    height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
                                }
                            }

                            .badge {
                                padding: 4px 7px;
                                border-radius: 2px;
                                font-size: 14px;
                                line-height: 16.59px;
                                font-weight: 400;
                            }

                            &:nth-child(7) {
                                @media (max-width:1755px) and (min-width:1400px) {
                                    display: none;
                                }
                            }

                            &:nth-child(6) {
                                @media (max-width:1596px)and (min-width:1400px) {
                                    display: none;
                                }
                            }

                            &:nth-child(5) {
                                @media (max-width:1417px)and (min-width:1400px) {
                                    display: none;
                                }
                            }

                            @media (max-width:991px) {
                                &:nth-child(3) {
                                    min-width: 110px;
                                }

                                &:nth-child(4) {
                                    min-width: 108px;
                                }

                                &:nth-child(5) {
                                    min-width: 100px;
                                }

                                &:nth-child(6) {
                                    min-width: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .team-performance-table {
        .recent-table {
            table {

                thead,
                tbody {
                    tr {

                        th,
                        td {
                            min-width: auto;

                            a {
                                color: var(--body-font-color);
                            }

                            img {
                                height: 33px;
                            }

                            &:first-child {
                                padding-left: 20px;

                                @media (max-width:1484px) and (min-width:1400px) {

                                    span,
                                    a {
                                        @include pixelstrap-ellipsis;
                                        display: block;
                                        width: 71px;
                                    }
                                }
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            @media (max-width:1698px) and (min-width:1400px) {
                                &:last-child {
                                    display: none;
                                }

                                &:nth-child(3) {
                                    padding-right: 20px;
                                }
                            }

                            @media (max-width:1235px) and (min-width:1200px) {
                                &:last-child {
                                    display: none;
                                }

                                &:nth-child(3) {
                                    padding-right: 20px;
                                }
                            }

                            @media (max-width:1199px) {
                                &:first-child {
                                    min-width: 130px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-expenses {
        h6 {
            font-size: 26px;
            padding-bottom: 6px;

            @media (min-width:1400px) {
                font-size: calc(21px + (26 - 21) * ((100vw - 1400px) / (1920 - 1400)));
            }

            @media (max-width:1399px) and (min-width:1200px) {
                font-size: calc(20px + (26 - 20) * ((100vw - 1200px) / (1920 - 1200)));
            }

            @media (max-width:1199px) {
                font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        span {
            &[class^="font-"] {
                font-size: 18px;

                @media (max-width:1399px) and (min-width:992px) {
                    font-size: calc(14px + (18 - 14) * ((100vw - 992px) / (1920 - 992)));
                }
            }

            svg {
                @include pixelstrap-size(18px, 18px);
                vertical-align: text-top;
            }
        }

        .monthly-expense-wrapper {
            margin: -34px 0 -65px 0;

            @media (max-width:1399px) and (min-width:1386px) {
                margin: -54px 0 -48px 0;
            }

            @media (max-width:1199px) and (min-width:906px) {
                margin: -34px 0 -50px 0;
            }

            @media (max-width:905px) and (min-width:768px) {
                margin: -35px 0 -65px 0;
            }

            @media (max-width:767px) and (min-width:762px) {
                margin: -30px 0 -50px 0;
            }

            @media (max-width:761px) and (min-width:576px) {
                margin: -34px 0 -66px 0;
            }

            @media (max-width:575px) and (min-width:359px) {
                margin: -68px 0 -48px 0;
            }

            @media (max-width:358px) {
                margin: -50px 0 -63px 0;
            }

        }

        .yearly-expense-wrapper {
            margin-bottom: -70px;
        }

        @media (max-width:1399px) {
            .card {
                .card-body {
                    padding-top: 0;
                }
            }
        }
    }

    .estimated-project {
        margin-left: -20px;
    }

    .upcoming-datatable {
        .recent-table {
            table {

                thead,
                tbody {
                    tr {

                        th,
                        td {
                            min-width: auto;

                            a {
                                color: var(--body-font-color);
                            }

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            @media (max-width:1776px) and (min-width:1400px) {
                                &:nth-child(3) {
                                    span {
                                        display: block;
                                        width: 88%;
                                        @include pixelstrap-ellipsis;
                                    }
                                }
                            }

                            @media (max-width:1706px)and (min-width:1400px) {
                                &:nth-child(3) {
                                    display: none;
                                }
                            }

                            @media (max-width:992px) {
                                &:nth-child(2) {
                                    min-width: 100px;
                                }

                                &:nth-child(3) {
                                    min-width: 160px;
                                }

                                &:last-child {
                                    min-width: 90px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .recent-activity-table {
        .recent-table {
            table {

                thead,
                tbody {
                    tr {

                        th,
                        td {
                            min-width: auto;

                            a {
                                color: var(--body-font-color);
                            }

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            @media (max-width:1704px) and (min-width:1400px) {
                                &:last-child {
                                    display: none;
                                }

                                &:nth-child(4) {
                                    padding-right: 20px;
                                }
                            }

                            @media (max-width:1468px)and (min-width:1400px) {
                                &:nth-child(3) {
                                    display: none;
                                }
                            }

                            @media (max-width:991px) {
                                &:nth-child(2) {
                                    min-width: 225px;
                                }

                                &:nth-child(3) {
                                    min-width: 100px;
                                }

                                &:nth-child(4) {
                                    min-width: 122px;
                                }

                                &:nth-child(5) {
                                    min-width: 90px;
                                }
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            span {
                                @media (max-width:1697px) {
                                    @include pixelstrap-ellipsis;
                                    width: 78%;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sales-report {
        .balance-data {
            @media (max-width:1460px) and (min-width:1400px) {
                display: flex;
            }

            @media (max-width:1399px) and (min-width:1200px) {
                display: none;
            }

            @media (max-width:1199px) and (min-width:520px) {
                display: flex;
            }

            @media (max-width:519px) {
                display: flex;
                position: relative;
                inset: 0;
            }
        }
    }
}

/**=====================
    3.65 Dashboard_12 Start
==========================**/