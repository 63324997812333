/**=====================
    3.22 Flash_icons CSS Start
==========================**/
.main-animate {
    .common-flex {
        @media (max-width:331px) {
            justify-content: center;
        }
    }

    a {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--light-bg);
        overflow: hidden;
    }

    svg {
        width: 22px;
        height: 22px;
        stroke: var(--chart-text-color);
    }

    i {
        color: var(--chart-text-color);
        border-radius: 50%;
        font-size: 16px;
    }
}

.pulse-wrapper {
    a {
        border-radius: 50%;
    }

    svg {
        animation: pulse-animate 1s infinite ease;
        border-radius: 50%;
    }

    i {
        animation: pulse-animate 1s infinite ease;
    }

    @keyframes pulse-animate {
        0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
        }
    }
}

.heartbeat-wrapper {
    a {
        border-radius: 50%;
    }

    svg {
        animation: heartbeat-rate 1s infinite ease;
        border-radius: 50%;
        stroke: $danger-color;
    }

    i {
        color: $danger-color;
        animation: heartbeat-rate 1s infinite ease;
    }
}

//  border animations
.border-pulse-wrapper {
    a {
        border: 4px double var(--recent-dashed-border);
        border-radius: unset;

        svg,
        i {
            border-radius: 1%;
        }
    }
}

.pulse-rhombus {
    a {
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }

    svg,
    i {
        border-radius: 0;
    }
}

.pulse-rabbet {
    a {
        clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
    }
}

@keyframes heartbeat-rate {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    15% {
        transform: scale(.8);
    }

    20% {
        transform: scale(.85);
        opacity: 1;
    }

    35% {
        transform: scale(1.3);
        opacity: .6;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

/**=====================
    3.22 Flash_icons CSS End
==========================**/