/**=====================
    3.51 Placeholders CSS Start
==========================**/

.placeholder-light {
    background-color: $dark-color;
    opacity: 0.08;
}

.loading-card-wrapper {
    img {
        height: calc(125px + (200 - 125) * ((100vw - 320px) / (1920 - 320)));
        object-fit: cover;
    }
}

.placeholder-body {
    display: flex;
    align-items: center;
    gap: 14px;

    .placeholder-start {
        .square {
            width: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
            height: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 5px;
            background: linear-gradient(to right, rgba($dark-gray, 0.02) 2%, rgba(130, 130, 130, 0.02) 2%, rgba($dark-gray, 0.1) 14%);
            background-size: 800px 100px;
            animation: wave-square-lines 2s infinite ease-out;

            &.circle {
                border-radius: 50%;
                height: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
                width: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .placeholder-end {
        flex: 1;

        .placeholder-line {
            height: 12px;
            margin-bottom: 6px;
            border-radius: 2px;
            background: linear-gradient(to right, rgba($dark-gray, 0.02) 2%, rgba(130, 130, 130, 0.02) 2%, rgba($dark-gray, 0.1) 14%);
            background-size: 800px 100px;
            animation: wave-left-lines 2s infinite ease-out;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.animation-placeholder {
    p {
        margin-bottom: 0;
    }
}

@keyframes wave-left-lines {
    0% {
        background-position: -460px 0;
    }

    100% {
        background-position: 460px 0;
    }
}

@keyframes wave-square-lines {
    0% {
        background-position: -460px 0;
    }

    100% {
        background-position: 460px 0;
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.1;
    }
}

/**=====================
    3.51 Placeholders CSS End
==========================**/