/**=====================
    3.57 Task CSS Start
==========================**/
.modal-open {
  .datepickers-container {
    z-index: 1075;
  }
}

.date-details {
  align-items: center;

  >div {
    +div {
      margin-left: 10px;
    }
  }
}

.taskadd {
  table {
    tr {
      td {
        border-top: none;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;

          a {
            @include square-box(25px, 25px, 3px, $light-danger);
            display: grid;
            place-items: center;

            svg {
              color: $danger-color;
            }
          }
        }

        svg {
          @include pixelstrap-size(18px, 18px);
          color: $semi-dark;
        }
      }

      &:first-child {
        td {
          padding-top: 20px;
        }
      }

      &:last-child {
        td {
          padding-bottom: 20px;
          border-bottom: unset;
        }
      }
    }
  }
}

// responsive
@media only screen and (max-width: 1660px) {
  .taskadd {
    table {
      tr {
        td {
          &:first-child {
            min-width: 160px;
          }

          &:nth-child(3) {
            min-width: 70px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .date-details {
    display: block ! important;
    margin-bottom: -10px;

    .d-inline-block {
      margin-bottom: 10px;
    }
  }

  .taskadd {
    table {
      tr {
        td {
          &:first-child {
            padding-left: 20px;
          }
        }

        &:first-child {
          td {
            padding-top: 20px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .taskadd {
    table {
      tr {
        td {
          &:nth-child(2) {
            min-width: 745px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .taskadd {
    table {
      tr {
        td {
          &:first-child {
            padding-left: 15px;
          }
        }

        &:first-child {
          td {
            padding-top: 15px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.main-tasks {
  .main-menu {
    li {
      &:last-child {
        border-top: 1px solid var(--chart-dashed-border);
        padding-top: 14px;
        margin-top: 14px;
      }
    }
  }

  .task-stats {
    .common-align {
      background-color: rgba($primary-color, 0.1);
      padding: 12px;
      border-radius: 4px;
      gap: 12px;

      [class*="bg-"] {
        @include pixelstrap-size(40px, 40px);
        display: grid;
        place-items: center;
        border-radius: 3px;

        i {
          font-size: 20px;
          margin: 0;
          color: $white;
        }
      }

      @media (min-width:1400px) {
        [class*="bg-"] {
          width: calc(35px + (40 - 35) * ((100vw - 1400px) / (1920 - 1400)));
          height: calc(35px + (40 - 35) * ((100vw - 1400px) / (1920 - 1400)));

          i {
            font-size: calc(16px + (20 - 16) * ((100vw - 1400px) / (1920 - 1400)));
          }
        }

        h4 {
          font-size: calc(18px + (20 - 18) * ((100vw - 1400px) / (1920 - 1400)));
        }
      }
    }
  }

  .form-select {
    font-size: 14px;
    color: rgba($badge-light-color, 0.8);
  }

  .bootstrap-select {
    .dropdown-toggle {
      border-color: rgba($badge-light-color, 0.1) !important;

      .filter-option {
        white-space: initial;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        opacity: 1;
        color: rgba($badge-light-color, 0.6) !important;
      }
    }

    .no-results {
      font-size: 14px;
      margin: 0;
      padding: 0 12px;
    }
  }

  .was-validated {
    .bootstrap-select {
      select {
        &:valid+.dropdown-toggle {
          border-color: $success-color !important;
        }

        &:invalid+.dropdown-toggle {
          border-color: $danger-color !important;
        }
      }
    }
  }

  .common-task-table {
    .table-responsive {
      div.dt-container {
        .dt-layout-row {
          .dt-search {
            right: -50px;
          }
        }
      }

      form {
        padding: 20px;
      }

      table {

        thead,
        tbody {
          tr {

            th,
            td {
              min-width: auto;
              color: var(--body-font-color);

              a {
                color: var(--body-font-color);
              }

              &:first-child {
                padding-left: 20px;
              }

              &:last-child {
                padding-right: 20px;
              }

              &:nth-child(2) {
                max-width: 150px;
              }

              &:nth-child(3) {
                span {
                  @include multi-line-ellipsis(2, auto, vertical, hidden);
                  width: 140px;
                }
              }

              @media (max-width:1485px) and (min-width:1200px) {
                &:nth-child(3) {
                  display: none;
                }
              }

              span {
                &.badge {
                  padding: 6px 12px;
                  font-size: 12px;
                }
              }

              ul {
                li {
                  +li {
                    margin-left: -12px;

                    [dir="rtl"] & {
                      margin-left: unset;
                      margin-right: -12px;
                    }
                  }
                }
              }

              img {
                height: 30px;
              }

              @media (max-width:1199px) {
                &:nth-child(2) {
                  min-width: 125px;
                }

                &:nth-child(3) {
                  span {
                    width: 345px;
                  }
                }

                &:nth-child(4) {
                  min-width: 108px;
                }

                &:nth-child(5) {
                  min-width: 100px;
                }

              }
            }
          }
        }
      }
    }
  }
}

/**=====================
  3.57 Task CSS Ends
==========================**/