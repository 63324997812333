/**=====================
    5.4 Navs CSS Start
==========================**/
#accordionoc {

	#collapseicon,
	#collapseicon1 {
		padding-top: 0px;
	}

	li {
		button {
			display: flex;
			align-items: center;
		}
	}
}

.navs-icon.default-according.style-1 {
	li {
		button {
			position: relative;

			&:hover {
				color: var(--theme-default) !important;
			}
		}

		button[aria-expanded="true"] {
			&:before {
				right: 2px;
				top: 6px;
			}
		}

		button[aria-expanded="false"]:before {
			right: 2px;
			top: 6px;
		}
	}
}

.navs-icon {
	padding: 20px;

	li {
		padding: 9px 0;

		a {
			color: $theme-body-font-color;

			&:hover {
				color: var(--theme-default);
			}
		}

		ul {
			padding-top: 9px;
		}
	}
}

.onhover-dropdown {
	&.navs-dropdown {
		&:hover {
			.onhover-show-div {

				&:before,
				&:after {
					right: 77px;
					left: unset;
				}
			}
		}
	}
}

.icon-lists {
	font-family: inherit;
}

/**=====================
    5.4 Navs CSS Ends
==========================**/