/**=====================
    3.31 Helper CSS start
==========================**/
.helper-common-box {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px;

    &:last-child {
        margin-bottom: 0;
    }

    &.helper-p-wrapper {
        gap: 12px;
        margin-bottom: 0;
        flex-wrap: wrap;
    }

    .border-common-box {
        width: auto;
        height: 50px;
        background-color: var(--light-background);
        border-radius: 10px;
        border-color: var(--recent-dashed-border);
        padding: 12px;
    }

    .border {
        --bs-border-color: var(--recent-border) !important;
    }

    [class*="border-"] {
        --bs-border-color: var(--recent-border) !important;
    }

}

.txt-space {
    div {
        margin-bottom: 16px;

        &:nth-child(7) {
            margin-bottom: 0;
        }
    }
}

.border-wrapper {
    padding: 20px;
}

.helper-box {
    @include pixelstrap-size(40px, 40px);
    border-radius: 25%;

    &.helper-text {
        @include pixelstrap-flex(flex, center, center);
        font-weight: 900;
    }
}

.helper-radius {
    width: 60px;
    height: 30px;
}

.fill-wrapper {
    background-color: $light-color;
}

.gradient-border {
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 1200px) {
        gap: calc(13px + (22 - 13) * ((100vw - 320px) / (1200 - 320)));
    }
}

.radius-wrapper {
    background-color: $light-semi-gray;
}

.font-wrapper {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}

.helper-padding {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.helper-p-box {
    @include pixelstrap-flex(flex, center, center);

    span {
        color: var(--body-font-color);
    }
}

.common-p-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
}

// custom helper col classes
@media (max-width:1746px) and (min-width:992px) {
    .helper-col-6 {
        width: 50%;
        flex: 0 0 auto;
    }
}

@media (max-width:1399px) and (min-width:992px) {
    .helper-height-equal {
        min-height: auto !important;
    }
}

/**=====================
    3.31 Helper CSS end
==========================**/