/**=====================
    3.13 Dashboard_5 CSS Start
==========================**/
.social {
    &-widget {
        .card-body {
            padding: 15px;

            @media (max-width:1699px) and (min-width:1500px) {
                span {
                    &[class*='font-'] {
                        display: none !important;
                    }
                }
            }
        }

        .social-icons {
            min-width: calc(40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)));
            height: calc(40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)));
            background: var(--white);
            box-shadow: 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        .social-content {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .social-chart {
                margin: -34px -42px -13px;
                width: 150px;

                .apexcharts-canvas {
                    .apexcharts-datalabels-group {
                        .apexcharts-datalabel-label {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@keyframes wave-speaker {
    0% {
        transform: translateX(5px);
    }

    50% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(5px);
    }
}

.mobile-app-card {
    background-image: url(../images/dashboard-5/profile-bg.png);
    background-repeat: no-repeat;
    background-size: cover;

    &.social-empower {
        .card-header {
            width: 100%;

            h6 {
                @media (max-width:1537px) and (min-width:1400px) {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                }
            }
        }
    }

    .card-header {
        background: transparent;
        padding: 20px;
        width: 68%;

        @media (max-width: 1693px) {
            width: 75%;
        }

        @media (max-width: 1316px) {
            width: 86%;
        }

        @media (max-width: 991px) {
            width: 64%;
        }

        @media (max-width: 350px) {
            width: 75%;
        }
    }

    .card-body {
        margin-top: -20px;

        @media (max-width:1693px) and (min-width:1353px) {
            margin-top: 10px;
        }

        @media (max-width:1352px) and (min-width:1500px) {
            margin-top: 70px;
        }

        @media (max-width:1499px) and (min-width:1409px) {
            margin-top: 45px;
        }

        @media (max-width:1408px) and (min-width:1353px) {
            margin-top: 22px;
        }

        @media (max-width:1352px) and (min-width:1213px) {
            margin-top: 45px;
        }

        @media (max-width:1212px) and (min-width:1200px) {
            margin-top: 25px;
        }

        @media (max-width: 1199px) and (min-width:992px) {
            margin-top: 26px;
        }

        @media (max-width: 991px) and (min-width:768px) {
            margin-top: 20px;
        }

        @media (max-width: 767px) and (min-width:461px) {
            margin-top: -90px;
        }

        @media (max-width: 460px) {
            margin-top: -30px;
        }

        .wavy {
            width: 50px;
            margin-right: -78px;
            margin-top: -50px;
            animation: wave-speaker 2s ease infinite;

            @media (max-width:1693px) and (min-width:768px) {
                width: 45px;
                margin-right: -70px;
                margin-top: -50px;
            }

            @media (max-width:1499px) and (min-width:768px) {
                margin-top: -36px;
                width: 32px;
                margin-right: -58px;
            }

            @media (max-width:460px) {
                margin-top: -35px;
                width: 30px;
                margin-right: -50px;
            }
        }

        img {
            &:not(.wavy) {
                height: 158px;

                @media (max-width:1693px) and (min-width:1500px) {
                    height: 154px;
                }

                @media (max-width:1499px) and (min-width:1200px) {
                    height: 120px;
                }

                @media (max-width:1199px) and (min-width:768px) {
                    height: 116px;
                }

                @media (max-width:460px) {
                    height: 108px;
                }

            }
        }
    }

    .purchase-btn {
        padding: 10px 25px;
        min-width: unset;

        @media (max-width: 575px) {
            padding: 8px 16px;
        }
    }
}

.subscriber-chart-container {
    margin: -22px -10px -24px;

    @media (max-width:1399px) and (min-width:1200px) {
        margin: -22px -10px -45px;
    }

    @media (max-width:488px) {
        margin: -22px -10px -45px;
    }
}

.click-widgets {
    .card-body {
        padding: 28px 15px;
    }

    .click-chart {
        margin: -50px 0 -70px -20px;
    }
}

.follower-wrap {

    .card-body,
    .card-header {
        @media (max-width: 1693px) {
            padding: 22px;
        }
    }

    .card-header {
        padding-bottom: 20px;
    }

    .papernote-wrap {
        >span {
            margin-bottom: 20px;

            @media (max-width:1768px) {
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.campaign-list {
    column-count: 2;
    column-gap: 20px;
    margin-bottom: 14px;

    li {
        position: relative;

        +li {
            &::before {
                position: absolute;
                content: "";
                width: 1px;
                height: 20px;
                background-color: var(--chart-text-color);
                top: 50%;
                transform: translateY(-50%);
                left: -10px;
                opacity: 0.6;

                [dir="rtl"] & {
                    left: unset;
                    right: -10px;
                }
            }
        }
    }

    .campaign-box {
        background: linear-gradient(180deg, var(--course-light-btn) 0%, rgba(242, 243, 247, 0) 100%);
        border-radius: 5px;
        padding: 6px 10px;
    }
}

.social-circle {
    width: 34px;
    height: 34px;
    border-width: 1px;
    border-style: solid;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.campaign-table {
    &.recent-table {
        table {
            tr {

                th,
                td {
                    &:first-child {
                        padding-left: 20px;
                    }

                    &:last-child {
                        padding-right: 20px;
                    }

                    @media (max-width:1399px) and (min-width:1200px) {

                        &:nth-child(3),
                        &:nth-child(6) {
                            display: none;
                        }
                    }

                    @media (max-width:1260px)and (min-width:1200px) {
                        &:last-child {
                            display: none;
                        }

                        &:nth-child(5) {
                            padding-right: 20px;
                        }
                    }
                }

                &:hover {
                    td {
                        &:nth-child(2) {
                            color: var(--theme-default);
                        }
                    }
                }
            }
        }
    }

    .border-icon {
        >div {
            border-left: 2px solid $fb-color;
            padding-left: 15px;

            [dir="rtl"] & {
                padding-left: unset;
                padding-right: 15px;
            }
        }
    }

    .facebook {
        >div {
            border-left: 2px solid $fb-color;

            [dir="rtl"] & {
                border-left: unset;
                border-right: 2px solid $fb-color;
            }
        }

        .social-circle {
            background-color: rgba($fb-color, 0.1);
            border-color: $fb-color;
            color: $fb-color;
        }
    }

    .instagram {
        >div {
            border-left: 2px solid $insta-color;

            [dir="rtl"] & {
                border-left: unset;
                border-right: 2px solid $insta-color;
            }
        }

        .social-circle {
            background-color: rgba($insta-color, 0.1);
            border-color: $insta-color;
            color: $insta-color;
        }
    }

    .pinterest {
        >div {
            border-left: 2px solid $pintrest-color;

            [dir="rtl"] & {
                border-left: unset;
                border-right: 2px solid $pintrest-color;
            }
        }

        .social-circle {
            background-color: rgba($pintrest-color, 0.1);
            border-color: $pintrest-color;
            color: $pintrest-color;
        }
    }

    .plus-btn {
        width: 24px;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid var(--body-font-color);
        color: var(--body-font-color);

        &:hover {
            background-color: var(--body-font-color);
            color: var(--white);
        }
    }

    .twitter {
        >div {
            border-left: 2px solid $twitter-color;

            [dir="rtl"] & {
                border-left: unset;
                border-right: 2px solid $twitter-color;
            }
        }

        .social-circle {
            background-color: rgba($twitter-color, 0.1);
            border-color: $twitter-color;
            color: $twitter-color;
        }
    }

    .you-tube {
        >div {
            border-left: 2px solid $youtube-color;

            [dir="rtl"] & {
                border-left: unset;
                border-right: 2px solid $youtube-color;
            }
        }

        .social-circle {
            background-color: rgba($youtube-color, 0.1);
            border-color: $youtube-color;
            color: $youtube-color;
        }
    }

    .linked-in {
        >div {
            border-left: 2px solid $linkedin;

            [dir="rtl"] & {
                border-left: unset;
                border-right: 2px solid $linkedin;
            }
        }

        .social-circle {
            background-color: rgba($linkedin, 0.1);
            border-color: $linkedin;
            color: $linkedin;
        }
    }

    .badge-light-primary,
    .badge-light-light {
        border: unset;
        min-width: 110px;
    }

    tr {

        td,
        th {
            border-style: dashed;

        }
    }

    .recent-table {
        &.table-responsive {
            div.dt-container {
                .dt-layout-row {
                    &:last-child {
                        @media (max-width:1399px) {
                            flex-direction: column;
                        }
                    }
                }
            }

            table {

                thead,
                tbody {
                    tr {

                        td,
                        th {
                            &:first-child {
                                min-width: 88px;

                                @media (max-width:775px) {
                                    min-width: 116px;
                                }
                            }

                            &:nth-child(2) {
                                min-width: 80px;
                            }

                            &:nth-child(3),
                            &:last-child {
                                min-width: 36px;
                            }

                            &:nth-child(3) {
                                @media (max-width:1566px) and (min-width:1200px) {
                                    display: none;
                                }
                            }

                            @media (max-width:1455px)and (min-width:1200px) {
                                &:last-child {
                                    display: none;
                                }

                                &:nth-child(6) {
                                    padding-right: 20px;
                                }
                            }

                            @media (max-width:1399px) and (min-width:1200px) {
                                &:nth-child(6) {
                                    display: none;
                                }

                                &:nth-child(5) {
                                    padding-right: 20px;

                                }
                            }

                            @media (max-width:1255px) and (min-width:1200px) {
                                min-width: auto;
                            }

                            @media (max-width:1215px) and (min-width:1200px) {
                                &:nth-child(4) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.analytics-data {
    .balance-data {
        @media (max-width:938px) {
            display: none;
        }
    }
}

.photo-click-wrapper {
    margin: -12px -20px -20px 0px;
}

// Social Tabs
.social-tabs {
    .tab-content {
        @media (max-width:413px) {
            margin-bottom: -45px;
        }
    }

    .nav {
        gap: 16px;

        @media (max-width:1210px) {
            gap: 10px;
        }

        @media (max-width:575px) {
            display: flex;
        }

        .social-box {
            padding: 12px 16px;
            border: 1px solid transparent;
            border-radius: 4px;
            transition: all 0.4s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            @media (max-width:1528px) {
                gap: 12px;
            }

            span {
                color: var(--body-font-color);
            }

            .frame-image {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                [class*='outline-10-'],
                [class*='outline-20-'] {
                    border-radius: 50%;
                    outline-offset: 4px;

                    [class*='bg-10-'],
                    [class*='bg-20-'] {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;

                        @media (max-width:1528px) {
                            width: 28px;
                            height: 28px;
                        }
                    }

                    [class*='fa-']:not(.fa-youtube-play) {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        font-size: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .youtube-color {
                        color: $youtube-color;
                    }

                    .instagram-color {
                        background: $instagram-color;
                    }
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            >div {
                margin-bottom: -20px;
            }
        }
    }
}

.top-country {
    .common-space {
        margin-bottom: 8px;

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            p {
                font-size: 16px;

                @media (max-width:1520px) {
                    font-size: 15px;
                }
            }
        }

        span {
            @media (max-width:1460px) and (min-width:768px) {
                display: none;
            }
        }
    }

    img {
        height: calc(25px + (37 - 25) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 50%;
    }

    .progress {
        height: 7px;
        background: linear-gradient(180.36deg, #CFDCE8 -32.82%, #FFFFFF 132.82%);
    }
}

.social-clicks {
    .analytics-tread-card {
        .card-header {
            .common-align {
                span {
                    @media (max-width:1791px) {
                        &:first-child {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    &:nth-child(2) {
                        @media (max-width:1499px) and (min-width:1200px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    i {
        font-size: 10px;
    }
}

.follower-country {
    #countryMap {
        height: 268px;

        @media (max-width:1821px) and (min-width:1015px) {
            height: 345px;
        }

        @media (max-width:1015px) and (min-width:992px) {
            height: 408px;
        }

        @media (max-width:991px) and (min-width:926px) {
            height: 345px;
        }

        @media (max-width:925px) and (min-width:808px) {
            height: 410px;
        }

        @media (max-width:807px) and (min-width:768px) {
            height: 400px;
        }
    }
}

.s-xxl-3 {
    @media (max-width:1499px) {
        width: 33.33333333%;
        flex: 0 0 auto;
    }

    @media (max-width:767px) {
        width: 50%;
    }

    @media (max-width:575px) {
        width: 100%;
    }
}

@media (max-width:1199px) {
    .od-xl-1 {
        order: 1;
    }

    .od-xl-2 {
        order: 2;
    }

    .od-xl-3 {
        order: 3;
    }

    .od-xl-4 {
        order: 4;
    }

    .od-xl-5 {
        order: 5;
    }

    .od-xl-6 {
        order: 6;
    }

    .od-xl-7 {
        order: 7;
    }

    .od-xl-8 {
        order: 8;
    }
}

/**=====================
    3.13 Dashboard_5 CSS Ends
==========================**/