/**=====================
  3.1 Add-product CSS Start
==========================**/

.sidebar-left-wrapper {
    border-right: 1px solid var(--recent-dashed-border);

    [dir="rtl"] & {
        border-right: unset;
        border-left: 1px solid var(--recent-dashed-border);
    }

    @media (max-width:1199px) {
        border-right: unset;
        margin-bottom: 25px;

        [dir="rtl"] & {
            border-left: unset;
        }
    }


    .sidebar-left-icons {
        display: block;

        .nav-item {
            position: relative;
            padding-bottom: 50px;
            z-index: 0;
            overflow: hidden;

            @media (max-width:1199px) {
                padding-bottom: 25px;
            }

            &:first-child {
                .nav-link {
                    padding-top: 0;
                }
            }

            &:last-child {
                padding-bottom: 0;

                .nav-link {
                    padding-bottom: 0;
                }
            }

            &::before {
                content: "";
                position: absolute;
                border: 1px dashed rgba($badge-light-color, 0.3);
                bottom: 0px;
                height: calc(100% - 42px);
                left: 20px;
                z-index: -1;

                [dir="rtl"] & {
                    left: unset;
                    right: 20px;
                }

                @media (max-width:1199px) {
                    height: 25px;
                }
            }

            &:last-child {

                &::before {
                    content: none;
                }

                .nav-link {
                    padding-bottom: 0;
                }
            }

            .nav-link {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 8px 0;
                background-color: var(--white);

                &.active,
                &:active {
                    color: unset;

                    .nav-rounded {
                        border: 1px solid var(--theme-default);

                        .product-icons {
                            background-color: var(--theme-default);

                            svg {
                                stroke: $white;
                                animation: tada 2.4s ease infinite;
                            }
                        }
                    }

                    .product-tab-content {
                        h6 {
                            color: var(--theme-default);
                            font-weight: 500;
                        }
                    }
                }

                .nav-rounded {
                    min-width: 42px;
                    height: 42px;
                    border: 1px solid var(--recent-dashed-border);
                    position: relative;
                    z-index: 2;
                    border-radius: 100%;
                    padding: 2px;
                    box-shadow: inset 0px 9px 20px 0px rgb(46 35 94 / 7%);


                    .product-icons {
                        min-width: 100%;
                        height: 100%;
                        background-color: var(--white);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;

                        svg {
                            @include pixelstrap-size(18px, 18px);
                            stroke: rgba($badge-light-color, 0.8);
                        }
                    }
                }

                .product-tab-content {
                    h6 {
                        color: var(--body-font-color);
                        font-weight: 400;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 100%;

                        @media (max-width:575px) {
                            text-align: left;
                        }
                    }

                    p {
                        color: var(--chart-text-color);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 100%;

                        @media (max-width:575px) {
                            text-align: left;
                        }
                    }
                }
            }

            &:hover {
                .nav-rounded {
                    border: 1px solid var(--theme-default);

                    .product-icons {
                        background-color: var(--light-shade-primary, 1);

                        svg {
                            stroke: var(--theme-default);
                            animation: tada 1.5s ease infinite;
                        }
                    }
                }

                .product-tab-content {
                    h6 {
                        color: var(--theme-default);
                    }
                }
            }
        }
    }
}

// Product 
.sidebar-body {
    &.advance-options {
        .nav-tabs {
            .nav-item {
                @media (max-width:576px) {
                    padding: 10px 0;
                }
            }
        }
    }

    form {

        .form-control,
        .form-select {
            &:focus {
                box-shadow: unset;
                border-color: unset;
            }
        }
    }

    .ql-snow {
        .ql-picker-label {
            [dir="rtl"] & {
                padding-right: 8px;
                padding-left: 2px;
            }
        }
    }

    .ql-toolbar {
        &.ql-snow {
            .ql-formats {
                @media (max-width:575px) {
                    margin-right: 0 !important;
                }
            }
        }
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        [dir="rtl"] & {
            right: unset;
            left: 0;
        }
    }

    .form-select {
        [dir="rtl"] & {
            padding: 6px 12px 6px 36px !important;
        }
    }

    .ql-formats {
        border-right: 1px solid var(--recent-dashed-border);

        [dir="rtl"] & {
            border-left: 1px solid var(--recent-dashed-border);
            border-right: unset;
        }

        @media (max-width:575px) {
            border-right: unset;

            [dir="rtl"] & {
                border-left: unset;

            }
        }

        &:last-of-type {
            border-right: unset;

            [dir="rtl"] & {
                border-left: unset;
            }
        }
    }

    #editor2 {
        min-height: 160px;
    }
}

// Gallery product
.sidebar-body {

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: inner-spin-button;
    }

    .form-select,
    .form-control {
        font-size: 14px;
        color: rgba($badge-light-color, 0.8);
    }

    .product-upload {
        .dropzone {
            .dz-message {
                i {
                    font-size: 42px;
                }
            }
        }

        p {
            margin-bottom: calc(8px + (18 - 8) * ((100vw - 320px) / (1920 - 320)));
            line-height: 0;

            @media (max-width:344px) {
                line-height: normal;
            }
        }

        &:nth-child(1) {
            margin-bottom: 40px;

            @media (max-width:400px) {
                margin-bottom: 26px;
            }
        }

        &:nth-child(2) {
            margin-bottom: 65px;

            @media (max-width:1199px) {
                margin-bottom: 0;
            }
        }


        form {
            svg {
                @include pixelstrap-size(48px, 48px);
            }

            h6 {
                font-weight: 400;
                font-size: 14px;
                line-height: 1.8;
            }

            span {
                color: var(--chart-text-color);
                font-size: 14px;
            }
        }
    }
}

.category-button {
    padding-bottom: 30px;
    border-bottom: 1px dashed var(--recent-dashed-border);

    @media (max-width:575px) {
        padding-bottom: 25px;
    }

    a {
        padding: 12px;
    }
}

.create-category {
    .toolbar-box {
        margin-top: 12px;
    }
}

.product-date {
    .form-control[readonly] {
        background-color: var(--white);
    }
}

.price-wrapper {
    .radio-wrapper {
        justify-content: flex-start;
        margin-top: 4px;

        li {
            max-width: 200px;

            .form-check-input {
                border-radius: 5px;
                background-color: var(--light-background);

                ~.form-check-label {
                    span {
                        color: $color-scarpa;
                        font-size: 15px;
                        position: relative;
                    }
                }

                &:checked {
                    background-color: var(--light-background);
                    border: 1px solid var(--theme-default);
                    border-radius: 5px;

                    ~.form-check-label {
                        span {
                            color: var(--theme-default);
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    .form-select {
        font-size: 15px;
        color: rgba($badge-light-color, 0.8);
    }
}

// Advance options
.advance-options {
    ul {
        border-bottom: 1px solid var(--recent-dashed-border);

        &.border-tab {
            &.nav-tabs {
                row-gap: 18px;
                flex-wrap: wrap;

                .nav-item {
                    .nav-link {
                        color: var(--body-font-color);
                        border-bottom: 2px solid transparent;
                        transition: all 0.3s ease-in-out;
                        padding: 0 calc(2px + (25 - 2) * ((100vw - 320px) / (1920 - 320))) 10px calc(2px + (25 - 2) * ((100vw - 320px) / (1920 - 320)));

                        @media (max-width:1199px) {
                            padding: 0 calc(5px + (25 - 5) * ((100vw - 320px) / (1920 - 320))) 10px calc(5px + (25 - 5) * ((100vw - 320px) / (1920 - 320)));
                        }

                        &:active,
                        &.active {
                            border-bottom: 2px solid var(--theme-default);
                            color: var(--body-font-color);
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

.meta-body {
    padding-top: 16px;

    .custom-input {
        .form-check {
            .form-check-input {

                &:checked {
                    background-color: var(--theme-default);
                }

                &:valid {
                    border-color: var(--theme-default);

                    ~ {
                        .form-check-label {
                            color: rgba($badge-light-color, 0.8);
                        }
                    }
                }
            }
        }
    }
}

.product-tag {
    .tagify {
        border-radius: 4px;
        padding: 0;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
    }
}

.tagify__tag {
    margin: 5px 0 5px 5px;

    [dir="rtl"] & {
        margin-right: 5px;
        margin-left: 0px;
    }
}

.tagify__input {
    margin: 0;
}

.tagify__tag__removeBtn {
    [dir="rtl"] & {
        margin-right: auto;
        margin-left: 4.6666666667px;
    }
}

.list-product {

    &.list-category {
        .square-white {

            @include square-box(34px, 34px, 2px, $white);
            @include pixelstrap-flex(flex, center, center);
            box-shadow: 0px 0px 28px 6px rgba(235, 235, 235, 0.4);

            svg {
                fill: rgba($badge-light-color, 0.8);
                @include pixelstrap-size(16px, 16px);
            }
        }

        div.dt-container {
            .dt-layout-row {
                .dt-search {
                    top: 26px;
                    right: 214px;

                    @media (max-width:640px) {
                        top: 4px;
                    }

                    .dt-input {
                        width: auto;
                    }
                }
            }
        }

        table {

            thead,
            tbody {
                tr {

                    th,
                    td {

                        color: var(--body-font-color);
                        min-width: auto;

                        &:first-child {
                            padding-left: 20px;
                        }

                        &:last-child {
                            padding-right: 20px;
                        }

                        p {
                            @media (min-width:1200px) {
                                @include multi-line-ellipsis(1, auto, vertical, hidden);
                            }
                        }

                        @media (min-width:320px) {
                            &:nth-child(4) {
                                min-width: 128px;
                            }
                        }

                        @media (max-width:1310px) {
                            &:nth-child(4) {
                                min-width: 125px;
                            }
                        }

                        @media (max-width:1199px) {
                            &:nth-child(2) {
                                min-width: 220px;
                            }

                            &:nth-child(3) {
                                min-width: 510px;
                            }
                        }

                        .badge {
                            padding: 6px 12px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .datatable-container {
        overflow-x: auto;

        thead {
            tr {
                th {
                    &:nth-child(8) {
                        @media (max-width:1445px) {
                            display: none;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;

                td {
                    &:nth-child(8) {
                        @media (max-width:1445px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .datatable-dropdown {
        position: relative;

        label {
            color: var(--body-font-color);

            &:before {
                content: "\f107";
                position: absolute;
                top: 6px;
                left: 38px;
                font-family: "FontAwesome";
                font-size: 16px;
                margin: 0;
                color: var(--chart-text-color);

                [dir="rtl"] & {
                    right: 38px;
                    left: unset;
                }
            }
        }
    }

    .datatable-selector {
        appearance: none;
        min-width: 55px;

        &:focus,
        &:focus-visible {
            outline: none;
        }
    }

    .datatable-sorter {

        &::before,
        &::after {

            [dir="rtl"] & {
                right: unset;
                left: 4px;
            }
        }
    }

    tr {

        th,
        td {
            text-align: left;

            [dir="rtl"] & {
                text-align: right;
            }
        }

        th:nth-child(1) {
            .datatable-sorter {

                &::before,
                &::after {
                    border: none;

                }
            }
        }

        th,
        td {
            &:nth-child(1) {
                min-width: 20px;
            }
        }

        th,
        td {
            &:nth-child(2) {
                min-width: 110px;

                @media (max-width:1460px) {
                    min-width: 230px;
                }
            }
        }

        th,
        td {
            &:nth-child(3) {
                min-width: 90px;
            }
        }

        th,
        td {
            &:nth-child(4) {
                min-width: 120px;
            }
        }

        th,
        td {
            &:nth-child(5) {
                min-width: 120px;
            }
        }

        th,
        td {
            &:nth-child(6) {
                min-width: 80px;
            }
        }

        th,
        td {
            &:nth-child(7) {
                min-width: 100px;
            }
        }

        th,
        td {
            &:nth-child(8) {
                min-width: 140px;
            }
        }

        th,
        td {
            &:nth-child(9) {
                min-width: 105px;
            }
        }
    }

    &:focus-visible {
        outline: 0;
    }

    .datatable-top {
        display: flex;
        gap: 22px;
        flex-wrap: wrap;
    }

    .datatable-search {
        float: left;

        [dir="rtl"] & {
            float: right;
        }
    }

    .datatable-container {
        &:focus-visible {
            outline: 0;
        }
    }

    .datatable-bottom {
        .datatable-info {
            color: var(--body-font-color);
        }
    }

    .datatable-top {
        padding: 0;
    }

    .datatable-pagination {
        li {
            &.datatable-hidden {
                visibility: visible;

                a {
                    color: var(--theme-default);
                    font-weight: 500;
                    opacity: 1;
                }
            }
        }

        .datatable-active {
            .datatable-pagination-list-item-link {
                background-color: var(--theme-default);
                color: var(--white);
            }
        }

        .datatable-pagination-list-item-link {
            background-color: rgba(var(--light-shade-primary), 0.02);
            border-radius: 5px;
        }
    }
}

.table {
    &#project-status {
        tbody {
            tr {
                td {
                    vertical-align: inherit;
                }
            }
        }
    }
}

.product-names {
    display: flex;
    align-items: center;
    gap: 15px;

    .light-product-box {
        min-width: 49px;
        height: 49px;
        border-radius: 3px;
        background-color: var(--chart-progress-light);
        @include pixelstrap-flex(flex, center, center);

        img {
            height: 26px;
        }
    }
}

.datatable-input,
.datatable-selector {
    border: 1px solid var(--recent-dashed-border);
    border-radius: 5px;
    background: unset;
}

.e-category {
    @media (max-width:767px) {
        .col {
            &.m-t-40 {
                margin-top: 20px !important;
            }
        }
    }

    .category-popup {
        .tagify {
            width: 100%;
            border-radius: 4px;
            padding: 0;
            height: auto;
            width: 100%;
            display: flex;
            align-items: center;
            border-color: var(--bs-border-color);
        }
    }

    .form-label {
        color: $badge-light-color;
    }

    .form-control {
        font-size: 14px;
        color: var(--body-font-color);

        &::placeholder {
            color: rgba($badge-light-color, 0.6);
        }
    }

    .form-select {
        font-size: 14px;
        color: rgba($badge-light-color, 0.8);
    }

    .main-divider {
        padding-block: 18px;

        .divider-body {
            position: relative;
            z-index: 0;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                border: 1px dashed rgba($badge-light-color, 0.1);
                z-index: -1;
            }

            h6 {
                background-color: var(--white);
                display: inline-block;
                color: $badge-light-color;
                padding-right: 12px;
            }
        }
    }
}

// Discount options
.discount-options {
    justify-content: flex-start;
    margin-top: 4px;
    gap: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));

    @media (max-width:575px) {
        display: flex !important;
    }

    .nav-item {
        .nav-link {
            position: relative;
            width: 185px;
            height: 70px;
            padding: 12px;
            border: none;
            text-align: center;
            @include pixelstrap-flex(flex, center, center);
            border-radius: 5px;
            background-color: var(--light-background);
            color: $color-scarpa;
            font-size: 15px;

            @media (min-width:1200px) {
                width: calc(170px + (185 - 170) * ((100vw - 320px) / (1920 - 320)));
                height: calc(58px + (70 - 58) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
            }

            &.active {
                background-color: var(--theme-default);
                color: $white;
            }
        }
    }
}

.common-form {
    .product-buttons {
        margin: 15px 0 0 0;
        text-align: end;
        border-top: 1px solid var(--recent-dashed-border);
        padding: 12px 0 0 0;
        position: absolute;
        bottom: 0;
        width: calc(100% - 48px);
        left: 50%;
        transform: translateX(-50%);

        [dir="rtl"] & {
            left: unset;
            right: 50%;
            transform: translateX(50%);
        }

        @media (max-width:1199px) {
            position: unset;
            width: 100%;
            transform: unset;

            [dir="rtl"] & {
                transform: unset;
            }
        }

        svg {
            @include svg-size(18px, var(--chart-text-color));
            vertical-align: sub;

            @media (max-width:400px) {
                @include pixelstrap-size(15px, 15px);
            }
        }

        .btn {
            border: 1px solid $gray-60;
            color: var(--chart-text-color);
            font-weight: 500;
            padding: 6px 20px;

            @media (max-width:1400px) {
                padding: 6px 16px;
            }

            @media (max-width:400px) {
                padding: 6px 8px;
            }

            &:hover,
            &:active {
                background-color: var(--theme-default);
                color: $light;

                svg {
                    stroke: $light;
                }
            }

            &:first-child {
                margin-right: 12px;

                svg {
                    margin-right: 8px;
                }
            }

            &:last-child {
                svg {
                    margin-left: 8px;
                }
            }
        }
    }
}

.common-tagify {
    .tagify {
        border-radius: 4px;
        padding: 0;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
    }
}

.category-btn {
    @include pixelstrap-flex(flex, center, center);
    margin-top: 24px;

    @media (max-width:767px) {
        margin-top: 0;
    }
}

.product-page-width {
    tbody {
        tr {
            td {
                padding: 8px 12px;
                border: 1px solid var(--recent-dashed-border);
            }
        }
    }
}

// Review and rating 
.review-box {
    .product-rating {
        .common-flex {
            gap: 6px;

            i {
                font-size: 18px;
                color: rgba($badge-light-color, 0.8);

                &.fill {
                    color: $warning-color;
                }
            }
        }
    }

    .rating-box {
        ul {
            @include pixelstrap-flex(flex, center, center);
            gap: 10px;
            flex-wrap: wrap;

            li {
                width: 100%;

                .rating-list {
                    @include pixelstrap-flex(flex, center, center);
                    gap: 12px;
                    flex-wrap: nowrap;

                    h6 {
                        white-space: nowrap;
                        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                        line-height: 1.2;
                        margin: 0;
                        font-weight: 400;

                        i {
                            margin-left: 3px;
                            font-size: 14px;
                            color: rgba($badge-light-color, 0.6);

                            [dir="rtl"] & {
                                margin-left: unset;
                                margin-right: 3px;
                            }
                        }
                    }

                    .progress {
                        width: 100%;
                        border-radius: 4px;
                        display: flex;
                        height: 8px;
                        overflow: hidden;
                        font-size: 12px;

                        .progress-bar {
                            background: var(--theme-default);
                        }
                    }
                }
            }
        }
    }

    .review-people {
        .review-list {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            align-items: center;
            max-height: 330px;
            overflow-y: auto;

            li {
                display: block;
                width: 100%;

                .people-box {
                    display: flex;
                    width: 100%;
                    border-radius: 10px;
                    padding: 16px 20px;
                    background: var(--light-background);

                    @media (max-width:575px) {
                        flex-wrap: wrap;
                        gap: 8px;
                    }

                    .people-comment {
                        .people-name {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            flex-wrap: wrap;

                            >div {
                                display: flex;
                                gap: 3px;
                            }
                        }

                        .date-time {
                            display: block;
                        }

                        .product-rating {
                            .common-flex {
                                gap: 3px;

                                i {
                                    font-size: 12px;
                                }
                            }
                        }

                        h6 {
                            font-size: 13px;
                            color: rgba($badge-light-color, 0.8);
                            font-weight: 400;
                            margin-left: auto;

                            [dir="rtl"] & {
                                margin-left: unset;
                                margin-right: auto;
                            }
                        }
                    }

                    img {
                        height: calc(45px + (58 - 45) * ((100vw - 320px) / (1920 - 320)));
                        border-radius: 8px;
                        object-fit: cover;
                        transition: all 0.3s ease-in-out;
                    }

                    .people-comment {
                        padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

                        [dir="rtl"] & {
                            padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
                            padding-left: unset;
                        }

                        @media (max-width:575px) {
                            padding-left: 0;

                            [dir="rtl"] & {
                                padding-left: unset;
                                padding-right: 0;
                            }
                        }

                        .name {
                            display: block;
                            font-weight: 500;

                            &:hover {
                                color: var(--theme-default);
                            }
                        }


                        .date-time {
                            @include pixelstrap-flex(flex, space-between, center);
                            width: 100%;

                            h6 {
                                margin: 0;
                            }
                        }
                    }

                    .reply {
                        margin-top: 2px;
                        line-height: 1.6;

                        p {
                            margin: 0;
                            line-height: 1.6;
                            @include multi-line-ellipsis(2, auto, vertical, hidden);

                            @media (max-width:575px) {
                                -webkit-line-clamp: 3;
                            }
                        }

                    }
                }
            }
        }
    }
}

.product-rating-box {
    .main-product-rating {
        font-size: 30px;

        i {
            font-size: 22px;
            color: $warning-color;
            margin-left: 4px;
            margin-top: -8px;
            vertical-align: middle;

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: 4px;
            }
        }
    }

    .review-title-2 {
        border-top: 1px solid var(--chart-progress-light);
        padding-top: calc(13px + 12*(100vw - 320px)/1600);
        margin-top: calc(13px + 12*(100vw - 320px)/1600);

        p {
            margin-bottom: calc(7px + 6*(100vw - 320px)/1600);
            font-size: 15px;
            line-height: 1.6;
            color: rgba($badge-light-color, 0.6);
        }
    }

    .product-review-rating {
        @include pixelstrap-flex(flex, flex-start, center);
        gap: 8px;
        padding-bottom: 12px;
        margin-bottom: 12px;

        .product-rating {
            .common-flex {
                i {
                    font-size: 14px;
                }
            }
        }
    }
}

.list-product-view {
    .list-product {
        .recent-table {
            div.dt-container {
                .dt-layout-row {
                    .dt-search {
                        top: 26px;
                        right: 214px;

                        @media (max-width:640px) {
                            top: 4px;
                        }

                        .dt-input {
                            width: auto;
                        }
                    }
                }
            }

            table {

                thead,
                tbody {
                    tr {

                        th,
                        td {

                            color: var(--body-font-color);
                            min-width: auto;

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }

                            .product-action {
                                @include pixelstrap-flex(flex, flex-start, center);
                                gap: 8px;

                                .square-white {
                                    @include square-box(34px, 34px, 2px, $white);
                                    @include pixelstrap-flex(flex, center, center);
                                    box-shadow: 0px 0px 28px 6px rgba(235, 235, 235, 0.4);

                                    svg {
                                        @include pixelstrap-size(18px, 18px);
                                        cursor: pointer;
                                        fill: var(--chart-text-color);
                                        @include pixelstrap-size(16px, 16px);
                                    }
                                }
                            }

                            .badge {
                                padding: 6px 12px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.list-product {
    .product-list-table {
        &.recent-table {

            thead,
            tbody {
                tr {

                    th,
                    td {
                        a {
                            color: var(--body-font-color);
                        }

                        .product-names {
                            p {
                                @include pixelstrap-ellipsis;
                            }
                        }

                        &:nth-child(4) {
                            @include pixelstrap-ellipsis;
                        }

                        @media (max-width:1382px) and (min-width:1200px) {
                            &:nth-child(6) {
                                display: none;
                            }
                        }

                        @media (max-width:1330px) and (min-width:1200px) {
                            &:nth-child(8) {
                                display: none;
                            }
                        }

                        @media (max-width:1199px) {
                            &:nth-child(2) {
                                min-width: 285px;
                            }

                            &:nth-child(3) {
                                min-width: 110px;
                            }

                            &:nth-child(4) {
                                min-width: 138px;
                            }

                            &:nth-child(5) {
                                min-width: 90px;
                            }

                            &:nth-child(7) {
                                min-width: 98px;
                            }

                            &:nth-child(8) {
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-list-table {

    thead,
    tbody {
        tr {

            th,
            td {
                @media (max-width:1382px) and (min-width:1200px) {
                    &:nth-child(6) {
                        display: none;
                    }
                }

                @media (max-width:1330px) and (min-width:1200px) {
                    &:nth-child(8) {
                        display: none;
                    }
                }

                @media (max-width:1199px) {
                    &:nth-child(2) {
                        min-width: 285px;
                    }

                    &:nth-child(3) {
                        min-width: 110px;
                    }

                    &:nth-child(4) {
                        min-width: 138px;
                    }

                    &:nth-child(5) {
                        min-width: 90px;
                    }

                    &:nth-child(8) {
                        min-width: 100px;
                    }
                }
            }
        }
    }
}

.product-info-table {
    .table {
        tbody {
            tr {
                td {
                    color: rgba($badge-light-color, 0.8);
                }
            }
        }

        thead,
        tbody {
            tr {

                th,
                td {
                    @media (max-width:767px) {
                        &:nth-child(3) {
                            min-width: 108px;
                        }

                        &:nth-child(4) {
                            min-width: 100px;
                        }

                        &:nth-child(5) {
                            min-width: 125px;
                        }
                    }
                }
            }
        }
    }
}

/**=====================
  3.1 Add-product CSS Start
==========================**/