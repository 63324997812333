/**=====================
    3.4 Cart CSS start
==========================**/
.shopping-cart-table {
	.recent-table {
		div.dt-container {
			.dt-layout-row {
				.dt-search {
					right: 20px;

					@media (max-width:640px) {
						top: 4px;
					}

					.dt-input {
						width: auto;
					}
				}

				.dt-length {
					top: 16px;
				}
			}

			.table {

				thead,
				tbody {
					tr {
						&:hover {
							td {
								&:nth-child(2) {
									.product-names {
										img {
											transform: scale(1.1);
											transition: all 0.3s ease-in-out;
										}
									}
								}
							}
						}

						th,
						td {

							color: var(--body-font-color);
							min-width: auto;

							&:first-child {
								padding-left: 20px;
							}

							&:last-child {
								padding-right: 20px;
							}

							a {
								color: $badge-light-color;
							}

							.touchspin-wrapper {
								justify-content: flex-start;

								.input-touchspin {
									width: 68px;
								}
							}

							@media (max-width:1660px) {
								&:nth-child(2) {
									min-width: 258px;
								}

								&:nth-child(4) {
									min-width: 140px;
								}

								&:last-child {
									min-width: 100px;
								}
							}

							@media (max-width:575px) {
								.touchspin-wrapper {
									.btn-touchspin {
										width: 24px;
										height: 24px;

										i {
											font-size: 8px;
										}
									}

									.input-touchspin[type='number'] {
										width: 50px;
										height: 20px;
										font-size: 12px;
									}
								}

								&:nth-child(4) {
									min-width: 130px;
								}
							}

							.product-names {
								.light-product-box {
									min-width: calc(72px + (140 - 72) * ((100vw - 1200px) / (1920 - 1200)));
									min-height: calc(70px + (100 - 70) * ((100vw - 1200px) / (1920 - 1200)));

									@media (max-width:1199px) {
										min-width: calc(70px + (140 - 70) * ((100vw - 320px) / (1920 - 320)));
										min-height: calc(60px + (100 - 60) * ((100vw - 320px) / (1920 - 320)));
									}

									img {
										height: calc(35px + (68 - 35) * ((100vw - 1200px) / (1920 - 1200)));
										transition: all 0.3s ease-in-out;

										@media (max-width:1199px) {
											height: calc(35px + (68 - 35) * ((100vw - 320px) / (1920 - 320)));
										}
									}
								}

								p,
								span {
									color: rgba($badge-light-color, 0.8);
								}

								ul {
									li {
										>p {
											margin-bottom: 0;
											display: inline-block;
										}

										.common-dot {
											@include pixelstrap-size(5px, 5px);
											border-radius: 50%;
											background-color: rgba($badge-light-color, 0.8);
											margin: 1px 6px 1px 6px;
										}
									}
								}
							}

							.product-action {
								display: flex;
								align-items: center;
								justify-content: flex-start;
								gap: 8px;

								.square-white {
									@include square-box(calc(36px + (42 - 36) * ((100vw - 320px) / (1920 - 320))), calc(36px + (42 - 36) * ((100vw - 320px) / (1920 - 320))), 2px, $white);
									@include pixelstrap-flex(flex, center, center);
									box-shadow: 0px 0px 28px 6px rgba(235, 235, 235, 0.4);

									svg {
										@include pixelstrap-size(calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320))), calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320))));
										cursor: pointer;
										fill: var(--chart-text-color);
									}
								}
							}
						}

						&:last-child {

							th,
							td {
								border-bottom: unset;
							}

							td {
								padding-bottom: 0;
							}
						}
					}
				}

				thead {
					tr {
						th {
							.dt-column-order {
								display: none;
							}

							.badge {
								vertical-align: text-bottom;
								margin-left: 4px;

								@media (max-width:575px) {
									padding: 2px 4px;
									font-size: 10px;
								}
							}

							&:last-child {
								span {
									@include pixelstrap-flex(flex, center, center);
									gap: 12px;
									cursor: pointer;

									svg {
										@include pixelstrap-size(16px, 16px);
										fill: $theme-body-font-color;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.cart-summary {
	li {
		@include pixelstrap-flex(flex, space-between, center);
		padding-block: 12px;
		@include pixelstrap-child(0, 0);

		@media (max-width:575px) {
			padding-block: 8px;
		}
	}
}

.product-total {
	padding: 0 20px 20px 20px;
	border-top: 1px solid var(--chart-progress-light);

	[dir="rtl"] & {
		padding: 0 20px 20px 20px !important;
	}

	>li:not(:last-child) {
		padding-block: 18px;
		@include pixelstrap-flex(flex, space-between, center);
	}
}

.cart-buttons {
	@include pixelstrap-flex(flex, center, center);
	flex-wrap: wrap;
	gap: 12px;

	@media (max-width:1660px) {
		justify-content: flex-end;
	}

	li {
		@media (min-width:1661px) {
			width: 100%;
		}

		.btn {
			width: 100%;
			@include pixelstrap-flex(flex, center, center);
			color: $white;
			gap: 5px;

			&.btn-hover-effect {
				background-color: rgba($gray-60, 0.4);
				color: $theme-body-font-color;

				svg {
					fill: $theme-body-font-color;
				}
			}

			svg {
				@include pixelstrap-size(18px, 18px);
				transform: scaleX(-1);
				vertical-align: middle;
				transition: 0.5s;
			}

			&:hover {
				svg {
					transform: translateX(-7px) scaleX(-1);
					transition: 0.5s;
				}
			}
		}
	}
}

.coupon-cart {
	.input-group {
		.form-control {
			border: 2px solid var(--theme-default);
		}
	}
}

/**=====================
    3.4 Cart CSS Ends
==========================**/