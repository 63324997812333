/**=====================
	4.1. Dark CSS Start
==========================**/
body {
	&.dark-only {
		color: $dark-all-font-color;
		background-color: $dark-body-background;

		.card {
			--bs-card-title-color: var(--body-font-color);
			--bs-card-subtitle-color: var(--body-font-color);
			--bs-card-cap-color: var(--body-font-color);
			--bs-card-color: var(--body-font-color);
			--bs-body-color: var(--body-font-color);
		}

		.breadcrumb {
			--bs-breadcrumb-bg: transparent;
		}

		.toast {
			--bs-toast-color: var(--body-font-color);
		}

		.modal {
			--bs-modal-color: rgba($white, 0.4);
			--bs-modal-footer-bg: $dark-card-background;
		}

		.form-control {
			background-color: $dark-card-background;
			border-color: $dark-card-border;

			&::placeholder {
				color: rgba($white, 0.3);
			}
		}

		.c-light {
			color: $dark-small-font-color;
		}

		.c-o-light {
			color: rgba($dark-all-font-color, 0.5);
		}

		// Input auto-fill
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-background-clip: text;
			-webkit-text-fill-color: $dark-all-font-color;
		}

		// ========================================== start===================================

		input[type=file] {
			&::file-selector-button {
				color: $dark-all-font-color;
				background-color: $dark-card-background !important;

				&:hover {
					color: $dark-all-font-color !important;
					background-color: $dark-card-background !important;
				}
			}
		}

		.apexcharts-tooltip.light {
			.apexcharts-tooltip-title {
				background: $dark-body-background;
				color: $white;
			}
		}

		.apexcharts-tooltip.apexcharts-theme-light {
			border: 1px solid $dark-card-border;
			background: $dark-body-background;
		}

		.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
			border: 1px solid $dark-card-border;
			background: $dark-body-background;
			color: $dark-all-font-color;
		}

		.apexcharts-tooltip-series-group {
			color: $dark-all-font-color !important;
		}

		.apexcharts-yaxistooltip,
		.apexcharts-xaxistooltip {
			color: $dark-all-font-color;
			background: $dark-body-background;
			border: 1px solid $dark-card-border;
		}

		.apexcharts-yaxistooltip-left {

			&::before,
			&::after {
				border-left-color: $dark-card-border;
			}
		}

		.apexcharts-xaxistooltip-bottom {

			&::before,
			&::after {
				border-bottom-color: $dark-card-border;
			}
		}

		.bottom-content {
			.apexcharts-canvas {
				span {
					color: $dark-all-font-color;
				}
			}
		}

		.apexcharts-canvas {
			.apexcharts-tooltip {
				border: 1px solid $dark-card-border;

				.apexcharts-tooltip-title {
					margin-bottom: 0;
				}

				.apexcharts-tooltip-series-group {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}

			.apexcharts-yaxis {
				text {
					fill: $dark-small-font-color;
				}
			}
		}

		.apexcharts-xaxistooltip {
			background-color: $dark-body-background;
			color: $dark-all-font-color;
			border: 1px solid $dark-card-border;
		}

		.apexcharts-xaxistooltip-bottom:before {
			border-bottom-color: $dark-card-border;
		}

		.apexcharts-xaxistooltip-bottom:after {
			border-bottom-color: $dark-card-border;
		}

		.apexcharts-tooltip.apexcharts-theme-light {
			border: 1px solid $dark-card-border;
			background: $dark-body-background;

			.apexcharts-tooltip-text {
				color: $dark-small-font-color;
			}

			.apexcharts-tooltip-title {
				background: $dark-card-background;
				border-bottom: 1px solid $dark-card-border;
				color: $dark-small-font-color;
			}
		}

		.apexcharts-yaxis {
			text {
				color: $dark-all-font-color;
			}
		}

		.theme-form input[type=text] {
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}

		.theme-form textarea {
			background-color: $dark-body-background;
		}

		// =====================================End======================-=========
		.page-body {
			.bg-light {
				background-color: $dark-body-background !important;
			}
		}

		// wizards
		.basic-wizard {
			.stepper-horizontal {
				.step {
					.step-circle {
						border-color: $dark-card-inbox;
					}

					.step-bar-left {
						border-color: $dark-card-border;
					}
				}
			}
		}

		.wizard-4 {
			.step-container {
				background-color: $dark-card-background;
				border-color: $dark-card-border;
			}

			.login-card {
				.login-main {
					background-color: $dark-body-background;
				}
			}
		}

		.total-balance {
			background-blend-mode: overlay;
		}

		.progress {
			background: $light-all-font-color;
		}

		.icon-state {
			.switch-state {
				&:after {
					color: $dark-all-font-color;
				}
			}
		}

		.breadcrumb-item {
			+.breadcrumb-item {
				&::before {
					color: rgba($white , 0.6);
				}
			}
		}

		.social-widget {
			.social-icons {
				box-shadow: 0px 42.1092px 27.8569px $dark-body-background, 0px 8.90772px 7.12618px $dark-body-background, 0px 2.02448px 3.44162px $dark-body-background;
			}
		}

		.calendar-basic {
			.md-sidebar-aside {
				background: $dark-card-background;
			}

			#external-events {
				border-color: $dark-card-border;
			}

			.calendar-default {
				.fc {

					.fc-daygrid-day-number,
					.fc-col-header-cell-cushion {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.clockpicker-popover {
			.popover-title {
				background-color: $dark-card-background;
			}

			&.bottom {
				>.arrow {
					border-bottom-color: $dark-body-background;

					&:after {
						border-bottom-color: $dark-card-background;
					}
				}
			}

			&.top {
				>.arrow {
					border-top-color: $dark-body-background;

					&::after {
						border-top-color: $dark-card-background;
					}
				}
			}

			&.left {
				>.arrow {
					border-left-color: $dark-body-background;

					&::after {
						border-left-color: $dark-card-background;
					}
				}
			}

			.popover-title {
				border-bottom-color: $dark-card-background;
			}
		}

		.fc-theme-standard {

			.fc-scrollgrid,
			.fc-list {
				border-color: $dark-card-border;
			}

			.fc-list-day-cushion {
				background-color: $dark-body-background;
			}
		}

		.fc {
			.fc-list-event {
				&:hover {
					td {
						background-color: $dark-body-background;
					}
				}
			}
		}

		// crypto dashboard
		.currency-widget {
			div[class*=bg-light-] {
				color: $dark-all-font-color;
			}
		}

		.btn {
			&.badge-light-primary {
				&:hover {
					color: rgba($white, 0.6);
				}
			}
		}

		.portfolio-chart-container {
			.apexcharts-canvas {
				.apexcharts-radialbar {
					image {
						display: none;
					}
				}
			}
		}

		// online course dashboard css start
		svg.f-light {
			fill: rgba($white, 0.6);
			opacity: 1;
		}

		.course-widget {
			.btn-light {
				background-color: $dark-body-background !important;
			}
		}

		.schedule-list {
			>li {
				box-shadow: 4px 7px 17px 5px rgba($dark-body-background, 0.3);
			}
		}

		.upcoming-event-wrap {
			.apexcharts-canvas {
				.apexcharts-data-labels {
					rect {
						fill: none;
						stroke: none;
					}
				}
			}
		}

		// online course dashboard  css end

		.page-wrapper {
			&.material-type {
				&.compact-wrapper {
					.page-header {
						&.close_icon {
							.header-wrapper {
								border-bottom: 1px solid $dark-card-border;
							}
						}
					}
				}
			}

			&.advance-layout {
				.page-header {
					background: $dark-card-background;
				}

				.page-body-wrapper {
					.sidebar-wrapper {
						border-bottom: 1px solid $dark-card-border;
					}
				}

				.card {
					border-color: $dark-card-border;
				}
			}

			&.only-body {
				background: $dark-body-background;
			}
		}

		.balance-card {
			.svg-box {
				background: $dark-card-background;
				box-shadow: none;

				svg {
					fill: $dark-all-font-color;
				}
			}
		}

		.f-light {
			color: $dark-all-font-color;
		}

		.apexcharts-tooltip.light {
			.apexcharts-tooltip-title {
				background: $dark-card-background;
				color: $white;
			}
		}

		.card {
			background-color: $dark-card-background;
		}

		.ecommerce-widget {
			border: 1px solid $dark-card-border !important;
		}

		.btn-light {
			color: $dark-all-font-color !important;
		}

		.form-select {
			color: $dark-all-font-color;
		}

		.knowledgebase-search {
			color: $theme-body-font-color;

			.form-control-plaintext {
				color: rgba($dark-all-font-color, 0.4);
			}
		}

		// layout-dark
		.layout-color {

			span,
			h6 {
				color: $dark-all-font-color;
			}
		}

		// Toasts 
		.toast {
			border: 1px solid $dark-card-border;
			-webkit-box-shadow: 0 0.5rem 1rem rgb(38 41 50 / 50%);
			box-shadow: 0 0.5rem 1rem rgb(38 41 50 / 50%);

			.toast-header {
				background-color: $dark-card-background;
				color: $dark-all-font-color;
				border-bottom-color: $dark-card-border;
			}

			.toast-dark {
				background-color: $dark-card-background;
				color: $dark-all-font-color !important;
			}
		}

		.animated-toast {
			.toast {
				background-color: $transparent-color;

				.toast-header {
					border-color: $dark-card-border;
				}

				.toast-body {
					background-color: $dark-card-background;
				}
			}
		}

		.toast-container {
			.toast {
				background-color: $transparent-color;
			}
		}

		.default-toasts {
			.card-body {
				&.toast-rtl {
					.toast {
						background-color: $transparent-color;
					}
				}
			}
		}

		.btn-close {
			filter: brightness(0.8) invert(1);
		}

		.product-price {
			del {
				color: $dark-small-font-color;
			}
		}

		.ProfileCard {
			border: 1px solid $dark-card-border !important;
		}

		.form-control-plaintext {
			color: $dark-all-font-color;
		}

		.form-select {
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}

		.box-layout {
			background-color: $dark-body-background;

			.page-wrapper,
			&.page-wrapper {
				.page-body-wrapper {
					background-color: $transparent-color;
				}

				.page-header {
					.header-wrapper {
						border: 1px solid $dark-border-color;
					}
				}

				&.only-body {
					.page-header {
						.header-wrapper {
							border: unset;
						}
					}
				}
			}
		}

		.form-check-input {
			background-color: $dark-body-background;
			border-color: $dark-card-border;
		}

		.common-toasts {
			.toast {
				border: 1px solid $dark-card-border;

				.btn-close {
					filter: grayscale(1);
				}
			}

			.toast-body {
				color: $dark-editor-document;
			}
		}

		// Common Datatable 
		.dt-button-collection {
			background-color: $dark-body-background;

			.dropdown-item {
				background-color: $dark-body-background;
			}
		}

		.dt-autofill-list {
			&.dtaf-popover-closeable {
				background-color: $dark-card-background;

				.dt-autofill-list-items {
					button {
						background-color: $dark-card-inbox;
						color: $dark-all-font-color;

						&:hover {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		// Common form floating
		.form-floating {

			.form-control,
			.form-select {
				&:not(:placeholder-shown) {
					~label {
						color: rgba($dark-all-font-color, 0.4);

						&::after {
							background-color: $dark-body-background;
						}
					}
				}

				&:focus {
					~label {
						color: rgba($dark-all-font-color, 0.4);

						&::after {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		// Radio and checkbox control
		.radio,
		.checkbox {
			label {
				&::before {
					background-color: $dark-body-background;
				}
			}
		}

		.checkbox-wrapper,
		.radio-wrapper {
			li {
				.form-check-input {
					background-color: transparent;
				}
			}
		}

		.switch-state {
			&:before {
				background-color: $dark-card-background;
			}
		}

		.main-radio-toggle {
			label {
				color: $dark-all-font-color;

				&:hover {
					color: $dark-all-font-color;
				}
			}
		}

		.main-icon-checkbox {
			.checkbox-wrapper {
				label {
					&::before {
						background-color: $transparent-color;
					}
				}
			}
		}

		// Custom Dropdown
		.dropdown {
			&.custom-dropdown {
				.dropdown-menu {
					.dropdown-item {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
			}
		}

		// tooltip css start
		.tooltip {
			&[data-popper-placement="left"] {
				.tooltip-arrow {
					&::before {
						border-left-color: $dark-card-inbox;
					}
				}
			}

			&[data-popper-placement="right"] {
				.tooltip-arrow {
					&::before {
						border-right-color: $dark-card-inbox;
					}
				}
			}

			&[data-popper-placement="top"] {
				.tooltip-arrow {
					&::before {
						border-top-color: $dark-card-inbox;
					}
				}
			}

			&[data-popper-placement="bottom"] {
				.tooltip-arrow {
					&::before {
						border-bottom-color: $dark-card-inbox;
					}
				}
			}

			&.bs-tooltip-top {
				.tooltip-arrow {
					&:before {
						border-top-color: $dark-card-inbox;
					}
				}
			}

			&.bs-tooltip-bottom {
				.tooltip-arrow {
					&:before {
						border-bottom-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}

			&.bs-tooltip-start {
				.tooltip-arrow {
					&:before {
						border-left-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}

			&.bs-tooltip-end {
				.tooltip-arrow {
					&:before {
						border-right-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}

			.tooltip-inner {
				background-color: $dark-card-inbox;
				color: $white;
			}

			.tooltip-arrow {
				&:before {
					border-top-color: $dark-card-inbox;
				}
			}
		}

		.svg-tooltip {
			.common-flex {
				[class*="bg-light-"] {
					border-color: $transparent-color;
				}
			}
		}

		// tooltip css end
		.page-wrapper {
			&.only-body {
				.page-body-wrapper {
					.page-title {
						>.row {
							.col-6 {
								&:first-child {
									h6 {
										color: rgba($dark-all-font-color, 0.5);
									}
								}
							}
						}
					}
				}

				.page-header {
					.header-wrapper {
						.search-full {
							.form-group {
								.Typeahead {
									.u-posRelative {
										background-color: $dark-card-background;
									}
								}
							}

						}
					}
				}
			}

			.page-header {
				.header-wrapper {
					.nav-right {
						.notification-dropdown {
							ul {
								li {
									.btn-close {
										filter: brightness(0.8) invert(1);
									}
								}
							}
						}

						.profile-dropdown li span {
							color: $white;
						}

						.flip-card .flip-card-inner {
							.back {
								background-color: $dark-body-background !important;

								.flip-back-content {
									input {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
										color: $dark-all-font-color;
									}
								}

								li {
									&:last-child {
										border-top: 1px solid $dark-card-border;
									}
								}
							}

							.front {
								background-color: $dark-body-background !important;

								svg {
									stroke: $white;
								}
							}
						}

						.cart-dropdown {
							.total {
								background-color: $dark-card-background;
							}

							.qty-box {
								.input-group {
									.btn {
										color: rgba($white, 0.4);
										border-color: $dark-card-background !important;
										background-color: $dark-card-background !important;
									}
								}
							}
						}
					}
				}
			}
		}

		.qty-box {
			input {
				color: rgba($white, 0.5);
			}
		}

		// Customizer
		.setting-sidebar,
		.layout-sidebar {
			box-shadow: 0 0 10px 1px $dark-card-inbox;

			.customizer-body {
				.bg-light {
					background-color: $dark-body-background !important;
				}

				h6 {
					color: rgba($dark-all-font-color, 0.7);
				}

				.main-layout {
					>li {
						border-color: $dark-card-border;
					}
				}

				.layout-grid {
					&.customizer-mix {
						.color-layout {
							border-color: $dark-card-border;
						}
					}
				}

				.layout-grid:not(.customizer-color) {
					li {
						>.body {
							ul {
								background-color: $dark-card-background;
							}
						}
					}
				}

				.layout-link {
					li {
						background-color: $dark-body-background;

						a {
							color: $dark-all-font-color;

							i {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			.sidebar-type,
			.sidebar-setting {
				>li {
					border-color: $dark-card-border;

					a,
					i {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.customizer-contain {
			color: $dark-body-background;

			.customizer-header {
				p {
					color: $dark-editor-document;
				}
			}

			.customizer-body {
				.main-layout {
					.box-layout {
						background-color: $white;
					}
				}
			}
		}

		.login-dark {
			background-color: $dark-card-background;
			background-blend-mode: overlay;

			.login-main {
				background-color: $dark-card-inbox;
			}

			&.login-card {
				.login-main {
					.text-muted {
						color: rgba($dark-all-font-color, 0.4) !important;
					}
				}
			}
		}

		.login-card {
			.btn-showcase .btn {
				background-color: $dark-card-background !important;
				color: $white;
				border-color: $dark-card-background !important;
			}

			.login-main {
				box-shadow: 0 0 37px rgba($white, 0.05);

				.theme-form {
					input {
						background-color: $dark-card-background !important;
					}

					.or {
						&:before {
							background-color: $dark-card-background;
						}
					}

					.checkbox label::before {
						background-color: $dark-card-background !important;
						border-color: $dark-card-background !important;
					}
				}
			}
		}

		.wizard-4 {
			.action-bar {
				.buttonDisabled {
					background-color: $dark-body-background;
					border-color: $dark-body-background;
					color: $dark-all-font-color;
				}
			}
		}

		.H_ui {
			~div {
				background-color: $dark-body-background !important;
			}
		}

		.swal-icon--success:after,
		.swal-icon--success:before {
			background: $dark-card-background;
		}

		.swal-icon--success__hide-corners {
			background-color: $dark-card-background;
		}

		.note-editor.note-frame {
			.note-status-output {
				border-top: 1px solid $dark-card-background;
			}

			.note-statusbar {
				border-top: 1px solid $dark-card-background;

				.note-resizebar {
					background-color: $dark-card-background;
				}
			}
		}

		.page-link {
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}

		.b-r-light {
			border-right: 1px solid $dark-card-border !important;
		}

		.history-details {
			.d-flex {
				border-top: 1px solid $dark-card-border;
			}

			>div {
				i {
					color: $dark-all-font-color;
				}
			}
		}

		.modal-header {
			border-bottom: 1px solid $dark-card-border;
		}

		.placeholder-light {
			background-color: $white;
		}

		#right-history {
			background-color: $dark-card-background;
			box-shadow: 0 0 9px $dark-body-background;

			h6 {
				span {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.ProfileCard {
			&:hover {
				color: $dark-all-font-color;
				background-color: $dark-card-background;
			}
		}

		.translate_wrapper.active .more_lang {
			&:before {
				border-bottom: 7px solid $dark-card-background;
			}

			.lang {
				border-bottom: 1px solid $dark-body-background;
			}
		}

		.toggle-sidebar {
			svg {
				stroke: var(--theme-default);
			}
		}

		.page-wrapper {
			.page-body-wrapper {
				background-color: $dark-body-background;

				.page-title {
					background-color: $dark-card-background;
					box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 40px;
				}

				div.sidebar-wrapper {
					.sidebar-main {
						.sidebar-links {
							.simplebar-wrapper {
								.simplebar-mask {
									.simplebar-content-wrapper {
										.simplebar-content {
											>li {
												a {
													svg {
														&.stroke-icon {
															stroke: $white;
														}

														&.fill-icon {
															fill: rgba($white, 0.5);
															stroke: transparent;
														}
													}

													&.active {
														svg {
															&.stroke-icon {
																stroke: var(--theme-default);
															}

															&.fill-icon {
																fill: var(--theme-default);
																stroke: transparent;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.sidebar-main {
					.sidebar-links {
						.sidebar-link.active {
							svg {
								&.stroke-icon {
									stroke: var(--theme-default);
								}
							}
						}

						li {
							a {
								span {
									color: $dark-all-font-color;
								}
							}
						}

						.simplebar-wrapper {
							.simplebar-mask {
								.simplebar-content-wrapper {
									.simplebar-content {
										>li {
											.sidebar-link {
												&.active {
													background-color: rgba($primary-color, 0.2);
												}
											}

											.sidebar-submenu {
												li {
													a {
														color: $dark-all-font-color;

														&.active {
															color: var(--theme-default);
														}
													}
												}
											}
										}
									}
								}
							}
						}

						.sidebar-list {
							ul.sidebar-submenu {
								li {
									a {
										span {
											color: $dark-all-font-color;
										}

										&.active {
											color: var(--theme-default);
										}
									}
								}
							}
						}
					}
				}
			}

			.page-header {
				box-shadow: 0px 3px 28px rgb(50 56 70);
			}

			.badge-b-light {
				border-color: rgba($white, 0.4);

				&.txt-dark {
					color: $dark-all-font-color !important;
				}
			}

			.badge-b-dark {
				border-color: $dark-all-font-color;

				&.txt-dark {
					color: $dark-all-font-color !important;
				}
			}

			&.modern-type {

				.page-body-wrapper {
					&.default-menu {
						background-color: $dark-body-background;

						.page-title {
							background-color: transparent;
							border: none;
						}
					}

					.page-body {
						.page-title {
							background-color: unset;
						}
					}
				}
			}

			.sidebar-main-title {
				p {
					color: $dark-all-font-color;
				}
			}

			&.compact-wrapper {
				.page-body-wrapper {
					div.sidebar-wrapper {
						background-color: $dark-card-background;

						.sidebar-main {
							.sidebar-links {
								.sidebar-link.active {
									svg {
										stroke: var(--theme-default);
									}
								}

								li {
									a {
										span {
											color: $dark-all-font-color;
										}
									}
								}

								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													.sidebar-link {
														&.active {
															background-color: rgba($primary-color, 0.2);
														}
													}

													.sidebar-submenu {
														li {
															a {
																color: $dark-all-font-color;

																&.active {
																	color: var(--theme-default);
																}
															}
														}
													}
												}
											}
										}
									}
								}

								.sidebar-list {
									ul.sidebar-submenu {
										li {
											a {
												span {
													color: $dark-all-font-color;
												}

												&.active {
													color: var(--theme-default);
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}

			&.horizontal-wrapper {
				.page-body-wrapper {
					.sidebar-wrapper {
						background: $dark-card-background;
						border-top: 1px solid $dark-card-border;
						box-shadow: 0px 9px 24px rgb(50, 56, 70);

						.sidebar-main {
							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													>a {
														color: $dark-all-font-color;
													}

													.sidebar-submenu {
														background: $dark-card-background;

														li {
															a {
																color: $dark-all-font-color;

																&.active,
																&:hover {
																	color: var(--theme-default);
																}
															}

															.sidebar-submenu {
																background: $dark-card-background;
															}
														}
													}
												}
											}
										}
									}
								}
							}

							.left-arrow,
							.right-arrow {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			&.compact-sidebar {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							>div {
								background: $dark-card-background;
							}

							.sidebar-main {
								.sidebar-links {
									li {
										.sidebar-title {
											border-bottom: 1px solid $dark-card-border;
										}

										a {
											span {
												color: $dark-all-font-color;
											}

											svg {
												stroke: $dark-all-font-color;
											}
										}

										.sidebar-submenu {
											background-color: $dark-card-background;

											li {
												a {
													border-bottom: 1px solid $dark-card-border;
												}
											}
										}
									}

									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {
														.sidebar-link {
															&.active {
																background-color: rgba($primary-color, 0.2);

																span {
																	color: var(--theme-default);
																}

																svg {
																	color: var(--theme-default);
																	stroke: var(--theme-default);
																}
															}
														}

														.sidebar-submenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
									}

									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}

													&.active {
														color: var(--theme-default);
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}

			&.compact-small {
				.page-header {
					&.close_icon {
						.header-wrapper {
							@media (min-width:576px) {
								border-bottom: 1px solid $dark-card-border;
							}
						}
					}
				}

				.page-body-wrapper {
					.page-body {
						.page-title {
							@media (min-width:576px) {
								border-top: 1px solid $dark-card-border;
							}
						}
					}
				}

				&.material-icon {
					.page-header {
						box-shadow: unset;
					}

					.page-body-wrapper {
						.page-body {
							.page-title {
								@media (min-width:576px) {
									border-top: 0;
								}
							}
						}
					}
				}
			}

			&.material-type {
				.page-header {
					.header-wrapper {
						border-radius: 15px 15px 0 0;
					}
				}
			}

			// material icon css
			&.material-icon {
				.page-header {
					.header-wrapper {
						background-color: $transparent-color;

						.nav-right {

							.language-nav {
								.more_lang {
									color: $white;
								}
							}

							.onhover-show-div {
								color: $white;
							}

							.flip-card {
								.flip-card-inner {
									.back {
										.flip-back-content {
											input {
												border: 1px solid $dark-border-color;
												background: $dark-body-background;
												color: $white;
											}
										}
									}
								}
							}
						}
					}

					.header-logo-wrapper {
						background-color: $transparent-color;
					}
				}

				.page-body-wrapper {
					.page-body {
						background-color: $transparent-color;
					}

				}
			}

			&.advance-layout {
				background-color: $dark-body-background;

				.page-header {
					.header-wrapper {
						background: $transparent-color;

						.header-logo-wrapper {
							background: $transparent-color;
						}
					}
				}

				.page-body-wrapper {
					background: $transparent-color;

					.page-body {
						background: $transparent-color;
					}
				}
			}

			&.material-icon {
				.page-body-wrapper {
					.page-title {
						background-color: transparent;
						box-shadow: none;
						border: none;
					}
				}
			}

			.page-header {
				.header-wrapper {
					.search-full {
						background-color: $dark-card-background;

						input {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		@media only screen and (min-width: 768px) {
			.page-wrapper {
				&.material-icon {
					.page-header {
						.header-wrapper {
							.nav-right {
								ul {
									li {
										.profile-dropdown {
											li {
												svg {
													stroke: $white;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		::-webkit-calendar-picker-indicator {
			filter: contrast(0.2);
		}

		.kanban-item {
			background-color: $dark-card-background;
		}

		.kanban-board-header {
			background-color: $dark-body-background;
			border-bottom: 1px solid $dark-card-border;
		}

		.kanban-board .kanban-drag {
			background: $dark-body-background;
		}

		.kanban-container {
			.kanban-board {
				background: $dark-body-background;

				.kanban-item {
					.kanban-box {
						.date {
							color: rgba($dark-all-font-color, 0.4);
						}
					}
				}

				footer {
					.btn {
						background-color: $dark-card-inbox;

						&:hover {
							background-color: rgba($dark-card-inbox, 0.8);
						}
					}
				}
			}
		}

		.project-box {
			border: 1px solid rgba($primary-color, 0.15);
			background-color: $dark-body-background;
		}

		.file-content {
			.form-inline {
				border: 1px solid $dark-body-background;
			}

			.folder .folder-box {
				border: 1px solid rgba($primary-color, 0.15);
				background-color: $dark-body-background;
			}
		}

		.file-sidebar {
			.pricing-plan {
				border: 1px solid rgba($primary-color, 0.15) !important;
			}

			.btn-light {
				color: $dark-all-font-color;
				background-color: $dark-body-background !important;
				border: 1px solid rgba($primary-color, 0.15) !important;
			}
		}

		#lnb {
			border-right: 1px solid $dark-card-border;
		}

		.lnb-new-schedule,
		.lnb-calendars>div {
			border-bottom: 1px solid $dark-card-border;
		}

		#menu .sidebar-list .btn-default {
			color: rgba($dark-all-font-color, 0.2);
		}

		.tui-full-calendar-timegrid-hour {
			background-color: $dark-card-background;
			color: $white !important;
		}

		.tui-full-calendar-timegrid-gridline {
			border-bottom: 1px solid $dark-card-border !important;
		}

		.tui-full-calendar-time-date,
		.tui-full-calendar-weekday-grid-line,
		.tui-full-calendar-left,
		.tui-full-calendar-timegrid-timezone {
			border-right-color: $dark-card-border !important;
		}

		.tui-full-calendar-popup {
			color: $theme-body-font-color;
		}

		#menu {
			.btn-default {
				color: $dark-all-font-color;

				&:hover {
					color: $theme-body-font-color;
				}
			}

			.dropdown-menu {
				color: $dark-all-font-color;
			}
		}

		.tui-full-calendar-dayname-container,
		.tui-full-calendar-splitter {
			border-top-color: $dark-card-border !important;
			border-bottom-color: $dark-card-border !important;
		}

		span.tui-full-calendar-dayname-date-area {
			color: $white !important;
		}

		.tui-full-calendar-layout {
			background-color: $dark-card-background !important;
		}

		// index page css end	

		.blockquote {
			border-left: 4px solid $dark-card-border;
		}

		.figure {
			&.text-end {
				blockquote {
					border-right-color: $dark-card-border;
				}
			}
		}

		.contacts-tabs {
			.nav-pills {
				border-right: 1px solid $dark-card-border;

				.nav-link {
					+.nav-link {
						border-top: 1px solid $dark-card-border;
					}
				}
			}
		}

		.product-wrapper {
			&.sidebaron {
				.product-sidebar {
					.filter-section {
						.card {
							.left-filter {
								background-color: $dark-card-background;
							}
						}
					}
				}
			}
		}

		.apexcharts-gridline {
			stroke: $dark-border-color;
		}

		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}

		.apexcharts-toolbar {
			.apexcharts-menu-open {
				background: $dark-card-inbox;
				border-color: $dark-card-inbox;

				.apexcharts-menu-item {
					opacity: 0.9;
					color: $dark-all-font-color;

					&:hover {
						background: $dark-body-background;
					}
				}
			}
		}

		// peity chart css
		.small-donut,
		.donut-peity2,
		.pie-peity {

			~svg,
			svg {
				path {
					&:nth-child(even) {
						fill: $dark-body-background;
					}
				}
			}
		}

		// index page css start		
		.appointment-table {
			table {
				tr {
					td {
						&.img-content-box {
							.font-roboto {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.notification {
			.card {
				.d-flex {
					.w-100 {
						p {
							span {
								color: $dark-all-font-color;
							}
						}

						span {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.datepicker {
			background-color: $dark-card-inbox;
			color: $dark-all-font-color;

			.datepicker--cell.-current- {
				color: $white;
			}

			.datepicker--cell.-current-.-focus- {
				color: $white;
			}

			.datepicker--cell-day.-other-month-,
			.datepicker--cell-year.-other-decade- {
				color: rgba($white, 0.4);
			}

			.datepicker--nav {
				border-bottom: 1px solid $dark-card-border;
			}

			.datepicker--pointer {
				background: $dark-card-inbox;
				border-top-color: $dark-card-inbox;
				border-right-color: $dark-card-inbox;
			}
		}

		// index page css end

		// ecommerce dashboard css start
		[class*='activity-dot-'] {
			background-color: $dark-card-background;
		}

		// ecommerce dashboard css end

		.sm-progress-bar {
			background-color: $dark-body-background;
		}

		$alert-name: primary,
			secondary,
			success,
			danger,
			warning,
			info,
			light,
			dark;
		$alert-color : $primary-color,
		$secondary-color,
		$success-color,
		$danger-color,
		$warning-color,
		$info-color,
		$light-color,
		$dark-color;

		@each $var in $alert-name {
			$i: index($alert-name, $var);

			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;

				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}

				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}

			.alert-#{$var}.inverse {
				background-color: $transparent-color !important;

				.btn-close {
					filter: brightness(1) invert(1);
				}
			}

			.alert-#{$var}.outline,
			.alert-#{$var}.outline-2x {
				.btn-close {
					filter: brightness(1) invert(1);
				}
			}

			.dismiss-text {
				.alert-dismissible {
					.btn-close {
						filter: unset;

						.bg-light {
							color: $theme-body-font-color !important;
						}
					}
				}
			}
		}

		// Alert

		.alert-addition-content {
			.alert {
				&[class*="bg-light-"] {
					p {
						color: rgba($white, 0.6);
					}
				}
			}
		}

		.options {
			>div {
				border: 1px solid $dark-card-border;
			}
		}

		.was-validated {
			.custom-control-input {
				&:invalid {
					~.custom-control-label {
						&::before {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		.pricing-simple {
			box-shadow: 1px 1px 2px 1px $dark-body-background !important;
		}

		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}

		.search-page {
			.nav {
				&.nav-tabs {
					border-bottom: unset;
				}
			}

			ul {
				&.search-info {
					li {
						color: $dark-small-font-color;

						+li {
							border-left: 1px solid $light-all-font-color;
						}
					}
				}
			}

			.info-block {
				border-color: $dark-card-border;

				a {
					color: $dark-all-font-color;
				}
			}

			p:not(.blog-details p) {
				color: rgba($dark-all-font-color, 0.4);
			}

			.audio-card {
				.audio-content {
					span {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}

			.audio-wrapper {
				table {
					&.audio-table {
						&.table-hover {

							thead,
							tbody {
								tr {
									&:hover {
										background-color: $dark-datatable-sorting-even;
									}
								}
							}
						}
					}
				}
			}
		}

		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid $dark-card-border;
				}
			}
		}

		.job-search {
			.job-description {
				ul:not(.job-apply-wrapper .job-search .job-description ul) {
					li {
						color: rgba($dark-all-font-color, 0.4);
					}
				}

				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid $dark-body-background;
								}
							}
						}
					}
				}

				ul {
					li {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}
		}

		.calendar-wrap {
			.fc-unthemed {

				td,
				th {
					border-color: $dark-body-background;
				}
			}
		}

		.mega-inline,
		.mega-horizontal,
		.mega-vertical {
			--bs-body-color: rgba(255, 255, 255, 0.6);

			.card {
				box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
				border-color: $dark-card-border;
			}

			&.plain-style,
			&.border-style,
			&.offer-style {
				.card {
					box-shadow: none !important;
				}
			}

			&.border-style {
				.card {
					border: 1px solid $dark-card-border !important;
				}
			}

			&.offer-style {
				.card {
					border: 1px dashed $dark-card-border !important;
				}
			}
		}

		.setting-dot {
			.setting-bg {
				background-color: var(--theme-secondary);
			}
		}

		.bookmark.pull-right {
			border: none;
		}

		// popover css start
		.popover {
			background-color: $dark-body-background;
			--bs-popover-header-bg: unset;

			&.bs-popover-bottom {
				.popover-arrow {
					&:after {
						border-bottom-color: $dark-body-background;
						border-right-color: $transparent-color;
					}
				}
			}

			&.bs-popover-top {
				.popover-arrow {
					&:after {
						border-right-color: $transparent-color;
						border-top-color: $dark-body-background;
					}
				}
			}

			&.bs-popover-start {
				.popover-arrow {
					&:after {
						border-left-color: $dark-body-background;
					}
				}
			}

			&[data-popper-placement="left"] {
				.popover-arrow {
					&:after {
						border-left-color: $dark-body-background;
					}
				}
			}

			&[data-popper-placement="right"] {
				.popover-arrow {
					&:after {
						border-right-color: $dark-body-background;
					}
				}
			}

			&[data-popper-placement="top"] {
				.popover-arrow {
					&:after {
						border-top-color: $dark-body-background;
					}
				}
			}

			&[data-popper-placement="bottom"] {
				.popover-arrow {
					&:after {
						border-bottom-color: $dark-body-background;
					}
				}
			}

			.popover-header {
				background-color: $dark-card-background;
			}

			.popover-body {
				color: rgba(255, 255, 255, 0.6);
			}

			code {
				background-color: unset;
			}
		}

		// popover css end
		// apex chart css start
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}
			}
		}

		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}

				.apexcharts-subtitle-text {
					fill: $white;
				}

				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}

				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}

				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}

				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white !important;
					}

					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}

		// apex chart css end
		.Typeahead-menu {
			background-color: $dark-body-background;
		}

		.ecommerce-widget {
			border: 1px solid $dark-border-color;
		}

		.bookmark {
			ul {
				li {
					.search-form {
						.form-control-search {
							input {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}

							&:before {
								background: $dark-border-color;
							}
						}
					}
				}
			}
		}

		// cart css start
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: $dark-card-border !important;
					}
				}
			}
		}

		// cart css end

		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid $dark-card-background;
				}
			}
		}

		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid $dark-border-color;
				}
			}
		}

		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}

		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: $dark-small-font-color;
							}

							&:hover {
								.btn {
									color: $white;
								}
							}
						}

						li:not(.product-wrapper-grid .product-box .product-hover li) {
							background-color: $dark-body-background;
						}

						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.radio_animated,
		.checkbox_animated {
			&:after {
				background: $dark-body-background;
				border: 2px solid $dark-body-background;
			}
		}

		.slider-product {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
		}

		.square-product-setting {
			.icon-grid {
				background-color: $dark-card-background;

				svg {
					color: $dark-all-font-color;
				}
			}
		}

		.active-order-table,
		.market-table {
			table {
				thead {
					tr {
						th {
							border-top: none !important;
						}
					}
				}

				tbody {
					tr {
						td {
							border-bottom: 1px solid $dark-border-color;
						}

						&:last-child {
							td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}

		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
			}
		}

		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: $dark-card-background;
						color: $white;

						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}

		.google-visualization-tooltip {
			text {
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}

		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}

		.loader-wrapper {
			background-color: $dark-body-background;

			.loader {
				background-color: $dark-body-background !important;
			}
		}

		.page-wrapper {

			.sidebar-main-title {
				h6 {
					color: $dark-all-font-color;
				}
			}

			/* Main Header start */
			.page-header {
				.translate_wrapper {
					&.active {
						.more_lang {
							background-color: $dark-body-background;
						}

						.lang {
							background-color: $dark-card-background;
						}
					}
				}

				form {
					background-color: $dark-body-background;
				}

				.header-wrapper {
					background-color: $dark-card-background;

					li {
						i {
							color: $light-color;
						}
					}

					.nav-right {
						>ul {
							>li {
								svg {
									stroke: $white;
								}

								.mode {
									svg {
										fill: $white;
									}
								}
							}
						}

						&.right-header {
							>ul {
								>li {
									.profile-media {
										.flex-grow-1 {
											p {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}

						.nav-menus {
							li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}

								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 14px $dark-body-background;

									li {
										border-color: $dark-card-background;
									}

									p {
										color: $dark-all-font-color;
									}

									&:before {
										border-bottom: 7px solid $dark-body-background;
									}

									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}

								.notification-dropdown.onhover-show-div {
									li {
										background-color: $dark-card-background;

										span {
											color: $dark-small-font-color;
										}
									}

									.bg-light {
										background-color: #282e38 !important;
										color: $dark-all-font-color !important;
									}
								}
							}

						}

						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;

									svg {

										path,
										line,
										circle {
											color: $dark-all-font-color !important;
										}
									}

									&:hover {
										color: var(--theme-default);

										svg {

											line,
											path,
											circle {
												color: var(--theme-default) !important;
											}
										}
									}
								}
							}
						}

						>ul {
							>li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}

								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;

									&:before {
										border-bottom: 7px solid $dark-body-background;
									}

									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
							}
						}

						.bookmark-flip {
							.bookmark-dropdown {
								.bookmark-content {
									.bookmark-icon {
										background-color: $dark-card-background;
									}
								}
							}
						}

					}
				}

				.header-logo-wrapper {
					background-color: $dark-card-background;

					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}

							.image-light {
								display: block;
							}
						}
					}
				}
			}

			/* Main Header ends */

			.page-body-wrapper {

				/* body start*/
				.page-body {
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										p {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
					}

					.header-small {
						color: $dark-small-font-color;
					}

					.statistics {
						p {
							color: $dark-small-font-color;
						}
					}

					.feather-main,
					.professor-table {

						.flex-grow-1,
						.professor-block {
							p {
								color: $dark-small-font-color;
							}
						}
					}

					.logs-element {
						span {
							+span {
								color: $dark-small-font-color
							}
						}
					}

					.progress-block {
						.progress-title {
							span {
								+span {
									color: $dark-small-font-color;
								}
							}
						}
					}

					.ecommerce-icons {
						div {
							span {
								color: $dark-small-font-color;
							}
						}
					}

					.new-users,
					.progress-media {
						.d-flex {
							.flex-grow-1 {
								span {
									color: $dark-small-font-color;
								}
							}
						}

						.progress-change {
							span {
								color: $dark-small-font-color;
							}
						}
					}

					.topper-lists {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
										border-bottom: 1px solid $dark-card-border;
									}

									&:last-child {
										td {
											border-bottom: none;
										}
									}
								}
							}
						}
					}

					.notifiaction-media {
						.d-flex {
							.flex-grow-1 {
								.circle-left {
									border: 5px solid #334154;
								}
							}
						}
					}

					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid $dark-border-color;

							&:last-child {
								border-bottom: none;
							}
						}
					}

					.professor-table {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}

					.number-widgets {
						.d-flex {
							.flex-grow-1 {
								h6 {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.activity {
						.d-flex {
							.flex-grow-1 {
								h6 {
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
					}

					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
				}

				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}

				.page-title {
					.row {
						h3 {
							color: $dark-all-font-color;

							small {
								color: $dark-small-font-color;
							}
						}
					}


					.breadcrumb {
						li {
							color: $dark-all-font-color;
						}

						.breadcrumb-item {
							a {
								svg {
									stroke: $dark-all-font-color;
								}
							}

							&+.breadcrumb-item {
								&:before {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}

				.page-body {
					background-color: $dark-body-background;

					.default-according {
						.card:not(.email-body) {
							border: none;
						}
					}

					.card:not(.email-body) {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);

						&.widget-1 {
							background-blend-mode: color-burn;

							.widget-round {
								.bg-round {
									box-shadow: 1px 2px 21px -2px rgba(14, 14, 16, 0.83);

									.half-circle {
										background: rgba(49, 53, 66, 0);
									}
								}
							}
						}

						&.social-profile {
							background-blend-mode: overlay;
							background-color: $dark-body-background;
						}

						.table {
							&.table-light {
								tr {

									th,
									td {
										color: $theme-body-font-color;
									}
								}
							}
						}

						table {

							// thead,
							// tbody {
							// 	.border-bottom-light {

							// 		th,
							// 		td {
							// 			border-bottom: 1px solid $dark-card-border !important;
							// 		}
							// 	}
							// }

							thead {
								.border-bottom-light {
									th {
										&:not(.border-bottom-table thead .border-bottom-light th) {
											border-bottom: 1px solid $dark-card-border !important;
										}
									}
								}
							}

							tbody {
								.border-bottom-light {
									td {
										&:not(.border-bottom-table tbody .border-bottom-light td) {
											border-bottom: 1px solid $dark-card-border !important;
										}
									}
								}
							}

						}

						&.trending-card {
							background-color: #010314;
						}

						&.explore-card {
							background-color: $dark-card-background;
							background-blend-mode: overlay;
						}

						// cypto dashboard css start
						&.balance-box {
							background-blend-mode: overlay;
							background-color: #16171c;
							border-color: transparent;
						}

						// cypto dashboard css end

						.chart-block {
							#bar-chart2 {
								svg {
									>rect {
										fill: $dark-card-background;
									}

									>g {
										text {
											fill: $dark-all-font-color;
										}
									}
								}
							}

							.word-tree {
								svg {
									>g {
										>rect {
											fill: $dark-card-background;
										}

										>text {
											fill: $dark-all-font-color;
										}
									}
								}
							}
						}

						.card-header {
							background-color: $transparent-color;
							border-bottom: 1px solid $dark-card-border;

							>span {
								color: $dark-all-font-color;
							}

							p {
								color: rgba($dark-all-font-color, 0.4);
							}

							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: $dark-all-font-color;
							}

							.card-header-right {
								background-color: $dark-card-background;

								i {
									color: $dark-all-font-color;

									&.fa-cog {
										color: var(--theme-default);
									}
								}
							}

							&.note-toolbar {
								background-color: $dark-card-background;
							}
						}

						// Basic card

						.alert-dark {
							color: $dark-small-font-color;

							a {
								color: $dark-small-font-color;
							}
						}

						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: $dark-all-font-color;
						}

						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}

						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-card-border;
							}
						}

						.line {
							color: $dark-all-font-color;
						}

						thead,
						tbody,
						tfoot,
						tr,
						td,
						th {
							border-color: $dark-card-border;
						}

						.table {

							th,
							td {
								color: $dark-all-font-color;
							}

							thead {
								th {
									border-bottom-color: $dark-card-border;
								}

								.border-bottom-primary {
									th {
										border-bottom-color: var(--theme-default);
									}
								}
							}

							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {

								th,
								td {
									color: $dark-card-background;
								}
							}

							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}

							.bg-light {
								color: $black;
							}

							.thead-light {
								th {
									color: $black;
								}
							}

							tbody {
								.border-bottom-primary {

									th,
									td {
										border-bottom: 1px solid var(--theme-default);
									}
								}
							}
						}

						.table[class*='bg-'] {

							th,
							td {
								color: $white;
							}
						}

						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										--bs-table-accent-bg: rgba(0, 0, 0, 0.05);

										&:hover {

											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}

						.table-double,
						.table-dotted,
						.table-dashed {
							border-left-color: $dark-card-border;
							border-right-color: $dark-card-border;
						}

						.table-bordered {
							border-color: $dark-card-border !important;

							td,
							th {
								border-color: $dark-card-border !important;
							}
						}

						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}

						.emphasis-table {
							--bs-emphasis-color-rgb: #1c212b;

							.table-striped-columns>:not(caption)>tr>:nth-child(2n) {
								--bs-table-striped-bg: var(--bs-emphasis-color-rgb);
							}

							.table-active {
								--bs-table-active-bg: var(--bs-emphasis-color-rgb);
							}
						}

						.table-border-vertical {

							tr,
							th,
							td {
								border-right: 1px solid $dark-border-color;
							}
						}

						.table-styling {

							thead,
							tbody {

								th,
								td {
									color: $white;
								}
							}
						}

						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
						}

						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}

							input {
								&:checked {
									+.switch-state {
										background-color: var(--theme-default);
									}
								}
							}
						}

						.bg-white {
							background-color: $dark-card-background !important;
							color: rgba($white , 0.6) !important;
						}

						.ct-grid {
							stroke: $dark-border-color;
						}

						.ct-label {
							color: $dark-small-font-color;
						}

						hr {
							border-top: 1px solid $dark-all-font-color;

							&.dropdown-divider {
								border-color: $dark-border-color;
							}
						}

						.text-muted {
							color: $dark-all-font-color !important;
						}

						.btn-outline-light,
						.btn-outline-dark,
						.btn-outline-light-2x {
							color: $white !important;
							border: 1px solid $dark-card-border;
						}

						.btn-outline-light,
						.btn-outline-light-2x {

							&:hover,
							&:active {
								color: $black !important;
							}
						}

						.border-right {
							border-right: 1px solid $dark-card-border !important;
						}

						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}

							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}

						.dark-alert {
							&.alert-addition-content {
								.alert {
									hr {
										border-top: 1px solid $dark-card-border;
									}
								}
							}
						}

						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: $dark-body-background;
											}
										}
									}
								}

								rect {
									&:nth-child(6) {
										fill: $dark-body-background;
									}
								}
							}

							&#line-chart,
							&#column-chart1,
							&#column-chart2 {
								rect {
									fill: $dark-card-background;
								}
							}

							svg {
								>rect {
									fill: $dark-card-background;
								}

								>g {
									>g {
										>g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}

									>text {
										fill: $dark-all-font-color;
									}
								}
							}
						}

						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}

						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;

									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}

						.chart-container {

							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}

								.ct-grid {
									stroke: $white;
								}
							}

							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}

						.status-details {
							h4 {
								color: $dark-all-font-color;

								span {
									color: $dark-all-font-color;
								}
							}

							span {
								color: $dark-small-font-color;
							}
						}

						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}

						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-card-border;
						}

						.dataTables_wrapper {
							button {
								color: $black;
							}

							&.no-footer {
								.dataTables_scrollBody {
									border-bottom: 1px solid $dark-body-background;
								}
							}

							.btn-danger,
							.btn-success,
							.btn-primary {
								color: $white;
							}

							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}

							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}

							.dataTables_paginate {
								border: 1px solid $dark-border-color;
							}

							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}

							table.dataTable.display,
							table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;

										&:hover {
											>.sorting_1 {
												background-color: $dark-datatable-sorting;
											}
										}
									}

									tr.odd {
										background-color: $dark-datatable-odd;

										>.sorting_1 {
											background-color: $dark-datatable-sorting;
										}
									}

									tr.even {
										>.sorting_1 {
											background-color: $dark-datatable-sorting-even;
										}
									}
								}
							}

							table.dataTable {
								border: 1px solid $dark-border-color;

								thead {

									th,
									td {
										border-bottom: 2px solid $dark-border-color;
									}
								}

								input,
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}

								tbody {
									tr {
										background-color: $dark-card-background;
									}

									td.select-checkbox,
									th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}

							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											--bs-table-accent-bg: #1d1e26;
										}
									}
								}
							}

							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;

									&.current,
									&:active {
										border-color: var(--theme-default);
									}
								}

								.paginate_button.disabled {
									color: $dark-small-font-color !important;

									&:hover,
									:active {
										color: $dark-small-font-color !important;
									}
								}
							}

							table.dataTable.row-border,
							table.dataTable.display {
								tbody {

									th,
									td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}

							table.dataTable.display,
							table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											>.sorting_1 {
												background-color: $dark-even-hover-sorting;
											}
										}
									}
								}
							}

							table.dataTable.hover,
							table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: $dark-datatable-sorting;
										}
									}
								}
							}

							table.dataTable.cell-border {

								th,
								td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;

									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}

							table.dataTable.order-column,
							table.dataTable.display {
								tbody {
									tr {

										>.sorting_1,
										>.sorting_2,
										>.sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}

						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}

						.page-link {
							border: 1px solid $dark-card-border;
						}

						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}

						.page-link {
							color: $dark-all-font-color;
							background-color: $dark-card-background;
						}

						.page-item {
							&:hover {
								.page-link {
									background-color: $dark-body-background;
								}
							}
						}

						.page-item.active {
							.page-link {
								background-color: $dark-body-background;
							}
						}

						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}

							.total-num {
								color: $dark-all-font-color;

								span {
									color: $dark-all-font-color;
								}
							}

							span {
								color: $dark-small-font-color;
							}
						}

						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}

						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {

											span,
											p {
												color: $dark-small-font-color;
											}
										}
									}
								}
							}

							h6 {
								color: $dark-small-font-color;
							}
						}

						.border-tab.nav-tabs {
							border-bottom: 1px solid $dark-border-color;

							.nav-item {
								.nav-link {
									color: $dark-all-font-color;
								}
							}

							.nav-link {

								&.active,
								&:focus,
								&:hover {
									color: var(--theme-default);
								}
							}
						}

						.project-tabs {
							.nav-tabs {
								border-bottom: unset;
							}
						}

						.br-theme-bars-1to10,
						.br-theme-bars-movie,
						.br-theme-bars-pill,
						.br-theme-bars-reversed,
						.br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: $dark-border-color;

									&.br-active,
									&.br-selected {
										background-color: var(--theme-default);
									}
								}
							}
						}

						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background;
									color: $dark-all-font-color;

									&.br-active,
									&.br-selected {
										border: 2px solid var(--theme-default);
										color: var(--theme-default);
									}
								}
							}
						}

						.br-theme-fontawesome-stars,
						.br-theme-fontawesome-stars-o {
							.br-widget {
								a {

									&.br-selected,
									&.br-active {
										&:after {
											color: var(--theme-default);
										}
									}
								}
							}
						}

						.scroll-demo {
							border: 1px solid $dark-card-border;
						}

						// Scrollable
						.vertical-scroll {
							&.scroll-demo {
								&:not(.default-scroll) {
									border: unset;
								}

								.list-group {
									.list-group-item {
										&:hover {
											z-index: 0;
										}
									}
								}
							}
						}

						.search-form {
							input {
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								background-color: $dark-body-background;
							}

							.form-group {
								&:before {
									background: $dark-card-background;
								}
							}
						}

						.breadcrumb {
							&.bg-white {
								background-color: $dark-card-background !important;
							}
						}

						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}

					.collapse-horizontal {
						.card {
							background-color: $dark-body-background;
						}
					}

					.collapse-accordion {
						.btn-dark {
							&.show {
								.dark-accordion {
									background-color: $dark-body-background;
								}
							}
						}
					}

					//social-app
					.social-app-profile1 {
						.social-app-profile {
							.common-user-image {
								background-color: $dark-body-background;
							}
						}
					}

					// bookmark
					.modal-bookmark {
						.modal-content {
							.modal-body {
								.search-picker {
									.dropdown-menu {
										.dropdown-item {
											border-color: $dark-card-border;
										}
									}
								}
							}
						}
					}

					.main-bookmark {
						.email-right-aside {
							.card-body {
								.details-bookmark {
									.bookmark-card {
										background-color: $dark-body-background;
									}
								}
							}
						}
					}

					.email-wrap {
						.flex-grow-1 {
							h6 {
								color: $dark-all-font-color;
							}
						}
					}

					.bookmark-wrap {
						.main-bookmark {
							.bootstrap-select {
								.dropdown-toggle {
									border-color: $dark-card-border !important;
								}

								.dropdown-menu {
									li {
										background-color: $dark-body-background;

										&:hover {
											background-color: $dark-card-inbox;

											.dropdown-item {
												color: $dark-all-font-color !important;
											}
										}
									}
								}
							}
						}
					}

					.contact-editform {
						.more-data {
							.bootstrap-select {
								.dropdown-toggle {
									background-color: $dark-body-background !important;
									border-color: $transparent-color !important;

									.filter-option {
										color: $light-all-font-color;
									}
								}
							}
						}
					}

					// user-cards
					.social-user-cards {
						.card {
							&.social-profile {
								background-blend-mode: overlay;

								.social-details {
									.social-follow {
										li {
											+li {
												&::before {
													background-color: $light-all-font-color;
												}
											}
										}
									}
								}
							}
						}
					}

					.user-profile {
						.list-inline {
							.border-right {
								border-right-color: $dark-card-border !important;
							}
						}

						.common-user-image {
							.user-image {
								.avatar {
									.common-align {
										>div {
											.icon-wrapper {
												&#cancelButton {
													i {
														color: $dark-all-font-color;
													}
												}
											}
										}
									}

									.follow {
										>div {
											+div {
												border-left-color: $dark-card-border;
											}
										}
									}
								}
							}
						}

						.social-media {
							a {
								color: rgba($white, 0.8);
							}
						}
					}

					.user-profile-wrapper {
						&.scope-bottom-wrapper {
							.sidebar-left-icons {
								.nav-item {
									.nav-link {
										&.active {
											.product-icons {
												i {
													color: rgba($white, 0.8);
												}
											}
										}

										.product-icons {
											i {
												color: rgba($dark-all-font-color, 0.4);
											}
										}
									}
								}
							}

							.user-notifications {
								ul {
									li {
										>div {
											p {
												span {
													color: rgba($dark-all-font-color, 0.3);
												}
											}

											span {
												color: rgba($dark-all-font-color, 0.4);
											}
										}
									}
								}
							}

							.setting-wrapper {
								.form-label {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.product-sidebar {
						.filter-section {
							.card {
								border: unset;

								.card-header {
									background-color: $dark-card-background;
									border-bottom: unset;
								}
							}
						}
					}

					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}

					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}

					.gallery-with-description {
						h4 {
							color: rgba($white, 0.8);
						}

						a {
							>div {
								border: 1px solid $dark-card-border;
							}
						}

						p {
							color: $dark-all-font-color;
						}
					}

					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}

					.jsgrid-header-row,
					.jsgrid-filter-row,
					.jsgrid-insert-row {

						>.jsgrid-header-cell,
						>.jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;

							input:not(.jsgrid-button) {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;

								&:focus {
									outline: none;
								}
							}
						}

						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}

					.jsgrid-insert-row {
						>.jsgrid-cell {
							input:not(.jsgrid-button) {
								border: 1px solid $dark-border-color;
							}

							.jsgrid-button {
								background-color: unset;
							}
						}
					}

					.jsgrid-row {
						>.jsgrid-cell {
							background-color: $dark-card-background;
						}
					}

					.jsgrid-alt-row {
						>.jsgrid-cell {
							background-color: $dark-body-background;
						}
					}

					.jsgrid-cell,
					.jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}

					.jsgrid-selected-row {
						>.jsgrid-cell {
							background-color: $dark-datatable-sorting;
						}
					}

					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}

							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}

					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}

					.card {
						.sub-title {
							color: $dark-all-font-color;
						}
					}

					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;

								&:hover {
									color: var(--theme-default);
								}
							}
						}

						.separator {
							border-bottom: 1px solid $dark-card-border;
						}
					}

					.default-according {
						li {
							.text-muted {
								color: $dark-all-font-color !important;
							}
						}
					}

					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: var(--theme-default) !important;
								}
							}
						}
					}

					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: $dark-all-font-color;
								}

								&:hover {
									color: var(--theme-default);

									a {
										color: var(--theme-default);
									}
								}
							}
						}
					}

					.navs-dropdown {
						.onhover-show-div {
							background-color: $dark-body-background;
							box-shadow: 0 0 14px 0 $dark-body-background;

							.navs-icon {
								li {
									p {
										color: $white;
									}

									a {
										svg {

											path,
											line,
											polyline,
											polygon,
											rect {
												color: $dark-all-font-color !important;
											}
										}

										&:hover {
											svg {

												path,
												line,
												polyline,
												polygon,
												rect {
													color: var(--theme-default) !important;
												}
											}
										}
									}
								}
							}
						}
					}

					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid $dark-body-background;
								}
							}
						}
					}

					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}

							.card-body {
								color: $dark-small-font-color;
							}

							.card-body {
								border-color: $dark-card-border;
							}
						}
					}

					.border {
						border: 1px solid $dark-card-border !important;
					}

					.border-1 {
						border: 1px solid $dark-body-background !important;
					}

					.border-2 {
						border: 2px solid $dark-body-background !important;
					}

					.grid-showcase {
						span {
							&.border-2 {
								border: 2px solid $dark-card-border !important;
							}
						}
					}

					.border-3 {
						border-color: $dark-body-background !important;
						border-width: 3px !important;
					}

					.border-4 {
						border: 4px solid $dark-body-background !important;
					}

					.border-5 {
						border: 5px solid $dark-body-background !important;
					}

					.border-6 {
						border: 6px solid $dark-body-background !important;
					}

					.border-7 {
						border: 7px solid $dark-body-background !important;
					}

					.border-8 {
						border: 8px solid $dark-body-background !important;
					}

					.border-9 {
						border: 9px solid $dark-body-background !important;
					}

					.border-10 {
						border: 10px solid $dark-body-background !important;
					}

					// Creative card 
					.effective-card {
						.common-hover {
							.card-header {
								&.border-3 {
									border-bottom-width: 1px !important;
								}
							}
						}
					}

					.subtract-border {
						&.border-wrapper {
							.helper-common-box {
								.helper-box {
									&.border-0 {
										border: 0 !important;
									}

									&.border-top-0 {
										border-top: 0 !important;
									}

									&.border-end-0 {
										border-right: 0 !important;
									}

									&.border-bottom-0 {
										border-bottom: 0 !important;
									}

									&.border-start-0 {
										border-left: 0 !important;
									}
								}
							}
						}
					}

					// Bootstrap table 
					.basic_table {
						.table {

							thead,
							tbody {
								tr {
									&[class*="table-"] {

										th,
										td {
											color: $dark-all-font-color !important;
										}
									}

								}
							}
						}
					}

					$border-gradient: primary,
						secondary,
						success,
						danger,
						warning,
						info,
						light,
						dark;

					$helper-border-color : $primary-color,
					$secondary-color,
					$success-color,
					$danger-color,
					$warning-color,
					$info-color,
					$light-color,
					$dark-color;

					@each $var in $border-gradient {
						$i: index($border-gradient, $var);

						.b-#{$var} {
							border-color: nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}

						.border-t-#{$var} {
							border-top: 1px solid nth($helper-border-color, $i) !important;
						}

						.b-t-#{$var} {
							border-top-color: nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}

						.border-b-#{$var} {
							border-bottom: 1px solid nth($helper-border-color, $i) !important;
						}

						.b-b-#{$var} {
							border-bottom-color: nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}

						.border-l-#{$var} {
							border-left-color: nth($helper-border-color, $i) !important;

							[dir="rtl"] & {
								border-left-color: unset !important;
								border-right-color: nth($helper-border-color, $i) !important;
							}
						}

						.b-l-#{$var} {
							border-left-color: nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}

						.border-r-#{$var} {
							border-right-color: nth($helper-border-color, $i) !important;
						}

						.b-r-#{$var} {
							border-right-color: nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}

						.border-#{$var} {
							border-color: nth($helper-border-color, $i) !important;
						}

						.gradient-border {
							.helper-common-box {
								.helper-box {

									&.b-t-dark {
										border-top-color: $bg-dark-color !important;
									}

									&.b-b-dark {
										border-bottom-color: $bg-dark-color !important;
									}

									&.b-l-dark {
										border-left-color: $bg-dark-color !important;
									}

									&.b-r-dark {
										border-right-color: $bg-dark-color !important;
									}
								}
							}
						}

						.schedule-calender {
							.tab-content {
								.activity-update {
									>li {
										>div {
											background-color: $transparent-color !important;
											border-left-width: 4px !important;

											[dir="rtl"] & {
												border-left: unset !important;
											}
										}
									}
								}
							}

							.card-header-right-icon {
								.btn {
									border-color: $dark-card-border;
								}
							}
						}
					}

					// Navigate Links
					.border-opacity-wrapper {
						.border {
							border-color: rgba(var(--theme-default), var(--bs-border-opacity)) !important;
						}
					}

					.bg-opacity-wrapper {
						.text-dark {
							color: $dark-all-font-color !important;
						}
					}

					// Sitemap 
					.sitemap-wrapper {
						.default-sitemap {
							.common-flex {
								a {
									color: rgba($dark-all-font-color, 0.4);
								}
							}

							.sub-title {
								border-bottom: thick solid $dark-card-inbox;
							}
						}

						.horizontal-sitemap {
							.main-site {
								li {
									>a {
										background-color: rgba($dark-body-background, 0.29);
										color: $dark-all-font-color;
									}

									.main-root {
										background-color: $dark-body-background;

										&::after {
											border-color: $dark-card-border;
										}
									}
								}
							}

							.tier-1 {
								border-color: $dark-card-border;

								li {
									>a:not(.tier-2 li a) {
										&:first-child {
											background-color: rgba($dark-body-background, 0.6);

											&::before {
												color: $dark-card-border;
											}
										}
									}
								}

								&::before {
									background-color: $dark-body-background;
								}

								.tier-2 {
									border-color: $dark-card-border;

									li {
										>a {
											&::before {
												color: $dark-card-border;
											}
										}

										&:last-child {
											&::after {
												background-color: $dark-body-background;
											}
										}
									}

									.tier-3 {
										border-color: $dark-card-border;
									}
								}
							}
						}
					}

					.animate-wrapper {
						.card {
							box-shadow: 1px 1px 2px 1px rgba($dark-body-background, 0.9);
						}
					}
				}

				.footer {
					background-color: $dark-card-background;

					p,
					span {
						color: $dark-all-font-color;
					}
				}

				.custom-select,
				.custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}

				.footer-fix {
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}

			.note {
				textarea {
					color: $dark-all-font-color;
				}
			}

			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;

				h2 {
					background-color: $dark-card-background;
				}
			}

			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}

			.scorlled {
				background-color: $dark-body-background;
			}

			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}

			.input-group-text {
				background-color: $dark-card-background;
				border: 1px solid $dark-card-border;
				color: rgba($white, 0.6);

				i {
					color: rgba($white, 0.6);
				}
			}

			.input-group-solid {

				.input-group-text,
				.form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}

			.semilight-bg-color,
			.header-bg-color {
				background-color: $white;
			}

			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba($white, 0.3);
				color: $dark-all-font-color;

				+.list-group-item {
					border-top-width: 0;
				}

				&.active {
					background-color: var(--theme-default) !important;
					border-color: var(--theme-default);
					color: $white;
				}

				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}

			.list-group-flush {
				.list-group-item {
					&:first-child {
						border-top-color: $transparent-color;
					}

					&:last-child {
						border-bottom-color: $transparent-color;
					}
				}
			}

			.list-group-item-action {

				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}

			.contextual-wrapper {
				.list-group-item-action {
					&:hover {
						color: rgba($white , 0.6);
					}
				}
			}

			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}

			.dark-list {
				.list-group {
					.border-left-primary {
						border-left: 4px solid var(--theme-default);
					}

					.border-left-secondary {
						border-left: 4px solid var(--theme-secondary);
					}

					.border-left-warning {
						border-left: 4px solid $warning-color;
					}

					.border-left-success {
						border-left: 4px solid $success-color;
					}

					.border-left-info {
						border-left: 4px solid $info-color;
					}

					[dir="rtl"] & {
						[class*="border-left-"] {
							border-left: none;
						}
					}
				}
			}

			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}

			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}

			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}

			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}

			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}

			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}

			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}

			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}

			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);

				.authentication-box {
					.card {
						background-color: $dark-card-background;

						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
								}

								label {
									color: $dark-all-font-color;
								}
							}

							.checkbox {
								label {
									color: $dark-all-font-color;

									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}

					h3,
					h4,
					h6 {
						color: $white;
					}
				}
			}

			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);

				.authentication-box {

					h4,
					h3 {
						color: $white;
					}

					h6 {
						color: $dark-small-font-color;
					}

					.card {
						background-color: $dark-card-background;

						.theme-form {
							.form-group {

								input[type=text],
								input[type=password] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}

							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
				}
			}

			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}

						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}

			.modal-content {
				background-color: $dark-card-background;

				.modal-header {
					border-bottom: 1px solid $dark-card-border;

					.close {
						color: $dark-small-font-color;
					}
				}

				.modal-footer {
					border-top: 1px solid $dark-card-border;
				}
			}

			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);

				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}

					.countdown {
						border-color: $dark-card-border;

						.title {
							color: $dark-all-font-color;
						}
					}

					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
				}
			}

			.theme-form {
				.login-divider {
					border-top: 1px solid $dark-border-color;

					&:before {
						background: $dark-border-color;
						color: $dark-all-font-color;
					}
				}
			}

			.authentication-main {
				background-color: $dark-border-color;

				.auth-innerright {
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}

						.reset-password-link {
							color: $dark-small-font-color;
						}

						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}

								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-border-color;
								}
							}

							.opt-box {
								background-color: $dark-border-color;
							}
						}

					}

					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}

						h6 {
							color: $dark-small-font-color;
						}

						h3 {
							color: $dark-all-font-color;
						}

						.card {
							background-color: $dark-card-background;

							.theme-form {
								.form-group {

									input[type=text],
									input[type=password] {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
										color: $dark-all-font-color;
									}
								}

								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-border-color;
										}
									}
								}
							}
						}
					}
				}
			}

			.vertical-menu-main {
				background-color: $dark-card-background;
			}

			.mega-menu {
				.title {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}

				.list-unstyled {
					div {
						a {
							&:hover {
								color: var(--theme-default);
							}
						}
					}
				}
			}

			.default-according {
				.card {
					background-color: $dark-card-background;

					.btn-link {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-border;
						border-bottom: none;
						color: $white;
					}

					.text-muted {
						color: $dark-small-font-color !important;
					}
				}

				.bg-primary {
					.btn-link {
						background-color: var(--theme-default);
						border: 1px solid var(--theme-default);
					}
				}

				.bg-secondary {
					.btn-link {
						background-color: var(--theme-secondary);
						border: 1px solid var(--theme-secondary);
					}
				}
			}

			.collapse {
				.card-body {
					background-color: $dark-card-background;
				}
			}

			.balance-widget.card-body {
				background-color: $dark-card-background;
				background-blend-mode: overlay;
			}

			@media screen and (max-width: 1660px) {
				.caller-img {
					img {
						opacity: 0.7;
					}
				}

			}

			@media only screen and (max-width: 1199px) {
				.mobile-title {
					&.d-none {
						border-bottom: 1px solid $dark-card-border;
					}
				}

				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}

				.error-wrapper {
					.maintenance-icons {
						li {
							i {
								color: $dark-color;
							}
						}
					}
				}
			}

			@media only screen and (max-width: 575.98px) {
				&.compact-sidebar {
					.page-header {
						.header-wrapper {
							.toggle-sidebar {
								border-right: 1px solid $light-all-font-color;
							}
						}
					}
				}

				.page-wrapper {

					&.compact-wrapper,
					&.compact-sidebar {
						.page-header {
							.header-wrapper {
								.toggle-sidebar {
									border-right: 1px solid $dark-card-border;
								}
							}
						}
					}
				}
			}
		}

		.lg-backdrop {
			background-color: $dark-body-background;
		}

		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}

		.drag {
			background-color: $dark-card-background;

			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;

				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}

				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}

		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}

			p {
				color: $dark-small-font-color;
			}

			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}

			.radio,
			.checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}

		.select2-container--default {
			.select2-results__option[aria-selected=true] {
				background-color: $dark-card-inbox;
			}

			.select2-selection--multiple,
			.select2-selection--single {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color !important;
			}

			.select2-search--inline {
				.select2-search__field {
					color: $dark-all-font-color;
				}
			}

			.select2-selection--single {
				.select2-selection__rendered {
					color: $dark-all-font-color;
				}
			}

			.select2-search--dropdown {
				.select2-search__field {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}

			.select2-results__option--highlighted[aria-selected] {
				background-color: $dark-card-background !important;
				color: $dark-all-font-color !important;
			}
		}

		.select2-dropdown {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}

		.select2-container--default.select2-container--disabled {
			.select2-selection--single {
				background-color: $dark-border-color;
			}
		}

		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color;
				}

				input[type=text],
				input[type=email],
				input[type=search],
				input[type=password],
				input[type=number],
				input[type=tel],
				input[type=date],
				input[type=datetime-local],
				input[type=time],
				input[type=datetime-local],
				input[type=month],
				input[type=week],
				input[type=url],
				input[type=file],
				select {
					border-color: $dark-border-color;
					background-color: $dark-body-background;
					color: $dark-all-font-color;

					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}

				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}

				textarea {
					border-color: $dark-border-color;
				}
			}
		}

		.CodeMirror {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;

			.CodeMirror-code {
				pre {
					background: $dark-card-background;
				}
			}
		}

		.editor-toolbar {
			border-top: 1px solid $dark-small-font-color;
			border-left: 1px solid $dark-small-font-color;
			border-right: 1px solid $dark-small-font-color;

			a {
				color: $dark-all-font-color !important;

				&:hover,
				&.active {
					background: $dark-border-color;
				}
			}

			i.separator {
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}

			&.fullscreen {
				&::before {
					background: linear-gradient(to right, $dark-body-background 0, rgba(0, 0, 0, 0) 100%);
				}

				&::after {
					background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, $dark-body-background 100%);
				}
			}
		}

		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: $dark-border-color;
				}
			}
		}

		.editor-preview {
			background-color: $dark-card-background;
		}

		.editor-toolbar.fullscreen,
		.editor-preview-side {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}

		.u-step {
			background: $dark-border-color;

			&.active,
			&.current {
				background: var(--theme-default);
				color: $white;
			}
		}

		.u-step-title,
		.u-pearl-title {
			color: $dark-all-font-color;
		}

		.u-step-number {
			background-color: $dark-card-background;
		}

		.u-pearl {
			&:before {
				background-color: $dark-border-color;
			}
		}

		.u-pearl-number,
		.u-pearl-icon {
			background: $dark-body-background;
			border: 2px solid $dark-body-background;
		}

		.u-pearl.disabled {

			.u-pearl-icon,
			.u-pearl-number {
				background: $dark-border-color;
				border: 2px solid $dark-border-color;
			}

			&:after {
				background-color: #334053;
			}
		}

		.u-pearl.error {
			&:after {
				background-color: $dark-body-background;
			}
		}

		.note-editor.note-frame {
			border-color: $dark-border-color;

			.note-editing-area {
				.note-editable {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}

		.swal-modal {
			background-color: $dark-card-background;

			.swal-title {
				color: $dark-all-font-color;
			}

			.swal-text {
				color: $dark-small-font-color;
			}

			.swal-content__input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}

		.nav-tabs {
			border-bottom: 1px solid $dark-card-border;

			.nav-link {
				color: $dark-all-font-color;

				&.active {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-card-border $dark-card-border $dark-card-background;
				}

				&:hover,
				&:focus {
					border-color: $dark-card-border $dark-card-border $dark-card-inbox;
				}
			}

			.nav-item.show {
				.nav-link {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
			}
		}

		.nav-primary {

			.nav-link {
				&.active {
					background-color: var(--theme-default);
				}

			}

			.nav-item.show {
				.nav-link {
					background-color: var(--theme-default);
				}
			}
		}

		.custom-tab {
			&.nav-tabs {
				border-bottom: none;
			}
		}

		.bg-navbar {
			.nav {
				background-color: $dark-body-background;
			}
		}

		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {

					&.active,
					&.focus,
					&.hover {
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}

		.border-tab {
			.nav-link {

				&.active {
					background-color: transparent;
				}
			}

			.show {
				>.nav-link {
					background-color: transparent;
				}
			}
		}

		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: $dark-all-font-color;

				&.active {
					color: var(--theme-default);
				}
			}

			.show {
				>.nav-link {
					color: var(--theme-default);
				}
			}
		}

		.border-tab.nav-left.nav-secondary {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					border-left-color: var(--theme-secondary);
					color: var(--theme-secondary) !important;
				}
			}

			.show>.nav-link {
				border-left-color: var(--theme-secondary);
				color: var(--theme-secondary) !important;
			}

			.nav-item.show {
				color: var(--theme-secondary) !important;
				border-left-color: var(--theme-secondary);
			}
		}

		.border-tab.nav-left.nav-success {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}

			.show>.nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}

			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}

		.border-tab.nav-right.nav-info {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}

			.show>.nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}

			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}

		.border-tab.nav-secondary {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: var(--theme-secondary) !important;
				}
			}

			.nav-item {
				&.show {
					color: var(--theme-secondary) !important;
				}
			}
		}

		.border-tab.nav-success {
			.nav-link {

				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}

			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}

		.dropdown-divider {
			border-top: 1px solid $dark-border-color;
		}

		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-card-border;

			.icon-title {
				color: $dark-all-font-color;
			}

			span {
				color: $dark-small-font-color;
			}

			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}

			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}

		.horizontal-icons-tab {
			.nav-tabs {
				.nav-item {
					.nav-link {
						background-image: linear-gradient(90deg, transparent 70%, rgba(255, 255, 255, 0.08) 100%);
					}
				}
			}
		}

		.arrow-tabs {
			&.bg-navbar {
				i {
					color: $dark-all-font-color;
				}
			}
		}

		.bottom-navbar {
			.tab-content {
				.tab-paragraph {
					i {
						color: $dark-all-font-color;
					}
				}
			}
		}

		ul,
		ol,
		dl,
		p {
			color: $dark-all-font-color;

			li,
			dt,
			dd {
				color: $dark-all-font-color;
			}
		}

		#cd-timeline {
			&::before {
				background: $dark-border-color;
			}
		}

		.timeliny {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;

			&::before {
				background-color: $white;
			}

			.timeliny-dot {
				background-color: $dark-card-background;
				border: 1px solid $white;

				&::before {
					color: $dark-all-font-color;
				}
			}

			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: $dark-all-font-color;
							}

							&::after {
								background-color: $dark-border-color;
								color: $dark-all-font-color;
								border: none;
							}
						}
					}

					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;

								&::before {
									color: $dark-all-font-color;
								}
							}
						}
					}

					.timeliny-dot {
						&:hover {
							&::after {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.stroke-dark {
			stroke: $dark-all-font-color !important;
		}

		// tasks
		.taskadd {
			table {
				tr {
					td {
						.task_title_0 {
							color: rgba($white, 0.8);
						}
					}
				}
			}
		}

		.task-sidebar {
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-body-background;
			}
		}

		.todo-wrap {
			&.email-wrap {
				.todo-tasks {
					li {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.user-profile {

			.ttl-info {
				h6 {
					color: $dark-small-font-color;
				}

				span {
					color: $dark-all-font-color;
				}
			}

			.hovercard {
				.info {
					.title {
						a {
							color: $dark-all-font-color;
						}
					}
				}

				.user-image {
					.avatar {
						img {
							border-color: $dark-card-background;
						}
					}

					.icon-wrapper {
						background-color: $dark-card-background;
					}
				}

				.tabs-social {
					border-bottom: none !important;
				}
			}

			.follow {
				.follow-num {
					color: $dark-all-font-color;
				}
			}

			.profile-img-style {
				.user-name {
					color: $dark-all-font-color;
				}

				p {
					color: $dark-small-font-color;
				}
			}
		}

		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba($white, 0.5);
		}

		.jstree-default {

			.jstree-node,
			.jstree-icon {
				color: #455869 !important;
			}

			.jstree-anchor {
				color: $dark-small-font-color;
			}

			.jstree-clicked {
				color: $white;
				background-color: $transparent-color;
			}

			.jstree-hovered {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
			}
		}

		span.twitter-typeahead {
			.tt-menu {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}

			.tt-suggestion {
				color: $dark-all-font-color;
				background-color: $dark-body-background;
				border-top: 1px solid $dark-card-border;

				&:hover,
				&:focus {
					background-color: $dark-card-background;
				}
			}
		}

		.typography {
			small {
				color: $dark-all-font-color;
			}
		}

		.code-box-copy {
			pre {
				background-color: $dark-body-background;

				code {
					background-color: $dark-body-background;
				}
			}

			pre[class*="language-"] {
				border: 1px solid $dark-border-color;
			}

			.code-box-copy__btn {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color: $white;
			}

			code[class*=language-],
			pre[class*=language-] {
				text-shadow: 0px 1px $black;

				::selection {
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}

		table.fixedHeader-floating {
			background-color: $dark-body-background;
		}

		.note {
			textarea {
				color: $dark-all-font-color;
			}
		}

		.dt-button-info {
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;

			h2 {
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}

		pre {
			background-color: $dark-body-background;
		}

		#keytable_wrapper,
		#column-selector_wrapper,
		#auto-fill_wrapper,
		#custom-button_wrapper,
		#class-button_wrapper,
		#keyboard-btn_wrapper,
		#multilevel-btn_wrapper,
		#pagelength-btn_wrapper,
		#basic-colreorder_wrapper,
		#state-saving_wrapper,
		#real-time_wrapper,
		#basic-fixed-header_wrapper,
		#fixed-header-footer_wrapper,
		#export-button_wrapper,
		#excel-cust-bolder_wrapper,
		#cust-json_wrapper,
		#basic-key-table_wrapper,
		#focus-cell_wrapper,
		#responsive_wrapper,
		#new-cons_wrapper,
		#show-hidden-row_wrapper,
		#basic-row-reorder_wrapper,
		#full-row_wrapper,
		#rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}

		#example-style-8_wrapper {

			table.dataTable.stripe,
			table.dataTable.display {
				tbody {
					tr.odd {
						background-color: $dark-border-color;
					}
				}
			}
		}

		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.8);

			.sub-content {
				color: $dark-all-font-color;
			}
		}

		.b-light {
			border: 1px solid $dark-border-color !important;
		}

		.modal-content {
			background-color: $dark-card-background;

			.modal-header {
				border-bottom: 1px solid $dark-card-border;

				.close {
					color: $dark-all-font-color;
					font-weight: 400;
				}
			}

			.ui-front {
				.form-control {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-card-border;
				}
			}
		}

		.modal {
			.theme-close {
				color: $light-all-font-color;
			}
		}

		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}

		.token {

			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}

		.loader-box {
			&.card-loader {
				background-color: $dark-card-background;
			}
		}

		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid $dark-card-border !important;
					border-bottom: none !important;
				}
			}
		}

		@each $var in $alert-name {
			$i: index($alert-name, $var);

			.alert-#{$var}.inverse {
				color: $dark-all-font-color;
			}
		}


		.alert-theme {
			span {
				+span {
					+span {
						background-color: $dark-body-background;
					}
				}
			}

			i {
				padding: 20px;
				margin-right: 20px !important;
			}
		}

		.user-card {
			.user-deatils {
				h6 {
					color: $dark-small-font-color;
				}
			}

			.card-footer {
				>div {
					&:first-child {
						border-right: 1px solid $dark-border-color;
					}
				}

				.user-footer {
					h6 {
						color: $dark-small-font-color;
					}

					svg {

						path,
						rect {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}

		.navigation-option {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}

					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.product-box {
			.product-details {
				h6 {
					color: $dark-all-font-color;
				}

				>a {
					color: $dark-all-font-color;
				}

				span {
					color: $dark-small-font-color;
				}
			}

			.product-img {
				.product-hover {
					ul {
						li {
							background-color: $dark-card-background;

							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}

		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: $dark-all-font-color;
						}

						&:hover {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		@each $btn-name,
		$btn-color in (primary, var(--theme-default)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.radio-#{$btn-name} {
				input[type="radio"] {
					&+label {
						&::before {
							border-color: $btn-color !important;
						}

						&::after {
							background-color: $btn-color;
						}
					}

					&:checked {
						&+label {
							&::before {
								border-color: $btn-color !important;
							}

							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name,
		$btn-color in (primary, var(--theme-default)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}

		@each $btn-name,
		$btn-color in (primary, var(--theme-default)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}

				input[type="checkbox"] {
					&:checked {
						&+label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}

							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}

		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}
			}

			tr {

				th,
				td {
					&:hover {
						background-color: $dark-card-background;
					}

					span {
						&:hover {
							background-color: $dark-card-background;
						}
					}

					&.prev,
					&.next {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}

				&:first-child {
					th {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
			}
		}

		.btn-transparent {
			color: $dark-all-font-color;
		}

		#cal-basic {
			.fc-toolbar {

				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}

					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
				}

				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}

		.fc-button-group {

			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}

			.fc-month-button {
				color: $white !important;
			}
		}

		.visual-button {

			.button-light-secondary,
			.button-light-warning,
			.button-light-success {
				color: $dark-all-font-color;
			}
		}

		.mix-hover-btn {
			.txt-dark {
				color: rgba($white , 0.6) !important;
			}
		}

		.btn-light {
			&:not([disabled]):not(.disabled).active.active {
				background-color: $dark-body-background !important;
			}

			&.disabled,
			&:disabled {
				color: rgba($theme-body-font-color, 0.5) !important;
			}
		}

		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {

						.fc-month-button,
						.btn-light {
							color: $black !important;
						}

						.fc-agendaWeek-button {
							color: $white !important;

							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}

		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {

						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}

						.fc-month-button {
							color: $white !important;

							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}

		.categories {
			ul {
				li {
					a {
						color: $dark-small-font-color;
					}

					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.socialprofile {
			.badge {
				background-color: $dark-body-background;
			}

			.likes-profile {
				h5 {
					span {
						color: $dark-all-font-color;
					}
				}
			}

			span {
				color: $dark-small-font-color;
			}
		}

		.social-status {
			.d-flex {
				.flex-grow-1 {
					span {
						+span {
							color: rgba($dark-all-font-color, 0.4);
						}
					}

					p,
					.light-span {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}
		}

		.filter-cards-view,
		.timeline-content {
			p {
				color: rgba($dark-all-font-color, 0.4);
			}

			.comment-number {
				i {
					color: $dark-small-font-color;
				}
			}

			.comments-box {
				.input-group {
					background-color: $dark-body-background;

					.form-control {
						border: none !important;
					}

					.input-group-append {
						background-color: $dark-body-background;
					}

					.input-txt-bx {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.social-chat {
			.flex-grow-1 {
				border: 1px solid $dark-card-border;

				&:after {
					border-right: 7px solid $dark-card-background;
				}
			}

			span {
				span {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.details-about {
			.your-details {
				p {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.activity-log {
			.my-activity {
				p {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.bookmark {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.email-wrap {
			.flex-grow-1 {
				p {
					color: rgba($dark-all-font-color, 0.3);
				}
			}
		}

		//index page

		@media only screen and (max-width: 991.98px) {
			.page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
				border-right: 1px solid $dark-card-border;
			}
		}


		@media only screen and (max-width: 767.98px) {
			.page-wrapper {
				&.material-icon {
					.page-header {
						background-color: $dark-card-background;
					}
				}
			}
		}

		.apexcharts-tooltip.light {
			border-color: $dark-card-border;
			background-color: $dark-body-background;

			.apexcharts-tooltip-title {
				border-color: $dark-card-border;
			}

			.apexcharts-tooltip-text-label,
			.apexcharts-tooltip-text-value {
				color: $white;
			}
		}

		.faq-accordion {
			&.default-according {
				.card {
					.faq-header {
						border: 0 !important;
					}
				}
			}
		}

		// seller details 
		.seller-order-table {
			.recent-table {
				div.dt-container {
					.table {

						thead,
						tbody {
							tr {

								th,
								td {
									a {
										color: rgba($dark-all-font-color, 0.4);
									}

									&:nth-child(4) {
										a {
											color: rgba($dark-all-font-color, 0.4);
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.seller-details-table {
			.recent-table {
				.table {

					thead,
					tbody {
						tr {

							th,
							td {
								.product-action {
									.square-white {
										box-shadow: unset;
										background-color: $dark-body-background;

										svg {
											fill: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.review-box {
			.seller-profile {
				.accordion-button {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}

				.accordion-item {
					.accordion-body {
						background-color: $dark-card-inbox;

						li {
							>div {

								h6,
								i:not(.fill) {
									color: $dark-all-font-color;
								}
							}
						}

						.policies-wrapper {
							li {
								label {
									color: $dark-all-font-color;
								}
							}
						}

						.notification-wrapper {
							.form-check-label {
								color: $dark-all-font-color;
							}
						}

						.checkbox-wrapper {
							li {
								.form-check-label {
									i {
										color: $dark-all-font-color;
									}
								}
							}
						}
					}
				}
			}

			.review-people {
				.review-list {
					li {
						.people-box {
							.people-comment {
								h6 {
									color: rgba($dark-all-font-color, 0.4);
								}

								.date-time {
									h6 {
										color: rgba($dark-all-font-color, 0.4);
									}
								}
							}
						}
					}
				}
			}
		}

		.seller-details-wrapper {
			.review-box {
				.seller-profile {
					.accordion-item {
						background-color: $dark-card-background;

						.accordion-header {
							.accordion-button {
								&::after {
									filter: invert(1);
								}
							}
						}
					}
				}

				.md-sidebar {
					.email-left-aside {
						.card {
							background-color: $dark-body-background;
						}
					}
				}
			}

			.seller-order-table {
				tr {
					td {
						.square-white {
							background-color: unset;
						}
					}
				}
			}
		}

		// apex chart
		#radarchart {
			.apexcharts-canvas {
				.apexcharts-graphical {
					.apexcharts-plot-series {
						polygon {
							fill: $dark-body-background;
							stroke: $bg-dark-color;

							&:nth-child(odd) {
								fill: $dark-card-inbox;
							}
						}

						line {
							stroke: $bg-dark-color;
						}
					}
				}
			}
		}

		.apexcharts-radialbar-track {
			&.apexcharts-track {
				path {
					stroke: $dark-body-background;
				}
			}
		}

		// product details 
		.collection-filter-block {
			h6 {
				color: $dark-all-font-color;
			}

			ul {
				&.pro-services {
					li {
						span {
							color: rgba($dark-all-font-color, 0.3);
						}
					}
				}
			}

		}

		.rating-box {
			ul {
				li {
					.rating-list {
						.progress {
							background: $dark-body-background;
						}
					}
				}
			}
		}

		.product-rating-box {
			.review-title-2 {
				p {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		// Seller
		.seller-wrapper {
			.form-check {
				.form-check-input {
					&:valid {
						~.form-check-label {
							color: rgba($dark-all-font-color, 0.3);
						}
					}
				}
			}
		}

		// Mega options 
		.megaoption-space {
			span:not(.pull-right) {
				&:last-child {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.main-upgrade {
			.variation-box {
				.selection-box {
					.d-flex {
						color: $bg-dark-color;
					}
				}
			}
		}

		// Alert
		.dark-alert {
			.alert {
				p {
					color: $dark-body-background;
				}
			}
		}

		.dark-alert-msg {
			.alert {
				&[class*="alert-bg-"] {
					p {
						color: $dark-all-font-color;
					}
				}

				&[class*="alert-bg-"] {
					.btn-close {
						filter: invert(1);
					}
				}
			}
		}

		.dark-txt {
			p {
				strong {
					color: $dark-all-font-color !important;
				}
			}
		}

		.live-dark {
			.btn-close {
				filter: initial;
			}
		}

		.alert-light {
			color: $dark-body-background !important;
		}

		// Rating
		.heart-rating {
			.rating-text {
				color: $dark-all-font-color;
			}
		}

		// Flash Icons
		.main-animate {
			a {
				background: $dark-card-inbox;
			}

			svg {
				stroke: $dark-all-font-color;

				&.fill-icon,
				&.fill-secondary {
					stroke: none;
					fill: $dark-all-font-color;
				}
			}

			i {
				color: $dark-all-font-color;
			}

			&.heartbeat-wrapper {
				svg {
					stroke: $danger-color;
				}

				i {
					color: $danger-color;
				}
			}

			&.border-pulse-wrapper {
				a {
					border-color: $dark-card-border;
				}
			}
		}

		.pulse-wrapper {

			svg,
			i {
				animation: pulse-animate-dark 1s infinite ease;
			}
		}

		@keyframes pulse-animate-dark {
			0% {
				box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
			}

			100% {
				box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
			}
		}

		// Dahboard-5
		.mobile-app-card {

			&.social-empower,
			&.upgrade-plan {
				background: $dark-body-background;
			}
		}

		.campaign-table {
			.badge-light-light {
				&.disabled {
					color: $dark-editor-document;
				}
			}

			.twitter {
				>div {
					border-color: $dark-all-font-color;
				}

				.social-circle {
					background-color: $light-all-font-color;
					border-color: $dark-all-font-color;
					color: $dark-all-font-color;
				}
			}
		}

		// Dashboard-6
		.live-auction-wrapper {
			.product-nft-content {
				>div {
					p {
						span {
							&.text-black {
								color: $dark-all-font-color !important;
							}
						}
					}
				}
			}
		}

		.nft-collection {
			.nft-user-info {
				.user-desc {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		// Dashboard-7
		.currency-table {
			&.task-table {
				.table {
					.main-task-wrapper {
						tr {
							td {
								.form-check-label {
									&::before {
										background-color: $dark-body-background;
									}
								}
							}

							.icons-box {
								.square-white {
									i {
										color: rgba($dark-all-font-color, 0.4);
									}
								}
							}
						}

						.square-white {
							i {
								color: rgba($dark-all-font-color, 0.4);
							}
						}
					}
				}
			}
		}

		// Tour
		.hovercard {
			.info {
				.follow {
					.border-end {
						border-color: $light-all-font-color !important;
					}
				}
			}
		}

		.tour-email {
			span {
				color: $dark-small-font-color;
			}
		}

		// Dropdown
		.dropdown-menu {
			li {
				&:first-child {
					.dropdown-item {
						border-top-color: $transparent-color;
					}
				}
			}

			.dropdown-item {
				border-top-color: $dark-card-border;
				color: $dark-all-font-color;
				opacity: 0.9;

				&:hover {
					background-color: $dark-card-inbox;
				}
			}
		}

		.split-dropdown {
			.dropdown-basic {
				.btn-group {
					.dropdown-menu {
						.dropdown-item {
							a {
								border-top-color: $dark-border-color;
								color: $dark-all-font-color;
								opacity: 0.9;

								&:hover {
									background-color: $dark-card-inbox;
								}
							}
						}
					}
				}
			}
		}

		.dropdown-basic {
			.dropdown {
				.dropdown-content {
					a {
						border-top-color: $dark-border-color;
						color: $dark-all-font-color;
						opacity: 0.9;

						&:hover {
							background-color: $dark-card-inbox !important;
						}
					}
				}
			}
		}

		.dark-input-type {
			.input-group {

				.input-group-text {
					background: transparent;
					border: unset;
				}
			}
		}

		.heading-dropdown {
			.dropdown-menu {
				>li {
					&:first-child {
						>a {
							background-color: $dark-body-background;
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		btn-group {
			.dropdown-wrapper {
				.input-group {
					border-top-color: $dark-border-color;
					color: $dark-all-font-color;
					opacity: 0.9;

					&:hover {
						background-color: $dark-card-inbox;
					}
				}
			}
		}

		.helper-cards {
			.dropdown-block {
				background-color: $dark-card-inbox;

				li {
					h6 {
						color: $dark-all-font-color;
					}

					.dropdown-item {
						background-color: $transparent-color;
					}
				}
			}
		}

		.dark-dropdown-box {
			.btn-group {
				.dropdown-toggle {
					color: $dark-all-font-color;
				}
			}
		}

		.form-wrapper {

			&.dark-form,
			&.show {
				background-color: $dark-body-background;
			}
		}

		// Accordion
		.dark-accordion {
			.accordion-item {
				border: 1px solid $dark-card-border;
				background-color: $transparent-color;

				.accordion-collapse {
					p {
						color: $dark-body-background;
					}

					.accordion-body {
						background-color: $dark-card-background;

						h6 {
							border: 0;
						}
					}
				}
			}

			.collapse-wrapper {
				color: $dark-body-background;
			}

			&.card {
				border-radius: unset;

				&.card-body {
					&.mb-0 {
						border-radius: 15px;
					}
				}
			}
		}

		.accordion {
			&.dark-accordion {
				.accordion-item {
					.accordion-collapse {
						.accordion-body {
							.accordions-content {
								li {
									color: $dark-small-font-color;
								}
							}
						}

						p {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}

		.accordions-content {
			li {
				color: $dark-card-background;
			}
		}

		// List
		.dark-list {
			.list-group {
				.list-group-item {
					+.list-group-item {
						border-top-width: 1px;
					}

					.form-check-input {
						border: 1px solid $dark-all-font-color;
						background-color: transparent;
					}
				}
			}

			.list-light-dark {
				background-color: $dark-body-background !important;
			}
		}

		// Typography 
		.card-wrapper {
			.sub-title {
				border-bottom: unset !important;
			}
		}

		.figure {
			&.dark-blockquote {
				.blockquote {
					border-left-color: var(--theme-default);
					border-right-color: var(--theme-default);

					p {
						color: $dark-all-font-color !important;
					}
				}
			}
		}

		.typography-list {
			.card-wrapper {
				dl {
					dt {
						color: rgba($dark-all-font-color, 0.8);
					}
				}
			}
		}

		// Table
		.datatable-init,
		.datatable-advance-wrapper,
		.datatable-api-wrapper,
		.data-source-wrapper,
		.autofill-data-tables,
		.support-ticket-table {
			div.dt-container {
				&.dt-bootstrap5 {
					table {
						tbody {
							tr {
								td {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}
				}
			}
		}

		.basic_table {
			.card-block {
				.table {
					&.light-card {
						background-color: $dark-card-inbox;
					}
				}

				table.table-light {
					--bs-table-bg: #22262f;

					thead,
					tbody {
						tr {

							th,
							td {
								color: $dark-all-font-color !important;
							}
						}
					}

					tbody {
						tr {
							td {
								background-color: $dark-card-inbox !important;
							}
						}
					}
				}
			}
		}

		// Modal
		.dark-sign-up {
			.modal-body {
				form {
					.form-label {
						color: $bg-dark-color;
					}

					.form-check {
						.form-check-label {
							color: $bg-dark-color;
						}
					}

					.form-control {
						color: $dark-all-font-color;
					}
				}
			}

			&.social-profile {
				background-image: unset;
				background-color: $dark-body-background;

				.modal-body {
					form {
						.form-check {
							.form-check-label {
								color: $dark-all-font-color;
							}
						}
					}

					.form-label {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.modal-dialog {
			.modal-content {
				.dark-modal {
					.large-modal-header {
						svg {
							color: $dark-all-font-color;
						}

						h6 {
							color: $dark-all-font-color;
						}
					}

					.large-modal-body {
						svg {
							color: $dark-all-font-color;
						}

						p {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.modal-toggle-wrapper {
			.dark-toggle-btn {
				color: $dark-all-font-color;
			}
		}

		.social-profile {
			background-attachment: fixed;

			.social-details {
				h5 {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.md-sidebar {
			.job-sidebar {
				box-shadow: 0px 9px 20px rgba($dark-body-background, 0.7);
			}
		}

		.job-sidebar {
			.job-accordion {
				.card-body {
					&.animate-chk:not(.filter-cards-view) {
						.form-check {
							.form-check-label {
								.number {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}
				}
			}
		}

		// File-manager
		.main-file-sidebar {
			.md-sidebar {
				.md-sidebar-aside {
					.common-sort-card {
						background-color: $dark-body-background;
					}
				}
			}
		}

		.file-manager-grid {
			.freeze-ui {
				background-color: rgba($dark-body-background, 0.8);
			}

			.doc-icon {
				color: $dark-body-background;

				&::after {
					@include file-icon($dark-body-background, var(--dark-light-color), var(--dark-light-color));
				}
			}

			.file {
				&:hover {
					.doc-icon {
						&::after {
							@include file-icon($dark-body-background, var(--dark-light-color), var(--dark-light-color));
						}
					}
				}
			}

			.item-selected {
				background-color: $dark-card-inbox;

				&.file {
					.doc-icon {
						&::after {
							@include file-icon($dark-card-inbox, var(--dark-light-color), var(--dark-light-color));
						}
					}
				}

				&:hover {
					background-color: $dark-card-inbox;
				}
			}
		}

		.common-file-manager {
			.filemanger {
				.top-menu {
					background-color: $dark-body-background;

					button {
						background-color: $dark-card-inbox;
						color: $dark-all-font-color;
					}
				}

				.top-folder-path {
					background-color: $dark-card-background;

					.path-action-btns {
						button {
							background-color: $dark-card-background;

							&:disabled {
								background-color: $dark-card-background;
							}
						}
					}

					.folder-path-write {
						background-color: $dark-body-background;

						button {
							background-color: $dark-body-background;
						}

						input {
							color: rgba($dark-all-font-color, 0.4);
						}
					}
				}
			}

			.popup {
				.popup-content {
					background-color: $bg-dark-color;

					form {
						input {
							background-color: $dark-card-inbox;
							color: $dark-all-font-color;
						}
					}
				}
			}

			.folderEmpty {
				background-color: rgba($dark-body-background, 0.4);
			}
		}

		// Common BS Select picker
		.bootstrap-select {
			.dropdown-toggle {
				color: $dark-gray;
				background-color: $dark-body-background !important;
				border-color: $transparent-color !important;
			}

			.bs-searchbox {
				background-color: $dark-card-inbox;
			}

			.no-results {
				background-color: $dark-body-background;
			}

			.dropdown-menu {
				.dropdown-header {
					background-color: $dark-body-background;
				}

				.dropdown-item {
					background-color: $dark-card-background;
					color: $dark-all-font-color !important;

					&:hover {
						background-color: $dark-body-background !important;
					}
				}

				.form-control {
					border-color: $dark-card-border;
				}
			}
		}

		.discount-options {
			.nav-link {
				color: rgba($dark-all-font-color, 0.4);
			}
		}

		// Dropzone
		.filepond--drop-label {
			&.filepond--drop-label {
				label {
					color: $dark-all-font-color;
				}
			}
		}

		.filepond--root {
			.filepond--panel-root {
				background-color: $dark-body-background;
			}
		}

		.dropzone {
			&.dropzone-secondary {
				i {
					color: $dark-all-font-color;
				}
			}

			.dz-preview {
				&.dz-file-preview {
					.dz-image {
						background: linear-gradient(to bottom, $dark-card-inbox, $dark-body-background);
					}
				}

				&.dz-image-preview {
					background: $transparent-color;
				}

				.dz-details {

					.dz-size,
					.dz-filename {
						span {
							background-color: $transparent-color !important;
							color: rgba($dark-all-font-color, 0.3);
						}
					}
				}
			}
		}

		// Warning alert for jquery
		.warning-alert {
			p {
				color: $dark-all-font-color;
			}

			.btn-close {
				filter: invert(1);
			}
		}

		// Helper-classes
		.dark-helper {
			background-color: rgba($dark-body-background, 0.4);

			h6,
			span {
				color: $dark-all-font-color;
			}

			.text-muted {
				color: $dark-card-background !important;
			}
		}

		.font-dark {
			color: $dark-all-font-color !important;
		}

		.helper-common-box {
			.border-common-box {
				border-color: $dark-card-border;
			}

			.radius-wrapper {
				background-color: $dark-body-background;
			}
		}

		// Range-slider 
		.irs-min,
		.irs-max {
			color: $dark-small-font-color;
			background-color: $dark-card-inbox;
		}

		.irs--flat,
		.irs--square,
		.irs--round {
			.irs-line {
				background-color: $dark-body-background;
			}
		}

		.irs--big,
		.irs--modern {
			.irs-line {
				background: $dark-body-background;
				border-color: $dark-card-border;
			}
		}

		.irs--sharp {
			.irs-line {
				background-color: $dark-body-background;
			}
		}

		// Maps
		.jvectormap-container {

			.jvectormap-zoomin,
			.jvectormap-zoomout {
				background: $dark-card-inbox;
			}
		}

		// Divider
		.linear-divider {
			h6 {
				color: rgba($dark-all-font-color, 0.5);
			}
		}

		.main-divider {
			.vertical-divider {
				.divider-dark-icon {
					stroke: rgba($white, 0.5);
				}

				.vertical-line {
					color: $dark-card-background;

					&.bg-dark {
						background-color: $dark-all-font-color !important;
					}
				}
			}

			.divider-h-line,
			.vr {
				&.bg-dark {
					background-color: $dark-all-font-color !important;
				}
			}
		}

		.animated-divider {
			&.animated-line {
				&:before {
					background: linear-gradient(90deg, $dark-card-background 0%, $dark-card-background 5%, transparent 5%, transparent 10%, $dark-card-background 10%, $dark-card-background 15%, transparent 15%, transparent 20%, $dark-card-background 20%, $dark-card-background 25%, transparent 25%, transparent 30%, $dark-card-background 30%, $dark-card-background 35%, transparent 35%, transparent 40%, $dark-card-background 40%, $dark-card-background 45%, transparent 45%, transparent 50%, $dark-card-background 50%, $dark-card-background 55%, transparent 55%, transparent 60%, $dark-card-background 60%, $dark-card-background 65%, transparent 65%, transparent 70%, $dark-card-background 70%, $dark-card-background 75%, transparent 75%, transparent 80%, $dark-card-background 80%, $dark-card-background 85%, transparent 85%, transparent 90%, $dark-card-background 90%, $dark-card-background 95%, transparent 95%, transparent 100%);
					background-size: 150px;
				}
			}
		}

		// Ribbons 
		.ribbon-wrapper,
		.ribbon-wrapper-bottom,
		.ribbon-vertical-left-wrapper,
		.ribbon-wrapper-right,
		.ribbon-vertical-right-wrapper {
			&.alert-light-light {
				background-color: $dark-card-inbox;
			}
		}

		// Progress
		button {
			&.progress-btn {
				background-color: $dark-card-inbox;
				border-color: $dark-card-inbox;
			}
		}

		// Paginations
		.pagination {
			.page-item {
				.page-link {
					&.rounded-circle {
						color: $dark-all-font-color !important;
					}
				}

				&.disabled {
					.page-link {
						border-color: $dark-card-border !important;
					}
				}
			}
		}

		// Timeline
		.dark-timeline {
			.date-content {
				color: $dark-all-font-color !important;
				background-color: $dark-body-background !important;
			}
		}

		.animated-timeline {
			.timeline-block {
				.title {
					color: $dark-all-font-color;
				}

				&::before {
					border: 1px dashed $dark-card-border;
				}

				.timeline-event {
					background-color: $dark-body-background;
					border: 1px dashed $dark-card-border;

					.timeline-desc {
						h6 {
							color: $dark-all-font-color;
						}

						span {
							color: rgba($dark-all-font-color, 0.4);
						}
					}

					&::before {
						background-color: $dark-card-border;
					}

					&.non-focus {
						background-color: rgba($dark-body-background, 0.4);
						border: 1px dashed rgba($dark-card-border, 0.4);

						.timeline-desc {
							h6 {
								color: rgba($dark-all-font-color, 0.4);
							}

							span {
								color: rgba($light-all-font-color, 0.4);
							}
						}
					}
				}
			}
		}

		.timeline-list {

			&.timeline-border {
				border-bottom-color: $dark-card-border;
			}
		}

		// Default dashboard
		.activity-log {
			&.main-timeline {
				&.notification {
					.basic-timeline {
						ul {
							li {
								.date-content {
									background-color: $transparent-color !important;
								}
							}
						}
					}
				}
			}
		}

		// Dashboard-7
		.student-leader-wrapper {
			.student-leader-content {
				border-bottom-color: $dark-card-border;
			}
		}

		.attendance-card {
			.left-overview-content {
				.svg-box {
					box-shadow: unset;
					border: 1px solid $dark-card-border;
				}
			}
		}

		.task-table {
			.main-task-wrapper {
				.square-white {
					box-shadow: unset;
					border: 1px solid transparent;
					background-color: $dark-body-background;
				}
			}
		}

		.income-wrapper {
			ul {
				li {
					+li {
						border-left: 1px dashed $dark-card-border;

						[dir="rtl"] & {
							border-left: unset;
							border-right: 1px dashed $dark-card-border;
						}

						@media (max-width:1735px) {
							border-left: unset;

							[dir="rtl"] & {
								border-right: unset;
							}
						}
					}
				}
			}
		}

		.icons-box-2 {
			.open-options {
				.dropdown-menu {
					background-color: $dark-body-background;

					.dropdown-item {
						&:hover {
							background-color: $dark-card-inbox;
						}
					}
				}
			}
		}

		// Dashboard-8
		.main-product-wrapper {
			.product-body {
				.product-search {
					&.input-group {
						>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
							color: $dark-small-font-color;
						}

						.input-group-text {
							border: 1px solid $dark-card-border;
						}

						.form-control {
							border: 1px solid $dark-card-border;
							border-left: unset;
							background-color: $dark-card-background;

							[dir="rtl"] & {
								border: 1px solid $dark-card-border;
								border-right: unset;
							}
						}
					}
				}
			}
		}

		.shop-category-slider {
			.swiper-wrapper {
				.category-title {
					color: $dark-all-font-color !important;
				}
			}
		}

		.our-product-wrapper {
			box-shadow: 0px 3px 17px rgb(113 113 137 / 18%);
		}

		.order-details {
			.search-items {
				.input-group-text {
					border: 1px solid $dark-card-border;
				}
			}

			.common-search {
				.input-group-text {
					border: 1px solid $dark-card-border;
				}

				&.input-group {
					.form-control {
						&:focus {
							border-color: $dark-card-border;
						}
					}
				}
			}
		}

		.create-customers {
			.modal-body {
				.dark-sign-up {
					form {
						.form-label {
							color: $dark-all-font-color;
						}

						.form-control {
							color: $light-all-font-color;

							&::placeholder {
								color: $light-all-font-color;
							}
						}
					}
				}
			}
		}

		.customer-sticky {
			.border-bottom {
				border-bottom-color: $dark-card-border !important;
			}
		}

		// Dashboard-9
		.compare-order {
			.card-body {
				.progress {
					background: $dark-body-background;
				}

				.user-text {
					color: rgba($dark-all-font-color, 0.4);
				}
			}

			.compare-icon {
				&[class*="shadow-"] {
					box-shadow: 0px 10.511241px 12.4089927673px 0px $dark-card-inbox;
				}
			}
		}

		.pipeline-data {
			.common-flex {
				span {
					color: rgba($dark-all-font-color, 0.4);

				}
			}
		}

		.crm-todo-list {
			li {
				span {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.analytics-widget {
			background-color: $dark-card-background;
			background-blend-mode: darken;
		}

		// grid 
		.grid-showcase {
			span {
				background-color: $dark-body-background;
			}
		}

		// Ribbons 
		.ribbon-wrapper,
		.ribbon-wrapper-bottom,
		.ribbon-vertical-left-wrapper,
		.ribbon-wrapper-right,
		.ribbon-vertical-right-wrapper {
			&.alert-light-light {
				background-color: $dark-body-background;
			}
		}

		// Dashboard-10
		.main-session-devices {
			.progress {
				background: $dark-body-background;
			}
		}

		// Dashboard-11
		.widget-11 {
			background-blend-mode: color-burn;
		}

		.project-finish-card {
			.project-left-content {
				.progress {
					background: linear-gradient(180.36deg, $dark-card-border -32.82%, $dark-body-background 99.69%);
				}
			}
		}

		.employee-list-table {
			&.recent-table {
				table {
					tbody {
						.square-white {
							box-shadow: unset;

							svg {
								fill: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		// Dashboard-12
		.dashboard-12 {
			.tasks-card {
				.arrow-group {

					.next,
					.prev {
						background-color: $dark-body-background;
					}
				}

				.task-box {
					.progress {
						background: $light-all-font-color;
					}

					.swiper-wrapper {
						.swiper-slide {
							+.swiper-slide {
								border-color: $dark-card-border;
							}
						}
					}
				}
			}

			.task-card {
				.common-space {

					&:nth-child(2),
					&:nth-child(3) {
						color: rgba($dark-all-font-color, 0.3);
					}
				}
			}

			.small-stats {
				.task-box {

					p,
					a {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}
		}

		// Dashboard-13
		.dashboard-13 {
			.widget-13 {
				background-blend-mode: color-burn;
			}

			.shipping-details {
				.simple-timeline {
					ul {
						li {
							.shipment-details {
								p {
									&:last-child {
										border-bottom-color: rgba($dark-card-border, 0.5);
									}
								}
							}

							.dots {
								border-color: $dark-body-background;
								box-shadow: 0px 6px 18px 0px rgba($dark-small-font-color, 0.19);
							}
						}
					}
				}
			}
		}

		// Settings
		.main-setting {
			.tab-content {
				border-color: $dark-card-border;

				.advance-options {
					.nav-link {
						background-color: $transparent-color;
					}
				}
			}

			.nav-link {
				background-color: $dark-body-background;
				color: rgba($dark-all-font-color, 0.4);

				svg {
					stroke: rgba($dark-all-font-color, 0.4);
				}
			}

			.form-label {
				color: $dark-all-font-color;
			}

			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}

		// Multiple select
		.multiple-selects {
			select {
				option {
					&:checked {
						background-color: $dark-card-inbox;
						color: $dark-all-font-color;
					}
				}
			}
		}

		.autosize-textarea {
			.textarea {
				border-color: $dark-card-border;
				color: $dark-all-font-color;
				background-color: $dark-body-background;
			}

			.textarea[contenteditable]:empty::before {
				color: rgba($dark-all-font-color, 0.2);
			}
		}


		// Timeline
		.square-timeline {
			&::before {
				border-color: $dark-card-border;
			}

			.timeline-event {
				.timeline-event-wrapper {
					.timeline-thumbnail {
						color: $dark-all-font-color;

					}
				}

				&:hover {
					.timeline-event-wrapper {
						.timeline-thumbnail {
							color: $dark-body-background;
						}
					}
				}
			}
		}

		.rounded-timeline {
			.container {
				&::before {
					background: $dark-card-border;
				}

				&::after {
					border-color: $dark-card-border;
					background: $bg-dark-color;
				}
			}

			.timeline {
				&::after {
					background: $dark-card-border;
				}
			}
		}

		audio::-webkit-media-controls-play-button,
		audio::-webkit-media-controls-panel {
			background-color: $dark-card-background;
			color: $dark-all-font-color;

			@media (max-width:335px) {
				background-color: $transparent-color;
			}
		}

		audio::-webkit-media-controls-current-time-display,
		audio::-webkit-media-controls-time-remaining-display {
			text-shadow: unset;
		}

		audio::-webkit-media-controls-enclosure {
			background: unset;
		}

		// Project-list
		.project-cards {
			.project-list {
				.main-card-box {
					.cost-right-data {
						ul {
							li {
								&:first-child {
									border-color: rgba($dark-all-font-color, 0.3);
								}

								span {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}

					>.common-space {
						border-color: rgba($dark-all-font-color, 0.3);
					}
				}
			}

			.projects-wrapper {
				.list-box {
					.project-body {
						.progress {
							background-color: $light-all-font-color;
						}
					}
				}
			}
		}

		// Gallery
		.gallery-description-skeleton {
			.skeleton-wrapper {
				border-color: $dark-card-border;
			}
		}

		// Jobs
		.job-accordion {
			.location-checkbox {
				label {
					span {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}

			span:not(.number) {
				color: $dark-all-font-color;
			}

			.filter-cards-view,
			.animate-chk {
				.form-check {
					.form-check-label {
						.number {
							color: rgba($dark-all-font-color, 0.4);
						}
					}
				}
			}
		}

		.job-apply-wrapper {
			.job-description {
				input[type=text] {
					border: 1px solid $transparent-color;
				}
			}
		}

		// social-app
		.social-app-profile {
			.tab-pane {
				.job-accordion {
					.filter-cards-view {
						p {
							color: rgba($dark-all-font-color, 0.4);
						}
					}
				}
			}
		}

		.social-app-profile1 {
			.social-app-profile {
				.social-friends {
					.social-profile {
						.card-social {
							&::before {
								border-top-color: $dark-card-border;
							}
						}
					}

					.social-follow {
						li {
							+li {
								&::before {
									background-color: $light-all-font-color;
								}
							}
						}
					}
				}

				.gallery-with-description {
					.caption {
						ul {
							li {
								+li {
									border-left-color: $dark-card-border;
								}
							}
						}
					}
				}

				.common-user-image {
					.tabs-social {
						.nav-item {
							.nav-link {
								&.active {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
			}
		}

		// Tabs
		.main-vertical-tab {
			.nav-link {
				.badge {
					background-color: rgba($white, 0.9) !important;
				}
			}
		}

		// Projects
		.main-scope-project {
			.progress-project-box {
				.project-body {
					.progress {
						background-color: $light-all-font-color;
					}
				}
			}
		}

		.create-project-form {
			.bootstrap-select {
				.dropdown-toggle {
					border-color: $dark-body-background !important;
					background-color: $dark-body-background !important;
					color: $light-all-font-color !important;
				}

				.dropdown-menu {
					.bs-searchbox {
						background-color: $dark-body-background;
					}
				}

				.inner {
					background-color: $dark-body-background;

					.dropdown-menu {
						.no-results {
							background: $dark-body-background;
						}
					}
				}
			}

			.form-control {
				&::-webkit-input-placeholder {
					color: rgba(255, 255, 255, 0.15);
				}
			}
		}

		.progress-project-box {
			@include pixelstrap-status(false);
		}

		.projects-wrapper {
			.project-body {
				.project-bottom {
					p {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.budget-card {
			&.widget-2 {
				background-blend-mode: color-burn;
			}
		}

		.filter-header {
			.btn-group {
				.dropdown-toggle {
					border-color: rgba($dark-card-border, 0.4);
					color: $dark-all-font-color;
				}

				.dropdown-menu {
					li {
						.dropdown-item {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		.project-timeline {
			.notification {
				ul {
					li {
						span {
							color: $dark-all-font-color;

							.activity-profile {
								border-color: $dark-card-border;
							}
						}

						.upload-doc {
							background-color: $dark-body-background;
						}

						.flowchart-wrapper {
							background-color: $dark-body-background;
						}

						.project-task-note {
							border-color: $dark-card-border;
						}
					}
				}
			}
		}

		.attach-files-wrapper {
			.upload-files-wrapper {
				>div {
					background-color: $dark-body-background;

					.common-space {

						p,
						span {
							color: rgba($dark-all-font-color, 0.4);
						}
					}

					.progress {
						background: $dark-card-inbox;
					}
				}
			}
		}

		.project-budget-wrapper {

			.form-check-input,
			.form-control {
				&:focus {
					border-color: $dark-card-border;
				}
			}

			.touchspin-wrapper {
				background-color: rgba($dark-body-background, 0.8);
			}
		}

		.common-searchbox {
			.input-group-text {
				border: 1px solid $dark-card-border;
			}

			.form-control {
				&:focus {
					border-color: $dark-card-border;
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.progress-project {
			border-color: $dark-card-inbox;

			.common-space {
				.common-align {
					.badge {
						background-color: $bg-dark-color;
					}
				}
			}
		}

		.summary-chart-box {
			background-color: $dark-body-background;

			.apexcharts-graphical {
				.apexcharts-radial-series {
					.apexcharts-text {
						fill: $dark-all-font-color;
					}
				}
			}
		}

		.scope-bottom-wrapper {
			.sidebar-left-icons {
				.nav-item {
					.nav-link {
						.nav-rounded {
							.product-icons {
								svg {
									fill: $dark-small-font-color;
								}
							}
						}
					}

					&:nth-child(4) {
						svg {
							stroke: $dark-small-font-color;
						}
					}
				}
			}
		}

		.common-project-header {
			.common-align {
				&:last-child {
					.common-align {
						li {
							span {
								color: $dark-all-font-color;
							}

							svg {
								stroke: $dark-small-font-color;
							}
						}
					}
				}
			}
		}

		.project-pending-table {
			&.recent-table {
				table {

					thead,
					tbody {
						tr {

							th,
							td {
								color: $dark-all-font-color !important;
							}
						}
					}
				}
			}
		}

		.activity-wrapper {
			.schedule-wrapper {
				border-bottom: unset;
			}

			.tab-content {
				.activity-update {
					>li {
						border-left-color: $dark-card-border;
					}
				}
			}
		}

		.invite-member {
			ul {
				li {
					svg {
						stroke: $dark-all-font-color;
					}
				}
			}
		}

		.main-team-box {
			>div {
				background-color: $dark-card-inbox;
			}
		}

		.team-box {
			&-content {
				.common-space {
					>span {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}

			&-footer {
				border-top-color: $dark-border-color;
			}
		}

		.cmt-box {
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}

		// Scrollspy
		.main-scrollspy {
			.experience-section {
				h6 {
					color: rgba($dark-all-font-color, 0.4);
				}
			}

			.navbar-scrollspy {
				.nav-pills {
					.nav-item {
						&.dropdown {
							.dropdown-menu {
								li {
									.dropdown-item {

										&.active,
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// Blog
		.blog-box {
			.blog-details {
				p {
					color: $dark-all-font-color;
				}

				.blog-social {
					li {
						color: rgba($dark-all-font-color, 0.4);
					}
				}

				.single-blog-content-top {
					border-color: $dark-card-border;
				}
			}

			.blog-date {
				color: rgba($dark-all-font-color, 0.8);
			}

			.blog-details-main {
				.blog-social {
					li {
						color: $dark-all-font-color;
					}
				}

				.blog-bottom-details {
					color: $dark-all-font-color;
				}
			}
		}

		.blog-list {
			.blog-details {
				h6 {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.comment-box {
			.d-flex {
				img {
					border-color: $dark-card-border;
				}

				h6 {
					span {
						color: rgba($dark-all-font-color, 0.3);
					}
				}
			}

			.comment-social {
				li {
					color: rgba($dark-all-font-color, 0.2);

					&:first-child {
						border-right-color: $dark-card-border;

						[dir="rtl"] & {
							border-left-color: $dark-card-border;
							border-right-color: unset;
						}
					}
				}
			}
		}

		.blog-single {
			.blog-details {
				.single-blog-content-top {
					p {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}

			.comment-box {
				p {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		// Block-ui
		.user-profile {

			.freeze-ui4,
			.freeze-ui7 {
				background-color: rgba($dark-body-background, 0.8);
			}

			.block-main-wrapper {
				.block-wrapper {
					.freeze-ui {
						background-color: rgba($dark-body-background, 0.8);
					}
				}
			}

			.overlay-bg {
				background-color: rgba($dark-body-background, 0.1) !important;
				opacity: 0.3;
			}
		}

		.form-main-wrapper {
			.form-block-wrapper {
				.form-control {
					&::placeholder {
						color: $light-all-font-color;
					}
				}
			}
		}

		// Sweetalert2
		div:where(.swal2-container) input:where(.swal2-input) {
			border-color: $dark-card-border;
		}

		.swal2-container {
			.swal2-popup {
				background: $dark-body-background;

				.swal2-title {
					color: $dark-all-font-color;
				}

				.swal2-checkbox {
					background: $transparent-color;
				}

				.form-label,
				label {
					color: $dark-all-font-color;
				}

				.form-control {
					color: $light-all-font-color;
				}

				.swal2-html-container {
					color: rgba($dark-all-font-color, 0.4);
				}
			}

			.swal2-wrapper {

				.form-control,
				.form-select {
					border-color: $dark-card-border;
					background-color: $dark-card-background;
				}
			}

			.swal2-timer-progress-bar-container {
				.swal2-timer-progress-bar {
					background-color: $dark-card-border;
				}
			}
		}

		// Offcanvas
		.main-offcanvas-wrapper {
			.card-body {
				[class*="offcanvas-"] {
					.offcanvas-body {
						.input-group-text {
							border-color: $dark-card-border;
						}

						.form-select,
						.form-control {
							color: rgba($light-all-font-color, 0.3);
						}

						.form-label {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}

		// forms
		.form-control[type=file]:not(:disabled):not([readonly]) {
			color: $light-all-font-color;
			background-color: $dark-body-background;
			border-color: $dark-card-border;
		}

		.custom-input {

			.form-control,
			.form-select {
				background-color: $dark-body-background;
				border-color: $dark-card-border;
				color: $dark-all-font-color;
			}
		}

		.vertical-main-wizard {
			.header-vertical-wizard {
				.nav-link {

					.vertical-wizard {
						.stroke-icon-wizard {
							border-color: $dark-card-border;

							i {
								color: $dark-all-font-color;
							}
						}
					}

					&:active,
					&.active {
						.vertical-wizard {
							.vertical-wizard-content {
								h6 {
									color: $dark-small-font-color;
								}

							}
						}
					}
				}
			}

			.accordion {
				.accordion-item {

					.accordion-button {
						&.collapsed {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}

		// Dashboard Datatables
		.default-dashboard,
		.dashboard-2,
		.dashboard-3,
		.dashboard-4,
		.dashboard-5,
		.dashboard-6,
		.dashboard-7,
		.dashboard-09,
		.dashboard-10,
		.dashboard-11,
		.dashboard-12,
		.dashboard-13,
		.main-scope-project,
		.e-category,
		.list-product-view,
		.seller-details-wrapper,
		.common-order-history,
		.common-cart,
		.role-permission-wrapper,
		.user-list-wrapper,
		.manage-review-wrapper,
		.candidate-wrapper,
		.product-report-wrapper,
		.sale-report-wrapper,
		.sale-return-wrapper,
		.customer-order-wrapper,
		.main-tasks,
		.subscribed-user,
		.api-manage {
			div.dt-container {
				.dt-layout-row {
					.dt-length {
						.dt-input {
							background-color: $dark-body-background;
							color: $dark-all-font-color;
							border-color: $dark-card-border;
						}

						.dt-input {
							&:focus-visible {
								outline: $transparent-color;
							}
						}
					}

					.dataTable {
						input {
							&.dt-select-checkbox {
								&:indeterminate::after {
									background-color: rgba($white, 0.8);
								}
							}
						}
					}

					tr {
						&.selected {
							td {
								box-shadow: inset 0 0 0 9999px $dark-card-inbox;
							}

							a {
								color: rgba($dark-all-font-color, 0.4);
							}
						}
					}

					.dt-layout-cell {
						.dt-info {
							color: $dark-all-font-color;
						}
					}

					.dt-search {
						label {
							color: $dark-all-font-color;
						}

						.dt-input {
							background-color: $dark-body-background;
							color: $dark-all-font-color;
							outline: unset;
						}
					}

					.dt-paging {
						.dt-paging-button {
							background-color: $dark-body-background;

							&.current {
								background-color: var(--theme-default);
							}

							&.disabled {
								background-color: $dark-body-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			table {
				tbody {
					tr {
						td {

							.square-white {
								box-shadow: unset;
								background-color: $dark-body-background;

								svg {
									fill: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}
				}
			}
		}

		.product-report-wrapper,
		.sale-report-wrapper,
		.sale-return-wrapper,
		.customer-order-wrapper {
			.flatpickr-input {
				span {
					border-color: $dark-card-border;
					color: $dark-all-font-color;
				}

				#rangeButtons {
					box-shadow: 0px 0px 28px 6px $dark-body-background;

					button {
						background-color: $dark-body-background;
						color: rgba($dark-all-font-color, 0.4);

						&:hover {
							color: var(--theme-default);
						}

						&.active {
							background-color: var(--theme-default);
							color: $white;
						}
					}
				}
			}
		}

		// CKE editor
		.cke {
			.cke_top {
				border-color: $dark-card-border !important;
				background-color: $dark-body-background !important;
			}

			.cke_button {
				.cke_button_icon {
					filter: invert(1);
				}

				&:hover {
					.cke_button_icon {
						filter: hue-rotate(45deg);
					}
				}
			}

			.cke_combo_button {
				.cke_combo_text {
					color: $dark-all-font-color;
				}

				.cke_combo_arrow {
					border-top-color: $dark-all-font-color;
				}

				&:hover {
					.cke_combo_text {
						color: $dark-body-background;
					}

					.cke_combo_arrow {
						border-top-color: $dark-body-background;
					}
				}
			}
		}

		// Cusomer order report
		.customer-order-wrapper {
			.customer-order-report {
				.recent-table {
					table {

						thead,
						tbody {
							tr {

								th,
								td {
									.customer-details {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// Subscribed User 
		.subscribed-user-wrapper {
			.recent-table {
				table {

					thead,
					tbody {
						tr {

							th,
							td {
								.square-white {
									box-shadow: 0px 0px 28px 6px $dark-body-background;

									i {
										color: $dark-all-font-color;
									}
								}
							}
						}
					}
				}
			}
		}

		// API Management 
		.api-management {
			.recent-table {
				table {

					thead,
					tbody {
						tr {

							th,
							td {
								.square-l-white {
									background-color: $dark-body-background;

									i {
										color: $light-all-font-color;
									}
								}
							}
						}
					}
				}
			}
		}

		// Roles and permissions 
		.role-permission-wrapper {
			.permission-form {
				ul {
					li {
						.form-check {
							.form-check-input {
								&:valid~.form-check-label {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}
				}
			}
		}

		// User-list page
		.user-list-wrapper {
			.user-list-table {
				a {
					color: $dark-all-font-color;
				}
			}
		}

		// Manage review 
		.manage-review-wrapper {
			.bootstrap-select {
				.dropdown-toggle {
					color: rgba($dark-all-font-color, 0.4) !important;
				}
			}

			.manage-review {
				.recent-table {
					table {

						thead,
						tbody {
							tr {

								th,
								td {
									.common-f-start {
										.user-details {
											p {
												color: rgba($dark-all-font-color, 0.4);
											}
										}
									}

									.customer-review {
										span {
											color: rgba($dark-all-font-color, 0.4);
										}
									}

									[class*="square-"] {
										box-shadow: 0px 0px 28px 6px $dark-body-background;
									}
								}

								&.dt-hasChild {
									~ {
										tr[data-dt-row] {
											tbody {
												background-color: $dark-body-background;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// Advance Init table
		td.highlight {
			background-color: $dark-datatable-odd !important;
		}

		// JS Grid table
		.basic_table {
			&.jsgrid {
				.jsgrid-table {
					.jsgrid-edit-row {
						.jsgrid-cell {
							background: $dark-editor-document;

							input {
								background-color: $dark-datatable-sorting;
								color: $white;
								border: $transparent-color;

								&:focus,
								&:focus-visible {
									outline-color: $dark-card-border;
								}
							}
						}
					}
				}
			}
		}

		// Base-input 
		.card-wrapper,
		.dark-field {
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-card-border;
				color: $dark-all-font-color;
			}
		}

		.form-control-sm {
			background-color: $dark-body-background;
			border-color: $dark-card-border;
		}

		.dark-inputs {
			.form-control {
				&.btn-square {
					background-color: $dark-body-background;
					border-color: $dark-card-border;
				}

				&.btn-pill {
					background-color: $dark-body-background;
					border-color: $dark-card-border;
				}

				&.input-air-primary {
					background-color: $dark-body-background;
					border-color: $dark-card-border;
				}
			}
		}

		.horizontal-wizard-wrapper {
			.main-horizontal-header {
				.horizontal-options {
					.nav-link {
						.horizontal-wizard {
							.stroke-icon-wizard {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		// Dashboard-3
		.default-inline-calender {
			.main-inline-calender {
				.form-control {
					display: none;
				}

				.flatpickr-calendar {
					box-shadow: unset;

					.flatpickr-months {
						.flatpickr-monthDropdown-months {
							option {
								background-color: $dark-body-background;
							}
						}
					}
				}
			}
		}

		// Touchspin
		.touchspin-wrapper,
		.pre-post-touchspin {
			input[type="number"] {
				&.input-touchspin {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
			}
		}

		// unique checkbox checked
		.variation-box {
			.selection-box {
				input {
					&[type=checkbox]:checked~div {
						color: $bg-dark-color;
					}
				}
			}
		}

		// Datepicker
		.flatpickr-calendar {
			box-shadow: unset;

			&.arrowTop {
				&::before {
					border-bottom-color: $dark-body-background;
				}

				&::after {
					border-bottom-color: $dark-body-background;
				}
			}

			&.arrowBottom {
				&::before {
					border-top-color: $dark-body-background;
				}

				&::after {
					border-top-color: $dark-body-background;
				}
			}

			&.animate {
				background-color: $dark-body-background;
			}

			.flatpickr-months {

				.flatpickr-prev-month,
				.flatpickr-next-month {
					fill: $dark-all-font-color;
				}

				.flatpickr-monthDropdown-months {
					color: $dark-all-font-color;

					.flatpickr-monthDropdown-month {
						background-color: $dark-body-background;
					}
				}

				.flatpickr-current-month {
					color: $dark-all-font-color;
				}
			}

			.flatpickr-innerContainer {
				.flatpickr-days {
					.flatpickr-day {
						&.inRange {
							background: $dark-card-inbox;
							border-color: $dark-card-inbox;
							box-shadow: -5px 0 0 $dark-card-inbox, 5px 0 0 $dark-card-inbox;
						}
					}
				}
			}

			.flatpickr-weekday {
				color: rgba($dark-all-font-color, 0.7);
			}

			.flatpickr-day {
				color: $dark-all-font-color;

				&.prevMonthDay {
					color: $light-all-font-color;
				}

				&.nextMonthDay {
					color: $light-all-font-color;
				}

				&:hover {
					background: $dark-card-inbox;
				}
			}

			.flatpickr-time {
				border-top-color: $dark-card-border;

				input {
					color: $dark-all-font-color;
				}

				.flatpickr-am-pm {
					color: $light-all-font-color;
					background: $dark-body-background;

					&:hover {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.flatpickr-time {
			border: 1px solid $dark-card-inbox;

			input {
				background: $dark-datatable-sorting-even;
			}
		}

		.main-inline-calender {
			&.input-group {
				.flatpickr-rContainer {
					.flatpickr-days {
						.dayContainer {
							.flatpickr-day {
								&:hover {
									background-color: $dark-card-background;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		// Select2 
		.tagify__dropdown__item--active {
			background-color: $dark-body-background;
		}

		.tagify__input {
			color: $dark-small-font-color;

			&::before {
				color: $dark-small-font-color;
			}

			&:focus {
				&:empty::before {
					color: $light-all-font-color;
				}
			}
		}

		.tagify {
			border-color: $dark-card-border;
			--tags-focus-border-color: #374558;

			.tagify__tag__removeBtn {
				color: $dark-all-font-color;
			}
		}

		.tagify__tag {
			--tag-bg: var(--light-bg);
			--tag-hover: var(--light-bg);

			>div {
				color: $dark-all-font-color;
			}

			&:hover {
				--tag-hover: var(--light-bg);
			}
		}

		.international-num {
			input {
				border-color: $dark-card-border;
				background-color: $dark-card-background;
				color: $dark-all-font-color;

				&::placeholder {
					color: rgba($dark-all-font-color, 0.4);
				}
			}

			.results {
				ul {
					border: 1px solid $dark-card-border;
				}
			}

			.iti--allow-dropdown,
			input {
				&:focus {
					outline: unset;
				}
			}
		}

		.selected-box {
			color: $dark-all-font-color;
		}

		.iti__country-list {
			background-color: $dark-card-background;
		}

		.select-box {
			.selection-option {
				&:hover {
					background-color: $dark-datatable-sorting-even;
				}
			}

			.options-container.active {

				~ {
					.search-box {
						input {
							background-color: $dark-all-font-color;
						}
					}
				}
			}
		}


		// product-list
		.list-product-view {
			.list-product {
				.recent-table {
					table {

						thead,
						tbody {
							tr {

								th,
								td {
									.product-action {
										.square-white {
											box-shadow: unset;
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// product grid
		.product-filter {
			.checkbox-animated {
				.form-check {
					.form-check-label {
						.number {
							color: rgba($dark-all-font-color, 0.4);
						}
					}
				}
			}
		}

		.feature-products {
			.filter-toggle {
				background-color: $dark-card-background;
			}
		}

		.product-wrapper {
			.product-grid {
				.form-select {
					background-color: $dark-card-background;
					background-blend-mode: screen;
				}

				.product-wrapper-grid {
					.product-box {
						&:hover {
							ul {
								li {
									background-color: $transparent-color;
								}
							}
						}
					}

					.modal {
						.modal-header {
							.btn-close {
								@media (max-width:991px) {
									filter: unset;
								}
							}

							.product-box {
								.product-details {
									.product-size {
										ul {
											li {
												background-color: rgba($primary-color, 0.1);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// order histroy
		.common-order-history {
			.order-history-wrapper {
				.recent-table {
					table {
						tbody {
							.square-white {
								box-shadow: unset;
								background-color: $dark-body-background;

								svg {
									fill: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.track-order-details {
			#order-status-timeline {
				.main-status-line {
					ul {
						li {
							.order-process {
								color: rgba($dark-all-font-color, 0.4);
								border-color: $dark-card-border;

								&.active {
									color: rgba($dark-all-font-color, 0.8);
								}
							}

							h6 {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}

		.tracking-total {
			li {

				h6,
				span {
					color: $dark-all-font-color;
				}

				&:last-child {
					border-color: $dark-card-border;
				}
			}
		}

		.order-details-product {
			table {
				tbody {
					ul {
						li {
							&:last-child {

								p,
								span {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}
				}
			}
		}

		.customer-details {
			h6 {
				color: $dark-all-font-color;
			}

			span {
				color: rgba($dark-all-font-color, 0.4);
			}
		}

		// pricing
		.pricingtable {
			box-shadow: 0 0 40px $dark-body-background;
		}

		.pricing-simple {
			.pricing-content {
				li {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		// Checkout
		.checkout {
			.checkout-cart {
				form {
					.form-control {
						&::placeholder {
							color: rgba($white, 0.4);
						}
					}
				}
			}
		}

		.shipping-form {
			.checkout-cart {
				.basic-wizard {
					.shipping-wizard {
						.shipping-address {
							span {
								strong {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}

		.summery-contain {
			border-bottom-color: $dark-card-border;

			li {
				border-bottom-color: $light-all-font-color;

				h6 {
					color: rgba($dark-all-font-color, 0.4);
				}
			}
		}

		.summary-total {
			li {
				h6 {
					color: $dark-all-font-color;
				}

				&:last-child {
					border-top-color: $dark-card-border;
				}
			}
		}


		// Wishlist
		.wishlist-box {
			.wishlist-footer {
				.brand-name {
					color: rgba($dark-all-font-color, 0.4);
				}

				.price {
					del {
						color: rgba($dark-all-font-color, 0.4);
					}
				}
			}

			.wishlist-image {
				.wishlist-close-btn {
					.btn {
						box-shadow: 0 3px 3px rgba($light-all-font-color, 0.1) !important;
					}
				}
			}
		}

		// candidates 
		.candidate-wrapper {
			.candidates-box {
				.candidate-desc {
					li {
						p {
							color: rgba($dark-all-font-color, 0.4);
						}

						span {
							color: $dark-small-font-color;
						}
					}
				}

				.common-f-start {
					.attachment {
						background-color: $dark-body-background;
						color: rgba($dark-all-font-color, 0.4);
					}
				}

				.educations {
					p {
						color: rgba($dark-all-font-color, 0.4);
					}
				}

				.candidate-skill {
					p {
						color: rgba($dark-all-font-color, 0.4);
					}

					span:not(.badge) {
						color: $dark-all-font-color;
					}
				}
			}
		}

		// Buttons 
		[class*="button-light-"] {
			color: $dark-all-font-color;
		}

		.button--winona {
			&:hover {
				background-color: rgba($light-primary, 0.08);
			}
		}

		// Cart
		.cart-buttons {
			li {
				.btn {
					&.btn-hover-effect {
						background-color: $dark-card-inbox;
						color: $dark-all-font-color;
					}
				}
			}
		}

		.shopping-cart-table {
			.recent-table {
				div.dt-container {
					.table {

						thead,
						tbody {

							tr,
							td {
								.product-names {

									p,
									span {
										color: rgba($dark-all-font-color, 0.4);
									}
								}

								.product-action {
									.square-white {
										box-shadow: unset;
										background-color: $dark-body-background;

										&:first-child {
											svg {
												filter: opacity(0.3);
											}
										}
									}
								}
							}
						}

						thead {
							tr {
								th {
									&:last-child {
										span {
											svg {
												fill: $dark-small-font-color;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// Tables
		.table-hover {
			>tbody {
				>tr:hover {
					--bs-table-accent-bg: #1d1e26;
				}
			}
		}

		// Authentication

		.main-qr-code {
			.modal-toggle-wrapper {
				.modal-img {
					.qr-content {
						.alert {
							background-color: $dark-body-background;
							border-color: $dark-card-inbox;

							i {
								color: $dark-all-font-color;
							}

							div {
								color: $dark-all-font-color;
							}
						}
					}
				}

				form {
					.form-control {
						background-color: $dark-body-background;
						border-color: $dark-card-inbox;

					}
				}
			}
		}

		.authentication-options {
			.radio-wrapper {
				li {
					.form-check-label {
						span {
							>span {
								color: $dark-small-font-color;
							}
						}
					}
				}
			}
		}

		// Typeahead
		.typeahead-wrapper {
			.theme-form {
				.form-control {
					background-color: $dark-body-background !important;
					border-color: $dark-body-background !important;
				}
			}
		}

		// Mail box
		.email-main-wrapper {
			.email-right-aside {
				.freeze-ui {
					background-color: rgba($dark-body-background, 0.8);
				}
			}

			.email-app-sidebar {
				.nav-pills {
					.nav-item {
						ul {
							li {
								a {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}

					.nav-link {
						color: rgba($dark-all-font-color, 0.4);

						&.active,
						&:active {
							svg {
								stroke: $dark-all-font-color;
							}
						}

						svg {
							stroke: rgba($dark-all-font-color, 0.4);
						}
					}
				}
			}
		}

		.compose-modal {
			form {
				.form-control {
					background-color: $dark-body-background;
					border-color: $dark-card-border;
					color: $dark-small-font-color;
				}
			}
		}

		.toolbar-box {
			border-color: $dark-card-border;

			.ql-snow {
				.ql-stroke {
					stroke: $dark-small-font-color;
				}

				.ql-tooltip {
					background-color: $dark-body-background;
					box-shadow: unset;
					border: 0;

					&.ql-editing {
						input[type=text] {
							background-color: $dark-card-background;
							border: 0;
							color: $dark-small-font-color;
						}
					}

				}

				a {
					color: $light-all-font-color;
				}
			}

			.ql-editor.ql-blank::before {
				color: $light-all-font-color;
			}
		}

		.email-body {
			.mail-body-wrapper {
				ul {
					li {
						.inbox-message {
							.email-data {
								span {
									color: rgba($dark-all-font-color, 0.4);
								}
							}

							.email-options {
								i {
									color: rgba($dark-all-font-color, 0.4);
								}
							}
						}
					}
				}

				.important-mail {
					stroke: rgba($dark-all-font-color, 0.4);
				}

				.user-mail-wrapper {
					.user-title {
						.inbox-options {
							svg {
								stroke: rgba($dark-all-font-color, 0.4);
							}
						}

						.inbox-security {
							p {
								color: rgba($dark-all-font-color, 0.4);
							}
						}
					}
				}
			}

			.mail-header-wrapper {
				.mail-body {
					.mail-search {
						.form-control {
							background-color: $dark-body-background;
							border: 0;
							color: $dark-all-font-color;

							&::placeholder {
								color: $light-all-font-color;
							}
						}


						i {
							color: $light-all-font-color;
						}
					}

					.dropdown-menu {
						.dropdown-item {
							background: $dark-body-background;
							opacity: 1;
							border: 0;
							color: $dark-all-font-color;

							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
				}

				.mail-body1 {
					svg {
						stroke: rgba($dark-all-font-color, 0.6);
					}
				}
			}
		}

		.inbox-options {
			.light-square {
				&.btn-group {
					.dropdown-menu {
						background: $dark-editor-document;

						.dropdown-item {
							&:hover {
								background-color: $light-all-font-color;
								color: $dark-small-font-color;
							}
						}
					}
				}
			}
		}

		.mail-filters {
			svg {
				stroke: rgba($dark-all-font-color, 0.4);
			}
		}

		.inbox-security {
			background-color: $dark-body-background;
		}

		// Add-product 
		.flatpicker-calender {
			&.product-date {
				.form-control {
					&.flatpickr-input {
						border: unset;
						background-color: $dark-body-background;
					}
				}
			}
		}

		.price-wrapper {
			.radio-wrapper {
				li {
					.form-check-input {
						border: 1px solid $dark-card-border;

						~.form-check-label {
							span {
								color: $dark-small-font-color;

							}
						}
					}
				}
			}
		}

		.product-buttons {
			.btn {

				&:hover,
				&:active {
					color: rgba($dark-all-font-color, 0.8);
				}

				svg {
					stroke: rgba($dark-all-font-color, 0.8);
				}
			}
		}

		.ql-formats {
			.ql-picker-label {
				&::before {
					color: $dark-all-font-color;
				}
			}
		}

		// Category page
		.e-category {
			.form-label {
				color: $dark-all-font-color;
			}

			.form-control {
				&::placeholder {
					color: $light-all-font-color;
				}
			}

			.main-divider {
				.divider-body {
					h6 {
						color: $dark-all-font-color;
					}

					&::before {
						border-color: $dark-card-border;
					}
				}
			}
		}

		// Add product 
		.sidebar-left-wrapper {
			.sidebar-left-icons {
				.nav-item {
					.nav-link {
						.nav-rounded {
							box-shadow: inset 0px 9px 20px 0px rgba($dark-all-font-color, 0.1);
						}

						.product-icons {
							svg {
								stroke: $dark-all-font-color;
							}
						}
					}

					&::before {
						border-color: rgba($dark-all-font-color, 0.15);
					}
				}
			}
		}

		.common-form {
			.product-buttons {
				.btn {
					border-color: $dark-card-border;
				}
			}
		}

		// list-product
		.list-product {
			&.list-category {
				.square-white {
					svg {
						fill: $dark-all-font-color;
					}
				}
			}

			.datatable-pagination {
				.datatable-active {
					.datatable-pagination-list-item-link {
						background-color: var(--theme-default);
						color: var(--white);
					}
				}

				.datatable-pagination-list-item-link {
					background-color: $dark-body-background;
					color: $dark-small-font-color;
				}
			}

			.datatable-input {
				&:focus {
					outline: unset;
				}
			}

			.datatable-selector {

				color: $dark-all-font-color;
				background-color: $dark-body-background;
			}
		}

		// tables
		.table {
			--bs-table-bg: trasparnt;

			caption {
				color: rgba($dark-all-font-color, 0.4);
			}
		}

		// Grid
		.grid-footer {
			table {
				tbody {
					tr {
						color: $dark-all-font-color;
					}
				}
			}
		}

		// Tree view
		.tree-view {
			.light-background {
				background-color: $dark-body-background;
			}

			.list-light-primary {
				background-color: rgba($primary-color, 0.2);
			}

			.nested-1 {
				background-color: rgba($primary-color, 0.03);
			}

			.nested-2 {
				background-color: rgba($primary-color, 0.05);
			}

			.nested-3 {
				background-color: rgba($primary-color, 0.07);
			}

			.nested-4 {
				background-color: rgba($primary-color, 0.09);
			}
		}

		// private-chat
		.contact-edit {
			svg {
				stroke: $dark-all-font-color;
			}
		}

		.left-sidebar-wrapper {
			.left-sidebar-chat {
				.input-group {
					.input-group-text {
						background-color: $dark-body-background;
					}

					.form-control {
						background-color: $dark-body-background;
						border: unset;
						color: $dark-all-font-color;

						&::placeholder {
							color: $dark-all-font-color;

						}
					}
				}
			}

			.advance-options {
				.chats-user {
					span {
						color: $dark-all-font-color;
					}
				}
			}
		}

		.search-contacts {
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-card-border;
				color: $light-all-font-color;

				&::placeholder {
					color: $dark-small-font-color;
				}
			}
		}

		.right-sidebar-title {
			span {
				color: $dark-all-font-color;
			}
		}

		.msger-inputarea {
			.chat-icon {
				div {
					background-color: $dark-body-background;
					opacity: 1;
				}
			}
		}

		.right-sidebar-Chats {
			.msger-inputarea {
				background: $dark-body-background;
			}

			.msger-input {
				&:focus {
					background-color: $dark-body-background;
				}
			}
		}

		.left-msg {
			.msg-bubble {
				background: $dark-body-background;
			}
		}

		.fg-emoji-picker-search {

			input,
			.fg-emoji-picker-search-icon {
				background-color: $dark-card-background;
				color: $dark-all-font-color;
			}
		}

		.fg-emoji-picker-category-title {
			background-color: $dark-body-background;
			border-bottom: 1px solid $dark-card-border;
		}

		.fg-emoji-container {

			.fg-emoji-nav {
				background-color: $dark-body-background;

				ul {
					border-bottom-color: $dark-card-border;
				}

				li {

					&.emoji-picker-nav-active,
					&:hover {
						a {
							background-color: $dark-card-inbox;

							svg {
								fill: $white;
							}
						}
					}
				}
			}

			.fg-emoji-list {
				background-color: $dark-body-background;

				li {
					a {
						background-color: $dark-body-background;
					}
				}
			}
		}

		// Companies
		.main-companies {
			.filter-companies {
				.accordion-item {
					background-color: unset;

					.product-filter {
						label {
							color: rgba($dark-all-font-color, 0.4);
						}
					}

					.accordion-header {
						.accordion-button {
							&.collapsed {
								background-color: $dark-body-background;
								color: $dark-all-font-color;
								transition: all 0.4s ease;
							}
						}
					}
				}

				.accordion-body {
					background-color: $dark-card-background;
				}
			}
		}

		.company-box {
			.company-name {
				.company-desc {
					border-top-color: $dark-card-border;
				}
			}
		}

		// Add-post 
		.add-post {
			form {

				label,
				.col-form-label {
					color: $dark-small-font-color;
				}

				.form-control {
					background-color: $dark-body-background;
					color: $light-all-font-color;
					border: unset;

					&::placeholder {
						color: $light-all-font-color;
					}
				}
			}
		}

		.ql-container {
			&.ql-snow {
				.ql-editor {
					color: $light-all-font-color;
				}
			}
		}

		&.box-layout {
			.page-wrapper {

				.page-header {
					background-color: $transparent-color;

					.header-wrapper {
						border: 1px solid $dark-card-background;
					}
				}

				.page-body-wrapper {
					background-color: $transparent-color;
					box-shadow: 4px 11px 25px $dark-card-inbox;
				}

				&.modern-type {
					.page-body-wrapper {
						background-color: $dark-body-background;
					}
				}
			}
		}
	}

	&.dark-sidebar {
		.toggle-sidebar {
			svg {
				stroke: var(--theme-default);
			}
		}

		.page-wrapper {
			.sidebar-main-title {
				p {
					color: $dark-all-font-color;
				}
			}

			&.compact-wrapper {
				.page-body-wrapper {
					div.sidebar-wrapper {
						background-color: $dark-card-background;

						.sidebar-main {
							.sidebar-links {
								li {
									a {
										span {
											color: $dark-all-font-color;
										}

										svg {
											stroke: $dark-all-font-color !important;
										}
									}
								}

								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													.sidebar-link {
														&.active {
															background-color: rgba($primary-color, 0.2);

															svg {
																color: var(--theme-default);
																stroke: var(--theme-default);
															}
														}
													}

													.sidebar-submenu {
														li {
															a {
																color: $dark-all-font-color;
															}
														}
													}
												}
											}
										}
									}
								}

								.sidebar-list {
									ul.sidebar-submenu {
										li {
											a {
												span {
													color: $dark-all-font-color;
												}

												&.active {
													color: var(--theme-default);
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}

			&.horizontal-wrapper {
				.page-body-wrapper {
					.sidebar-wrapper {
						background: $dark-card-background;

						.sidebar-main {
							background-color: $dark-card-background;

							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													>a {
														color: $dark-all-font-color;

														svg {
															&.stroke-icon {
																stroke: rgba($dark-all-font-color, 0.6);
															}

															&.fill-icon {
																fill: rgba($dark-all-font-color, 0.6);
															}
														}
													}

													.sidebar-submenu {
														background: $dark-card-background;

														li {
															a {
																color: $dark-all-font-color;

																&.active,
																&:hover {
																	color: var(--theme-default);
																}
															}

															.sidebar-submenu {
																background: $dark-card-background;
															}
														}
													}
												}
											}
										}
									}
								}

							}

							.left-arrow,
							.right-arrow {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}

			&.compact-sidebar {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							>div {
								background: $dark-card-background;
							}

							.sidebar-main {
								.sidebar-links {
									li {

										.sidebar-title {
											border-bottom: 1px solid $dark-card-border;
										}

										a {
											span {
												color: $dark-all-font-color;
											}

											svg {
												stroke: $dark-all-font-color;
											}
										}

										.sidebar-submenu {
											background-color: $dark-card-background;

											li {
												a {
													border-bottom: 1px solid $dark-card-border;
												}
											}
										}
									}

									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {

														.sidebar-link {
															&.active {
																background-color: rgba($primary-color, 0.2);

																span {
																	color: var(--theme-default);
																}

																svg {
																	color: var(--theme-default);
																	stroke: var(--theme-default);
																}
															}
														}

														.sidebar-submenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
									}

									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}

													&.active {
														color: var(--theme-default);
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
	}

	.for-dark {
		display: none;
	}

	&[class*='dark-'] {
		.for-dark {
			display: inline-block;
		}

		.for-light {
			display: none;
		}
	}
}

// =======================  Dark Body only  ======================= //
/**=====================
	4.1. Dark CSS ends
==========================**/