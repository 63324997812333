/**=====================
    2.29 Table CSS Start
==========================**/

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: var(--chart-progress-light);
}

.table {

	tbody,
	tfoot {
		tr {
			&:last-child {

				td,
				th {
					border-bottom: none;
				}
			}
		}
	}
}

.pills-component {
	width: 20%;
}

#row_create {
	td.highlight {
		font-weight: 500;
		color: $primary-color;
	}

	td.danger {
		font-weight: 500;
		color: $danger-color;
	}
}

.table {
	margin-bottom: $table-b-margin;

	> :not(:last-child) {
		> :last-child {
			>* {
				border-bottom-color: $light-gray;
			}
		}
	}

	th,
	td {
		padding: $table-padding;
	}

	&.table-primary {
		th {
			color: $white;
		}
	}

	thead.bg-primary {
		tr {
			th {
				color: $white;
			}
		}
	}

	th {
		color: $table-heading-color;
		font-weight: 500;

		&.c-light {
			color: $badge-light-color;

		}
	}

	td {
		color: $table-row-color;
	}

	[class*='bg-'] {
		color: $white;
	}

	.bg-light {
		color: $theme-body-font-color;
	}

	tfoot {
		font-weight: $table-footer-font-weight;
	}
}

.table-dark {
	th {
		color: $white;
	}
}

.table-inverse {
	color: $white;
	background-color: $table-inverse-bg-color;

	th,
	td {
		border-color: $white;
		color: $white;
	}

	&.table-bordered {
		border: 0;
	}
}

.table[class*='bg-'] {

	th,
	td {
		color: $white;
	}
}

.table-hover {
	>tbody {
		>tr:hover {
			--bs-table-accent-bg: #f6f6f6;
		}
	}

	tbody {
		tr {
			vertical-align: middle;

			&:hover {
				background-color: rgba($primary-color, 0.1);
			}

			td {
				svg {
					width: 30px;
					height: 30px;
					padding: 5px;
					border-radius: 4px;
					margin-right: 10px;

					[dir="rtl"] & {
						margin-right: unset;
						margin-left: 10px;
					}
				}
			}
		}
	}
}

// Table border-bottom removes in particular typography page
.typography-table {
	tr {
		&:last-child {
			border-bottom: none;

			td {
				border-bottom: none;
			}
		}
	}
}

.table-double {
	border-left: 4px double #dee2e6;
	border-right: 4px double #dee2e6;

	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-style: double;
		border-width: 4px;
		border-right: 0;
		border-left: 0;
	}
}

.table-dotted {
	border-left: 2px dotted #dee2e6;
	border-right: 2px dotted #dee2e6;

	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-style: dotted !important;
		border-width: 2px;
		border-right: 0;
		border-left: 0;
	}
}

.table-dashed {
	border-left: 2px dashed #dee2e6;
	border-right: 2px dashed #dee2e6;

	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-style: dashed !important;
		border-width: 2px;
		border-right: 0;
		border-left: 0;
	}

	tbody {
		tr {
			&:last-child {

				th,
				td {
					border-color: var(--chart-progress-light);
				}
			}
		}
	}
}

//Sizing class
.table-xl {

	td,
	th {
		padding: $table-xl-padding;
		font-size: $table-xl-font;
	}
}

.table-lg {

	td,
	th {
		padding: $table-lg-padding;
		font-size: $table-lg-font;
	}
}

.table-de {

	td,
	th {
		padding: $table-de-padding;
	}
}

.table-sm {

	th,
	td {
		padding: $table-sm-padding;
		font-size: $table-sm-font;
	}
}

.table-xs {

	th,
	td {
		padding: $table-xs-padding;
		font-size: $table-xs-font;
	}
}

//Table Border
.table-border-horizontal {

	tr,
	th,
	td {
		border-top: 1px solid $horizontal-border-color;
		padding: $horizontal-padding;
	}
}

.table-border-vertical {

	tr,
	th,
	td {
		border-top: 0;
		border-right: 1px solid $horizontal-border-color;
	}

	tr {
		td {
			border-bottom: 0;

			&:last-child {
				border-right: none;
			}
		}
	}

	tbody {
		tr {
			th {
				border-bottom: 0;
			}
		}
	}
}

.table-bordernone {
	td {
		border: none !important;
	}

	thead {
		th {
			border: none !important;
		}
	}

	.u-s-tb {
		padding: 11px;
	}
}

.table-striped {
	thead {
		tr {
			th {
				border-bottom: unset;
			}
		}
	}

	tbody {
		tr {
			&:nth-of-type(odd)>* {
				background-color: var(--light-background) !important;
				--bs-table-accent-bg: unset;
			}
		}
	}
}

.table-bordered {

	thead,
	tbody,
	tfoot,
	tr,
	td,
	th {
		border-color: var(--bs-border-color);
	}
}

.common-striped {
	.table {
		&.table-striped {
			&[class*='bg-'] {
				--bs-table-bg: auto;
			}
		}
	}
}

.table-components {
	.table-bordered {
		.dropdown-menu {
			&.show {
				background-color: var(--white);
			}
		}

		.dropdown-toggle {
			&::after {
				vertical-align: 2px;
			}
		}
	}

	@media (max-width:767px) {
		.table {
			tbody {
				tr {
					td {
						&:first-child {
							min-width: 180px;
						}
					}
				}
			}
		}
	}
}

.basic_table {
	.table {
		&.table-striped {
			&.table-hover {
				tr {
					&:hover {
						background-color: rgba($secondary-color, 0.2);
					}
				}
			}
		}
	}
}

.border-bottom-table {
	tbody {
		tr {
			&:last-child {

				th,
				td {
					border-bottom: unset !important;
				}
			}
		}
	}
}

.emphasis-table {
	--bs-emphasis-color-rgb: 242, 243, 247;

	.table-striped-columns>:not(caption)>tr>:nth-child(2n) {
		--bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 1);
	}

	.table-active {
		--bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 1);
	}

}

/**=====================
    2.29 Table CSS Ends
==========================**/