/**=====================
    2.21 Modal CSS start
==========================**/

.modal-toggle-wrapper {
    .form-control {
        &::placeholder {
            font-size: 13px;
        }
    }

    .modal-img {
        display: flex;
        justify-content: center;

        img {
            @include pixelstrap-size(200px, 200px);

            @media (max-width:992px) {
                width: calc(136px + (200 - 136) * ((100vw - 320px) / (992 - 320)));
                height: calc(136px + (200 - 136) * ((100vw - 320px) / (992 - 320)));
            }
        }

        li {
            img {
                @include pixelstrap-size(100px, 100px);

                @media (max-width:992px) {
                    width: calc(75px + (100 - 75) * ((100vw - 320px) / (992 - 320)));
                    height: calc(75px + (100 - 75) * ((100vw - 320px) / (992 - 320)));
                }
            }
        }

        li+li {
            margin-left: -39px;

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: -39px;
            }
        }
    }

    button {
        svg {
            @include pixelstrap-size(16px, 16px);
        }
    }
}

.grid-space {
    display: block;
    text-align: center;
    padding: 12px;
    margin-bottom: 12px;
    font-weight: 500;
}

.large-modal-header,
.large-modal-body {
    display: flex;
    align-items: center;
    color: $dark-color;

    svg {
        @include pixelstrap-size(20px, 20px);

        [dir="rtl"] & {
            transform: scaleX(-1);
        }
    }
}

.modal-padding-space {
    padding-left: 20px;
}

.svg-modal {
    @include pixelstrap-size(15px, 15px);
    stroke: $secondary-color;
    vertical-align: middle;

    [dir="rtl"] & {
        transform: scaleX(-1);
    }
}

.card-wrapper {
    .modal-heading {
        h5 {
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1400 - 320)));
        }
    }

    .balance-modal {
        @media (min-width:750px) and (max-width:1200px) {
            display: flex;
            flex-direction: column;
        }
    }
}

.modal-footer {
    .btn {
        margin: 0 4px;
    }
}

.modal-content {
    .modal-header {
        .btn-close {
            &:focus {
                box-shadow: unset;
            }
        }
    }
}

.modal-details {
    h6 {
        padding-bottom: 12px;
    }

    .web-content {
        margin-bottom: 16px;

        .d-flex {
            p {
                padding-bottom: 8px;
            }

            &:last-child {
                p {
                    padding-bottom: 0;
                }
            }

        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.modal {
    .modal-body {
        .card {
            box-shadow: none !important;
        }
    }
}

.modal-dialog {
    .modal-content {
        .modal-body {
            p {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}

// rangeslider
.irs-from,
.irs-to,
.irs-single {
    background: var(--theme-default);

    &:after {
        border-top-color: var(--theme-default);
    }
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
    background-color: var(--theme-default);
}

// sweetalert css
.swal-button {
    background-color: var(--theme-default);

    &:active {
        background-color: darken($primary-color, 5%);
    }
}

.swal-button--cancel {
    background-color: #EFEFEE !important;
}

.clipboaard-container {
    h6 {
        line-height: 1.6;
        font-size: $paragraph-font-size;
    }
}

fieldset {

    .bootstrap-touchspin-postfix,
    .bootstrap-touchspin-prefix {
        border-radius: 0;
        padding: .4rem .75rem;
    }

    .input-group-lg {
        .touchspin {
            height: 50px;
        }
    }
}

.f1 .f1-steps .f1-step .f1-step-icon {
    padding: 12px;
}

.form-inline .form-group {
    display: flex;
}

.input-group-text {
    i {
        line-height: 1.5;
    }
}

// animated modal css
.animated-modal {
    .form-select {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}

.card-wrapper {
    padding: 14px;

    dl {
        dt {
            font-weight: 500;
        }
    }
}

.custom-alert {
    .card-wrapper {
        .cuba-demo-img {
            .dot-group {
                display: flex;
                gap: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
                padding: 15px 10px 0;

                li {
                    width: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
                    height: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
                    background-color: var(--f-lighter);
                    border-radius: 100%;
                    opacity: 0.6;
                }
            }
        }
    }
}

// Animated Modal
.custom-col-2 {
    @media (max-width:768px) and (min-width:709px) {
        width: 9.333333%;
        flex: 0 0 auto;
    }

    @media (max-width:708px) and (min-width:582px) {
        width: 10.333333%;
        flex: 0 0 auto;
    }

    @media (max-width:581px) and (min-width:575px) {
        width: 11.333333%;
        flex: 0 0 auto;
    }

    @media (max-width:574px) and (min-width:481px) {
        width: 12.333333%;
        flex: 0 0 auto;
    }

    @media (max-width:480px) and (min-width:410px) {
        width: 14.333333%;
        flex: 0 0 auto;
    }

    @media (max-width:409px) and (min-width:364px) {
        width: 16.333333%;
        flex: 0 0 auto;
    }

    @media (max-width:363px) {
        width: 100%;
        flex: 0 0 auto;
    }
}

/**=====================
    2.21 Modal CSS end
==========================**/