/**=====================
    3.53 Social-app CSS start
==========================**/
.user-profile {
  .hovercard {
    .info {
      &.market-tabs {
        padding: 20px 0 0 0;

      }
    }
  }

  .card-footer {
    >div {
      h3 {
        font-size: 16px;
      }
    }
  }
}

.market-tabs {
  .border-tab.nav-tabs {
    .nav-item {
      .nav-link.active {
        border-bottom: none !important;
        background-color: $white;
      }
    }
  }

  .nav {
    justify-content: center;
    gap: 12px;

    .nav-item {
      width: 100%;

      .nav-link {
        height: 81px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 30px;
        font-weight: 500;

        @media (max-width:767px) {
          height: 50px;
          padding: 14px 25px;
        }
      }

      @media (max-width:767px) {
        width: auto;
      }
    }
  }
}

.tabs-social {
  border-bottom: none;
  margin-block: 20px !important;

  li {
    a {
      font-weight: 600;

      svg {
        circle {
          color: $light-text;
        }
      }
    }
  }
}

.default-according {
  &.style-1 {
    .social-network {
      button {
        width: 44px;
        height: 44px;
        border-radius: 4px;
        padding: 0;

        i {
          margin-right: 0;

          [dir="rtl"] & {
            margin-right: unset;
            margin-left: 0;
          }
        }
      }

      span {
        margin-bottom: 0.5rem;
        display: block;
      }
    }
  }
}

.social-list {
  .d-flex {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    .flex-grow-1 {
      a {
        color: $light-text;
      }

      span {
        font-weight: 600;
      }

      &:hover {
        a {
          color: var(--theme-default);
        }
      }
    }
  }
}

.photos {
  ul {
    li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 70px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

.new-users-social {
  margin-bottom: 30px;

  svg {
    cursor: pointer;
  }

  .d-flex {
    align-items: center;

    img {
      width: 58px;
    }

    .flex-grow-1 {
      margin-top: 10px;
    }
  }

  svg {
    circle {
      color: $light-text;
    }
  }
}

.like-content {
  span {
    i {
      font-size: 14px;
    }
  }
}

.timeline-content {
  p {
    margin-top: 30px;
    color: rgba($badge-light-color, 0.6);
  }

  .comment-number {
    i {
      color: $light-text;
      margin: 0 6px;
    }

    +.comment-number {
      padding-right: 12px;
    }
  }

  .comments-box {
    margin-top: 30px;

    .input-group {
      margin-top: 6px;
      background-color: rgba($light-semi-gray, 0.4);
      align-items: center;


      .input-txt-bx {
        background-color: rgba($light-semi-gray, 0.1);
        border: none;

        &::placeholder {
          color: $badge-light-color;
        }
      }

      .input-group-append {
        .btn {
          &:active {
            border-color: $transparent-color;
          }

          i {
            font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            color: $light-text;
          }
        }
      }

      .btn {
        padding: 0 28px 0 0;
        vertical-align: -3px;
      }
    }
  }
}

.social-chat {
  margin-top: 30px;

  span {
    span {
      font-weight: normal;
      color: rgba($badge-light-color, 0.6);
      font-size: 12px;
    }
  }

  .flex-grow-1 {
    padding: 20px;
    border: 1px solid rgba($badge-light-color, 0.2);
    position: relative;

    p {
      margin-top: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 19px;
      right: 100%;
      border: 7px solid transparent;
      border-right: 7px solid $semi-dark;
    }

    &:after {
      content: '';
      position: absolute;
      top: 19px;
      right: 100%;
      border: 7px solid transparent;
      border-right: 7px solid $white;
    }
  }

  .your-msg,
  .other-msg {
    margin-bottom: 30px;
  }

  .other-msg {
    margin-left: 40px;
  }
}

.socialprofile {
  span {
    color: $light-text;
  }

  .social-btngroup {
    margin: 20px 0;
  }

  .likes-profile {
    h5 {
      span {
        color: $theme-body-font-color;
      }
    }
  }

  .social-group {
    margin-top: 20px;

    ul {
      li {
        &:nth-child(4) {
          margin-left: -22px;
        }
      }
    }
  }
}

.social-status {
  form {
    .form-group {
      .form-control-social {
        border: 1px solid $light-color;
        padding-left: 15px;
        margin-bottom: 30px;
      }
    }
  }

  .d-flex {
    position: relative;
    margin-bottom: 30px;
    align-items: center;

    @media (max-width:1660px) and (min-width:1200px) {
      margin-bottom: calc(20px + (30 - 20) * ((100vw - 1200px) / (1920 - 1200)));
    }

    &:last-child {
      margin-bottom: 0;
    }

    .social-status {
      content: '';
      position: absolute;
      @include pixelstrap-size(12px, 12px);
      background-color: $white;
      bottom: 0;
      left: 35px;
      border-radius: 100%;
    }

    .social-online {
      border: 3px solid $success-color;
    }

    .social-busy {
      border: 3px solid $warning-color;
    }

    .social-offline {
      border: 3px solid $semi-dark;
    }

    .flex-grow-1 {
      p {
        color: rgba($badge-light-color, 0.6);
        margin-bottom: 5px;
      }

      span {
        +span {
          color: rgba($badge-light-color, 0.6);
        }
      }

      .light-span {
        color: rgba($badge-light-color, 0.6);
      }
    }
  }
}

.social-header {
  h5 {
    span {
      &:first-child {
        line-height: 1.48;
      }

      svg {
        vertical-align: -4px;

        circle {
          color: $light-text;
        }
      }
    }
  }
}

.details-about {
  +.details-about {
    margin-top: 30px;
  }

  .your-details {
    p {
      color: rgba($badge-light-color, 0.6);
    }
  }
}

.avatar-showcase {
  .friend-pic {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  .pepole-knows {
    ul {
      li {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
        }

        .add-friend {
          h6 {
            margin-top: 3px;
          }
        }
      }
    }
  }
}

.activity-log {
  .my-activity {
    +.my-activity {
      margin-top: 30px;
    }

    p {
      margin-bottom: 5px;

      span {
        svg {
          @include pixelstrap-size(15px, 15px);
        }
      }

      @media (max-width:525px) {
        margin-bottom: 10px;
      }
    }
  }
}

.social-app-profile {
  .tab-pane {
    .social-details {
      .social-follow {
        li {
          span {
            @include pixelstrap-ellipsis;
            width: 100%;
          }
        }
      }
    }

    .faq-accordion,
    .job-accordion {
      .card-header {
        .btn {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .filter-cards-view {
        .page-notification {
          .flex-grow-1 {
            span {
              font-size: 13px;
            }
          }
        }

        .flex-grow-1 {
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .job-accordion {
      .filter-cards-view {
        span {
          font-weight: 500;
          font-size: 14px;
        }

        p {
          color: rgba($theme-body-font-color, 0.9);
        }
      }
    }
  }
}

.social-app-profile1 {
  .social-app-profile {
    .gallery-with-description {
      .caption {
        ul {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          li {
            width: 100%;
            text-align: center;

            +li {
              border-left: 1px solid $light-gray;

              [dir="rtl"] & {
                border-left: unset;
                border-right: 1px solid $light-gray;
              }
            }

            span {
              margin-left: 6px;
              color: $light-text;

              [dir="rtl"] & {
                margin-left: unset;
                margin-right: 6px;
              }
            }

            i {
              color: $light-text;
            }
          }
        }
      }
    }

    .social-friends {
      .social-profile {
        text-align: left;

        .common-social-box {
          @include pixelstrap-flex(flex, flex-start, center);
          gap: 12px;

          .social-img {
            img {
              height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
            }
          }

          .edit-icon {
            right: calc(-2px + (0 - (-2)) * ((100vw - 320px) / (1920 - 320)));
            bottom: calc(-2px + (0 - (-2)) * ((100vw - 320px) / (1920 - 320)));

            [dir="rtl"] & {
              right: unset;
              left: calc(-2px + (0 - (-2)) * ((100vw - 320px) / (1920 - 320)));
            }

            svg {
              width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
              height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            }
          }
        }

        .social-details {
          .social-follow {
            text-align: center;

            li {
              position: relative;
            }
          }
        }

        .card-social {
          gap: 13px;
          position: relative;
          z-index: 0;
          display: flex;
          align-items: center;
          margin-top: 10px;
          padding-top: 15px;

          &::before {
            content: "";
            border-top: 1px solid $background-color_4;
            width: calc(100% + 40px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
          }

          li {
            width: 100%;

            a {
              width: 100%;
              background-color: rgba($primary-color, 0.1);
              border-radius: 5px;
              @include pixelstrap-flex(flex, center, center);
              font-size: 15px;

              @media (min-width:1200px) {
                height: calc(30px + (39 - 30) * ((100vw - 1200px) / (1920 - 1200)));
              }

              @media (max-width:1199px) {
                height: calc(28px + (39 - 28) * ((100vw - 320px) / (1920 - 320)));

                i {
                  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                }
              }
            }
          }
        }

        .social-details {
          .card-social {
            gap: 22px;
          }

          .social-follow {
            text-align: center;
          }
        }

        @media (min-width:1200px) {
          .social-details {
            h5 {
              font-size: calc(16px + (18 - 16) * ((100vw - 1200px) / (1920 - 1200)));
            }
          }
        }
      }
    }

    .filter-cards-view {
      &.photos {
        ul {
          @include pixelstrap-flex(flex, flex-start, center);
          flex-wrap: wrap;
          gap: 12px;

          li {
            margin: 0;
            width: auto;

            img {
              height: 55px;
            }
          }
        }
      }
    }

    .common-user-image {
      .user-image {
        .share-icons {
          position: inherit;
          inset: 0;

          li {
            display: inline-block;
            margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

            .social-icon {
              @include pixelstrap-size(calc(32px + (50 - 32) * ((100vw - 320px) / (1920 - 320))), calc(32px + (50 - 32) * ((100vw - 320px) / (1920 - 320))));
              @include pixelstrap-flex(flex, center, center);
              text-align: center;
              vertical-align: middle;
              border-radius: 100%;

              i {
                color: $white;
                font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
                animation-duration: 2.8s;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        @media (max-width:575px) {
          .avatar {
            gap: 12px;
          }
        }
      }
    }
  }
}

@media (min-width:320px) and (max-width:1660px) {
  .social-app-profile {
    .order-xxl-i {
      order: 1;
    }

    .order-xxl-ii {
      order: 2;
    }

    .order-xxl-iii {
      order: 3;
    }

    .order-xxl-iv {
      order: 4;
    }

    .order-xxl-v {
      order: 5;
    }

    .order-xxl-vi {
      order: 5;
    }

    .order-xxl-vii {
      order: 6;
    }
  }
}

/**=====================
    3.53 Social-app CSS end
==========================**/