/**=====================
  4.2 Theme-customizer CSS Start
==========================**/
.sidebar-panel-main {
  position: fixed;
  bottom: 80px;
  z-index: 9;
  right: 30px;

  @media (max-width:575px) {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .icon-btn {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      box-shadow: 0 0 10px $primary-color;

      i {
        font-size: 18px;
        color: $white;
      }
    }

    li {
      position: relative;

      span {
        position: absolute;
        right: -35px;
        width: max-content;
        text-align: right;
        padding: 7px 10px;
        display: block;
        top: 0;
        border-radius: 5px;
        transform: scale(0);
        transition: all 0.3s ease;
        opacity: 0;
        color: var(--theme-default);
        background-color: $light-primary;
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
      }

      &:hover {
        span {
          opacity: 1;
          right: 48px;
          transform: scale(1);
          transition: all 0.3s ease;
        }

        &::after {
          opacity: 1;
          transform: scale(1);
          transition: all 0.3s ease;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        top: 12px;
        border-width: 5px 0 5px 5px;
        border-left-color: $light-primary;
        opacity: 0;
        transform: scale(0);
        color: $white;
        left: -10px;
        z-index: 2;
        transition: all 0.3s ease;
      }
    }
  }
}

.setting-sidebar,
.layout-sidebar {
  background-color: var(--white);
  width: 370px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -400px;
  z-index: 999;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  transition: all 0.3s ease;

  @media (max-width:575px) {
    display: none;
  }

  &.open {
    right: 0;
    transition: all 0.3s ease;
  }

  h4 {
    font-weight: 500;
  }

  .customizer-header {
    padding: 10px 20px;
    background: var(--theme-default);

    p {
      i {
        margin-left: 4px;
      }
    }

    .theme-title {
      display: flex;
      align-items: center;

      h4 {
        color: $white;
      }

      p {
        color: $white;
      }

      .flex-grow-1 {
        .icon-btn {
          &.btn-outline-light {
            cursor: pointer;
            transition: 1s ease all;
            color: $primary-color;
            background-color: $white;
            height: 37px;
            width: 37px;
            border-radius: 100%;
            align-items: center;
            justify-content: center;
            display: inline-flex;

            &:hover {
              transform: rotate(360deg);
            }

            i {
              font-size: 18px;
              color: var(--theme-default);
            }
          }
        }
      }
    }
  }

  .customizer-body {
    padding: 15px 25px;
    max-height: calc(100vh - 68px);
    overflow-y: scroll;

    .color-body {
      .color-img {
        position: relative;
        border: 5px solid rgba($primary-color, 0.3);
        border-radius: 10px;

        img {
          left: 0;
          border-radius: 5px;
          transition: all 1s;

          &:hover {
            transform: scale(1.1);
          }
        }

        .customizer-overlay {
          position: absolute;
          border-radius: 5px;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          transition: background 0.5s ease;
        }

        .color-btn {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: opacity .35s ease;

          a {
            padding: 3px 6px;
            text-align: center;
            color: $white;
            font-size: 12px;
            border: solid 2px $white;
            z-index: 1;
          }
        }

        &:hover {
          .customizer-overlay {
            display: block;
            background: rgba(0, 0, 0, .3);
          }

          .color-btn {
            opacity: 1;
          }
        }
      }
    }

    .radio-form {
      gap: 0px;
      align-items: center;
      justify-content: space-evenly;
    }

    .sidebar-body {
      .radio-wrapper {
        li {
          width: unset;
          height: unset;
          padding: 8px 15px;

          .form-check-input {
            cursor: pointer;

            &:checked {
              ~ {
                .form-check-label {
                  .stroke-icon {
                    stroke: var(--theme-default);
                  }

                  .fill-icon {
                    fill: $primary-color;
                  }

                  span {
                    color: var(--theme-default);
                  }
                }
              }
            }
          }

          .form-check-label {
            margin-bottom: 0 !important;
            display: flex;
            align-items: center;

            .stroke-icon {
              stroke: var(--light-font);
            }

            .fill-icon {
              fill: var(--light-font);
            }

            span {
              color: var(--light-font);
            }
          }
        }
      }
    }

    .layout-grid {
      &.customizer-mix {
        .color-layout {
          height: 70px;
          width: 80px;
          border: 1px solid #f6f7fb;
          border-radius: 5px;
        }

        .common-layout {
          position: relative;

          span.badge {
            position: absolute;
            z-index: 1;
            background: rgba($gray-60, 0.5);
            line-height: normal;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 4px;
          }
        }
      }
    }

    ul {
      &.customizer-mix {
        .color-layout {
          height: 35px;
          width: 35px;
          border-radius: 6px;
          display: inline-block;
          margin-right: 7px;
          margin-bottom: 7px;
          border: 1px solid #b8b8b8;
          padding: 3px;
          position: relative;
          cursor: pointer;
          opacity: 0.9;

          &[data-attr="color-2"] {
            div {
              background-color: #0F2C59;
            }
          }

          &[data-attr="color-3"] {
            div {
              background-color: #7A3E65;
            }
          }

          &[data-attr="color-4"] {
            div {
              background-color: #025464;
            }
          }

          &[data-attr="color-5"] {
            div {
              background-color: #3E64FF;
            }
          }

          &[data-attr="color-6"] {
            div {
              background-color: #506D84;
            }
          }
        }
      }
    }

    li {
      cursor: pointer;
      opacity: 0.9;

      &.active {
        opacity: 1;
      }
    }

    .main-layout {
      >li {
        height: 65px;
        width: 85px;
        border: 1px solid $light-color;
        border-radius: 10px;
        display: inline-block;

        &+li {
          margin-left: 7px;
        }
      }

      .box-layout {
        .body {
          .badge {
            left: 11px;
          }
        }
      }
    }

    h6 {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-top: 12px;
      padding-top: 12px;
      margin-bottom: 5px;
      color: $black;

      &:first-child {
        padding-top: 0;
        margin-top: 0;
      }
    }

    .layout-link {
      display: flex;
      gap: 12px;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      li {
        border-radius: 4px;
        background-color: $light-color;

        a {
          padding: 10px 16px;
          display: block;
          color: $badge-light-color;

          i {
            font-size: 20px;
            color: $badge-light-color;
            vertical-align: -4px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  .sidebar-type,
  .sidebar-setting {
    >li {
      height: 70px;
      width: 85px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      display: inline-block;

      &+li {
        margin-left: 7px;
      }
    }
  }

  .sidebar-type {
    li {
      &[data-attr="normal-sidebar"] {
        .body {
          ul {
            li {

              &.bg-dark,
              &.sidebar {
                width: 100%;
                height: 10px;
                display: block;
              }

              &.bg-light,
              &.body {
                height: calc(100% - 10px);
                width: 100%;
              }
            }
          }
        }

        &.body-compact {
          .body {
            ul {
              li {
                &.body {
                  width: calc(100% - 25px);
                }
              }
            }
          }
        }
      }
    }
  }

  .layout-types {
    >li {
      width: unset;
      height: unset;
      margin-bottom: 20px;

      .layout-img {
        h6 {
          padding-top: 0;
          text-transform: inherit;
          text-align: center;
        }
      }
    }

    .box-layout {
      background: unset;
    }
  }

  .color-layout {
    height: 35px;
    width: 35px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #b8b8b8;
    padding: 3px;

    >div {
      background-color: $primary-color;
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }

    &[data-attr="color-2"] {
      div {
        background-color: #4831D4;
      }
    }

    &[data-attr="color-3"] {
      div {
        background-color: #d64dcf;
      }
    }

    &[data-attr="color-4"] {
      div {
        background-color: #4c2fbf;
      }
    }

    &[data-attr="color-5"] {
      div {
        background-color: #7c4dff;
      }
    }

    &[data-attr="color-6"] {
      div {
        background-color: #3949ab;
      }
    }
  }

  .dark {
    >li {
      background-color: #2f3c4e;
    }
  }

  .fade {
    &:not(.show) {
      display: none;
    }
  }

  .unlimited-color-layout {
    display: flex;
    align-items: center;

    .btn {
      font-size: 15px;
      padding: 4px 20px;
    }

    input {
      border: 1px solid $gray-60;
      height: 35px;
      width: 35px;
      border-radius: 5px;
      background-color: $white;
      margin-right: 5px;
    }
  }
}

.customizer-links {
  position: fixed;
  right: 00px;
  top: 50%;
  z-index: 8;
  transform: translate(0, -50%);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);

  >.nav {
    border-radius: 8px;
    padding: 10px;
    background-color: $white;
  }

  &.open {
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }

  .nav-link {
    padding: 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(115, 102, 255, 0.1);
      border-radius: 5px;
      transform: scale(0);
      transition: all 0.3s ease;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: 12px;
      border-width: 5px 0 5px 5px;
      border-left-color: lighten($primary-color, 25%);
      opacity: 0;
      transform: scale(0);
      color: $white;
      left: -10px;
      z-index: 2;
      transition: all 0.3s ease;
    }

    &:hover {
      i {
        color: var(--theme-default);
      }

      &:before,
      &:after {
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s ease;
      }

      span {
        opacity: 1;
        right: 48px;
        transform: scale(1);
        transition: all 0.3s ease;
      }
    }

    &+.nav-link {
      margin-bottom: 5px;
    }

    span {
      position: absolute;
      right: -60px;
      width: max-content;
      text-align: right;
      padding: 7px 10px;
      display: block;
      top: 0;
      border-radius: 5px;
      transform: scale(0);
      transition: all 0.3s ease;
      opacity: 0;
      color: var(--theme-default);
      background-color: lighten($primary-color, 25%);
      box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    }

    i {
      font-size: 17px;
      padding: 8px 10px;
      display: inline-block;
      cursor: pointer;
      margin: 0;
      color: gray;
      border-radius: 5px;
    }
  }
}

.customizer-contain {
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 79px;
  width: 333px;
  right: -335px;
  background-color: $white;
  z-index: 8;
  transition: all 0.3s ease;

  &.open {
    right: 0px;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  }

  li {
    position: relative;

    &.active {
      box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
    }
  }

  .customizer-header {
    padding: 15px 25px;
    border-bottom: 1px solid $light-color;

    .icon-close {
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      font-weight: 900;
      right: 24px;
      color: var(--theme-default);
    }

    h5 {
      text-transform: uppercase;
      color: $black;
    }
  }

  .customizer-body {
    padding: 15px 25px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
  }
}

.layout-grid:not(.customizer-color) {
  li {
    padding: 5px;

    >.header {
      height: 9px;
      border-radius: 3px;
      position: relative;

      ul {
        position: absolute;
        top: -8px;
        left: 5px;

        >li {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 5px;
          top: calc(50% - 3px);
          background-color: var(--theme-default);
          margin-right: 2px;
          padding: 0;

          &:nth-child(2) {
            background-color: var(--theme-secondary);
          }

          &:nth-child(3) {
            background-color: $success-color;
          }
        }
      }
    }

    >.body {
      background-color: $light-color;
      height: calc(100% - 16px);
      border-radius: 3px;
      position: relative;
      margin-top: 5px;
      text-align: center;
      line-height: 3.1;


      ul {
        height: 100%;
        background-color: $white;

        .sidebar {
          width: 18px;
          height: 100%;
          border-radius: 3px;
          display: inline-block;
          margin-right: 3px;
          padding: 0;

          &.compact {
            width: 12px;

            &~.body {
              width: calc(100% - 19px);
            }
          }
        }

        .body {
          width: calc(100% - 25px);
          padding: 0;
          margin: 0;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;

          .badge {
            position: absolute;
          }
        }
      }
    }

    &:nth-child(2) {
      >.body {
        ul {
          .sidebar {
            margin-left: 3px;
          }
        }
      }
    }
  }
}

/**=====================
    4.2 Theme-customizer CSS Ends
==========================**/