/**=====================
    2.9 BlockUi CSS start
==========================**/
//  freeze ui start

.block-main-wrapper,
.card-block-wrapper {
    p {
        display: block;
        display: -webkit-box;
        height: 68px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.block-main-wrapper {
    position: relative;

    .block-wrapper {
        padding: 12px;
        margin-bottom: 12px;
        position: relative;
        border: 1px solid var(--chart-dashed-border);

        p {
            margin: 0;
        }
    }
}

.card-img-top1,
.card-img-top2 {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: bottom;
    border-radius: 0;
}

.form-main-wrapper {
    .form-block-wrapper {
        position: relative;
        margin-bottom: 12px;

        .card-wrapper {
            &.overlay-bg {
                cursor: wait;

                form {
                    .form-control {
                        background-color: $transparent-color;
                        border-color: var(--view-grid-bg);
                    }
                }
            }
        }
    }
}

.overlay-bg {
    background-color: rgba(0, 0, 0, 0.3);
}

.page-loader-10 {
    position: relative;
    top: 0;
    left: 0;
    @include pixelstrap-size(100%, 100%);
    z-index: 2;
    background-color: rgba(255, 255, 255, .8);
    opacity: 0.4;

    &::before {
        content: attr(data-text);
        display: block;
        max-width: 125px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #343a40;
        text-align: center;
    }

    &::after {
        content: '';
        display: block;
        @include pixelstrap-size(35px, 35px);
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: transparent var(--recent-dashed-border) var(--recent-dashed-border) var(--recent-dashed-border);
        position: absolute;
        top: 50%;
        left: 50%;
        animation: load .85s infinite linear;
    }
}


@keyframes load {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}


.page-loader-12 {
    animation: content-loading 2s linear;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .8);

    &::before {
        content: "Loading...";
        display: block;
        max-width: 125px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--body-font-color);
        z-index: 1;
        font-size: 20px;
        padding: 18px;
        border-radius: 25px 0 25px 0;
        background-color: var(--theme-secondary);
    }
}

@keyframes content-loading {
    to {
        opacity: 0
    }
}

/**=====================
    2.9 BlockUi CSS start
==========================**/